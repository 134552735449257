import { makeAutoObservable } from "mobx";
import { IUser } from "../interfaces/IUser";
import AppStore from "../stores/AppStore";

export class User implements IUser {
  uid: string;
  fullName: string;
  email: string;
  password: string;
  branch: string;
  role: "Admin" | "NEO" | "MR";

  constructor(private store: AppStore, user: IUser) {
    makeAutoObservable(this);
    this.uid = user.uid;
    this.fullName = user.fullName;
    this.email = user.email;
    this.password = user.password;
    this.branch = user.branch;
    this.role = user.role;
  }

  get asJson(): IUser {
    return {
      uid: this.uid,
      fullName: this.fullName,
      email: this.email,
      password: this.password,
      branch: this.branch,
      role: this.role,
    };
  }
}
