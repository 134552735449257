import React, { useState } from "react";
import SectionHeading from "../../../shared/components/section-heading/SectionHeading";
import "./NOB.scss";

interface ILeaderItem {
  imgUrl: string;
  name: string;
  role: string;
}
const NOBItem = (props: ILeaderItem) => {
  return (
    <div className="nob-item uk-card uk-card-default uk-card-small">
      <div className="thumbnail uk-card-media-top">
        <img src={props.imgUrl} alt="" />
      </div>
      <div className="description uk-card-body">
        <h6 className="name">{props.name}</h6>
        <p className="role">{props.role}</p>
      </div>
    </div>
  );
};

const NOB = () => {
  return (
    <div className="nob uk-section">
      <div className="container uk-container">
        <SectionHeading
          heading="Our Leaders"
          subheading="National Office Bearers (NOB)"
        />

        <div
          className="uk-margin uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m uk-text-center"
          data-uk-grid
        >
          <div>
            <NOBItem
              imgUrl={
                process.env.PUBLIC_URL + "/assets/NOB pictures/President.jpg"
              }
              name={"Cde. Wigbert Mike Karupu"}
              role={"President"}
            />
          </div>
          <div>
            <NOBItem
              imgUrl={
                process.env.PUBLIC_URL +
                "/assets/NOB pictures/General Secretary.jpg"
              }
              name={"Cde. Jacob Penda"}
              role={"General Secretary"}
            />
          </div>
          <div>
            <NOBItem
              imgUrl={
                process.env.PUBLIC_URL +
                "/assets/NOB pictures/National Treasurer.jpg"
              }
              name={"Cde. Beauty Nomhle Wilhelm"}
              role={"National Treasurer"}
            />
          </div>
          <div>
            <NOBItem
              imgUrl={
                process.env.PUBLIC_URL +
                "/assets/NOB pictures/Deputy President.jpg"
              }
              name={"Cde. Abiud Tjipangadjara"}
              role={"Deputy President"}
            />
          </div>
          <div>
            <NOBItem
              imgUrl={
                process.env.PUBLIC_URL +
                "/assets/NOB pictures/Deputy General Secretary.jpg"
              }
              name={"Cde. Wilhelma Absalom"}
              role={"Deputy General Secretary"}
            />
          </div>
          <div>
            <NOBItem
              imgUrl={
                process.env.PUBLIC_URL +
                "/assets/NOB pictures/Deputy National Treasurer.jpg"
              }
              name={"Cde. Janet Kapere"}
              role={"Deputy National Treasurer"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NOB;
