import React from "react";
import PolicyAccordion from "./PolicyAccordion";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {

  const privacyPolicy = `${process.env.PUBLIC_URL}/assets/documents/nafau privacy policy.pdf`;


  return (
    <div className="privacy-policy">
      <div className="bg"></div>
      <div className="uk-section">
        <div className="container uk-container">
          <div className="policy-body uk-card uk-card-default uk-card-body">
            <h6 className="title">Privacy Policy.</h6>
            <p>
              NAFAU is committed to Internet privacy as demonstrated by the
              policies set forth herein. Please recognize that NAFAU may, in its
              sole discretion, modify these policies from time to time by
              updating this posting, so we encourage you to check this page each
              time you revisit one of our websites. Your continued use of our
              websites following any changes to this Privacy Policy will
              constitute your acceptance of such changes. The date of the latest
              revision will be listed below so you will know if the policies
              have changed since you last accessed one of our websites.
            </p>
            <p>
              Your use of a NAFAU website indicates your acceptance to be bound
              by the provisions of the Privacy Policy and Terms of Use in effect
              as of the date of your use. If you do not accept these terms, do
              not use our websites.
            </p>
            <p>
              The terms <span className="text-bold text-primary">“You”</span>{" "}
              and <span className="text-bold text-primary">“User” </span> as
              used herein refer to all individuals and/or entities accessing one
              of our websites for any reason.
            </p>

            <PolicyAccordion
              title="Website Usage; Information Collection"
              content={[
                `"When you visit one of the NAFAU websites, we automatically obtain
              information about your usage, including, for example, the name of
              your Internet service provider, the browser and type of computer
              that you are using, the website that referred you to us, the pages
              you view and the date and time of those page views. We use this
              information on an aggregate basis to gather data about activity on
              our websites, which we use to improve the usefulness of our
              websites. We do not use this information on any kind of individual
              basis. We do not collect or store personally identifiable
              information, such as your name, mailing address, e-mail address,
              or phone number unless you provide that information. We do not
              knowingly collect any information from children under the age of
              13."`,
              ]}
            />

            <PolicyAccordion
              title="Collection of Personally Identifiable Information"
              content={[
                `NAFAU provides opportunities for you to provide your contact
              information to engage in NAFAU activities such as online activism,
              to place orders for products or services, to correspond with
              NAFAU, or to submit a testimonial (i.e., share your story). Any
              information we collect, such as your name, mailing address, e-mail
              address, type of request you are making, and any additional
              information you provide, is stored by the NAFAU for the uses
              described below.`,

                `NAFAU makes reasonable efforts to ensure the secure collection,
              storage, and transmission of your sensitive information using
              industry-accepted data collection and encryption methodologies.`,

                `NAFAU does not sell your personal information to any third party.`,
              ]}
            />

            <PolicyAccordion
              title="Cookies"
              content={[
                `A cookie is a piece of data that is stored on a user’s hard drive
                that contains information about that user. NAFAU uses cookies to
                prepopulate data that you have previously supplied on our website
                so that you do not need to re-enter such information. We also use
                cookies to measure aggregate Web statistics such as collecting the
                number of users to our sites, the number of repeat users, and the
                most popular web pages.`,
              ]}
            />

            <PolicyAccordion
              title="NAFAU’s Sharing and Use of Personally Identifiable Information"
              content={[
                `The personal information that we collect on the website helps us,
                our state and local affiliates, and NAFAU-related organizations to
                efficiently and effectively represent union members, pursue our
                advocacy agenda, and provide valuable member benefits. To advance
                these goals and activities, we may share the personal information
                that we collect about you with our state and local affiliates and
                other NAFAU-related organizations, as well as third parties that
                perform services on our behalf. Additionally, subject to
                applicable law, we may share any of the personal information that
                we collect about you with certain third parties that share our
                interests.`,

                `We will use your e-mail address to communicate with you about our
                activities and offerings unless you opt-out from receiving such
                messages. We also will use your e-mail address for administrative
                purposes, such as notifying you of major website changes, sending
                messages related to actions you have taken on the site, or for
                customer service purposes. Although we hope you’ll find these
                communications informative and useful, you may opt-out of
                receiving future information via e-mail by using the unsubscribe
                procedures described below.`,

                `When you send an e-mail or other communications to us, we may
                retain those communications to process your inquiries, respond to
                your requests and improve our services.`,

                `We use both your personally identifying information and certain
                non-personally-identifying information (such as anonymous usage
                data, IP addresses, browser type, clickstream data, etc.) to
                improve the quality of your user experience and the design of the
                website and to create new features, functionality, and services by
                storing, tracking and analyzing user behavior, preferences,
                trends, and actions.`,

                `We may disclose your personal information (1) if we believe, in
                our sole discretion, that we are required to do so by law, such as
                in response to a court order, subpoena, or similar investigative
                demand; (2) as we deem appropriate or necessary in response to
                requests for cooperation from a law enforcement agency, taxation
                authority, or other government agency; and (3) as otherwise
                required by law.`,

                `We may share account or other information when we believe, in our
                sole discretion, it is necessary to protect our interests or
                property; to defend against legal claims or exercise our legal
                rights; to prevent fraud or other illegal activity perpetrated
                through any of NAFAU’s websites or using the NAFAU name; to
                prevent bodily harm; to enforce our Terms of Use; or to protect
                the rights, property or safety of visitors to our websites.`,
              ]}
            />

            <PolicyAccordion
              title="Text Messages"
              content={[
                `We also provide an opportunity for users to sign up on our
                websites to receive text messages from us about issues that may be
                of importance to them. You only receive mobile texts if you check
                a box indicating interest in mobile alerts or take some other
                action to specifically opt-in. Depending on an individual’s phone
                plan, message and data rates may apply for text messages. A user
                may opt-out of the continued receipt of text messages at any time
                by texting STOP to the text code. We never share or sell the
                mobile numbers we collect.`,
              ]}
            />

            <PolicyAccordion
              title="Opting Out of E-Mail Lists"
              content={[
                `People who subscribe to e-mail lists via the website will receive
                periodic updates from the NAFAU by regular mail, fax or e-mail.
                You may opt-out of receiving future information via e-mail by
                using the unsubscribe procedure specified on the e-mail messages
                you receive`,
              ]}
            />

            <PolicyAccordion
              title="Special Notice to Parents"
              content={[
                `We want to help you guard your children’s privacy. We encourage
                you to speak with your children about the safe and responsible use
                of their personal information while using the Internet. NAFAU does
                not knowingly collect any personally identifiable information from
                children under thirteen (13). If you have any concerns about any
                information that your child may have provided to us via one of our
                websites, please contact us at urgent@NAFAU.org.`,
              ]}
            />

            <PolicyAccordion
              title="Financial Information and Payments"
              content={[
                `You may be able to purchase items or donate money through our
                websites. These payments may be made through third-party providers
                such as PayPal. Though NAFAU makes every effort to only partner
                with reputable vendors, NAFAU is not responsible for the privacy
                or security practices of those third parties. We encourage our
                users to review the Privacy Policies of those third parties before
                completing any transactions.`,
              ]}
            />

            <PolicyAccordion
              title="Security"
              content={[
                `NAFAU has security measures in place to protect against the loss,
                misuse, and alteration of information within our control. These
                measures include internal reviews of our data collection, storage,
                and processing practices, and security measures, as well as
                physical security measures to guard against unauthorized access to
                systems where we store personal data.`,

                `Please recognize that if you send us an e-mail, the information
                contained in the e-mail is not secure against interception. So if
                your communication includes sensitive information, such as your
                credit card information, and you prefer not to use one of our
                secure online forms, you may choose the option of contacting us by
                mail or by telephone rather than by e-mail.`,
              ]}
            />

            <PolicyAccordion
              title="Corrections/Updating Personal Information"
              content={[
                `If you wish to correct or update your personal information, or if
                you wish to have your information removed from our database,
                contact us and we will honor your request to the extent we are
                able or as required under applicable law.`,

                `It is important to note that we periodically receive routine
                updates to our database records from local unions. If you are a
                NAFAU member and have requested personal information changes,
                please also alert your local union of any change. Failure to do so
                may result in your requested changes being overwritten by a
                subsequent routine update from your local union.`,
              ]}
            />

            <PolicyAccordion
              title="Links to Other Websites"
              content={[
                `Our websites may contain links to other sites. NAFAU is not
                responsible for the privacy practices or the content on such
                sites. We encourage our users to be aware when they leave our
                website and to read the privacy policies of each website that they
                visit that collects personally identifiable information. This
                Privacy Policy applies only to information collected through one
                of NAFAU websites.`,
              ]}
            />

            <PolicyAccordion
              title="Disclaimer"
              content={[
                `Opinions expressed here and in any corresponding comments are the
                personal opinions of the original authors and do not necessarily
                reflect the views of NAFAU.`,

                `Opinions expressed here and in any corresponding comments are the
                personal opinions of the original authors and do not necessarily
                reflect the views of NAFAU.`,
              ]}
            />

            <h4 className="policy-section-title">Terms of Use</h4>
            <p>
              By accessing the NAFAU websites, which include, for purposes of
              the Terms of Use, using our mobile texts function and our
              applications, including applications on third-party sites or
              platforms such as social networking sites, you are indicating your
              acceptance to be bound by the provisions of these Terms of Use and
              our Privacy Policy, which is incorporated herein by reference. If
              you do not accept any of these terms, do not access the site.
              NAFAU may, in its sole discretion, revise these Terms of Use at
              any time by updating this posting. Although we may attempt to
              notify you when major changes are made to these Terms of Use, you
              should visit this page periodically to review the most up-to-date
              Terms of Use because they are binding on you. By continuing to use
              this website following our posting of such changes, you agree to
              be bound by the Terms of Use, as modified.
            </p>
            <p>
              The terms <span className="text-bold text-primary">“You”</span>
              and <span className="text-bold text-primary">“User”</span> as used
              herein refer to all individuals and/or entities accessing this
              website for any reason.
            </p>

            <ol className="policy-section-list">
              <PolicyAccordion
                title="1. Eligibility"
                content={[
                  `By accessing one of the NAFAU websites, you represent and
                warrant that you are 18 years of age or older, or an
                emancipated minor, or possess legal parental or guardian
                consent, and that you have the right, authority, and
                capacity to agree to and abide by these Terms of Use. In any
                case, you affirm that you are above the age of 13, as the
                NAFAU website is not intended for children under 13. You
                also represent and warrant that you will use the websites in
                a manner consistent with any applicable laws and
                regulations.`,
                ]}
              />

              <PolicyAccordion
                title="2. NAFAU Permissions and Restrictions"
                content={[
                  `NAFAU hereby grants you permission to access and use the
                  websites as outlined in these Terms of Use, provided that:`,
                ]}
              >
                <ol className="sub-list">
                  <li className="list-item">
                    You do not collect any personally identifiable information
                    of others, including user names or e-mail addresses, from
                    the website.
                  </li>
                  <li>
                    You do not use any of the NAFAU websites in violation of any
                    applicable law or regulations.
                  </li>
                  <li>
                    You do not use the websites for any commercial purposes
                    without the prior written authorization of NAFAU. You agree
                    not to transmit or otherwise make available any unsolicited
                    advertising, promotional information, bulk e-mail, or other
                    solicitation. You agree not to solicit, for commercial
                    purposes, any users of the websites, including concerning
                    their user comments.
                  </li>

                  <li>
                    You do not circumvent, disable or otherwise interfere with
                    security-related features of the websites or our sites or
                    servers (or networks connected to our websites), including,
                    without limitation, the following activities:
                    <br />
                    <br />
                    (i) attempting to probe, scan or test the vulnerability of a
                    system or network or to breach security or authentication
                    measures without proper authorization;
                    <br />
                    <br />
                    (ii) attempting to interfere with communication to any user,
                    host or network, including, without limitation, via means of
                    submitting a virus to this or anyof NAFAU site, overloading,
                    “flooding,” “spamming,” “mailbombing” or ”crashing”; or{" "}
                    <br />
                    <br />
                    (iii) transmitting or otherwise making available any content
                    containing any “virus,” “worm” or “trojan horse” or any
                    other computer code, file or program designed to interrupt,
                    destroy or limit the functionality of any computer software
                    or hardware or telecommunications equipment. Violations of
                    system or network security may result in civil or criminal
                    liability. The NAFAU will investigate occurrences that may
                    involve such violations and may involve, and cooperate with,
                    law enforcement authorities in prosecuting users who are
                    involved in such violations.
                  </li>
                  <li>
                    You do not use or launch any automated system, including,
                    without limitation, “robots,” “spiders” or “offline
                    readers,” that accesses the websites in a manner that sends
                    more request messages to the NAFAU servers in a given period
                    than a human can reasonably produce in the same period by
                    using a conventional online Web browser. Notwithstanding the
                    foregoing, the NAFAU grants the operators of public search
                    engines permission to use spiders to copy materials from the
                    site for the sole purpose of and solely to the extent
                    necessary for creating publicly available searchable indices
                    of the materials but not caches or archives of such
                    materials. The NAFAU reserves the right to revoke these
                    exceptions either generally or in specific cases.
                  </li>
                  <li>You do not alter or modify any part of the website.</li>
                  <li>
                    You do not harass, threaten, embarrass or cause distress,
                    unwanted attention, or discomfort to a person or entity on
                    or through the websites or our communication systems.
                  </li>
                  <li>
                    You do not transmit or otherwise make available on or
                    through the websites any content that is unlawful, harmful,
                    threatening, abusive, harassing, defamatory, violent,
                    vulgar, obscene, hateful or racially, ethnically or
                    otherwise objectionable, or potentially libelous or
                    inflammatory, as solely determined by NAFAU.
                  </li>
                  <li>
                    NAFAU reserves the right to change or discontinue any aspect
                    of the websites at any time, for any reason, and without
                    notice to you.
                  </li>
                  <li>
                    NAFAU reserves the right to suspend or terminate a user’s
                    access to the websites, without prior notice and at the
                    NAFAU’s sole discretion. (k) This list of permissions and
                    restrictions, along with the other terms discussed below, is
                    not intended to be complete, but merely illustrative. We
                    reserve the right in our sole and final judgment to
                    determine whether your conduct violates conduct requirements
                    for the websites.
                  </li>
                </ol>
              </PolicyAccordion>

              <PolicyAccordion
                title="3. NAFAU Permissions and Restrictions"
                content={[
                  `To access some features of the websites, such as, for
                  example, interactive blogs, you have to log in using your
                  Facebook or Google login or become a registered user. As a
                  registered user, you agree to be bound by the following
                  terms:`,
                ]}
              >
                <ol className="sub-list">
                  <li className="list-item">
                    When creating or modifying your account, you must provide
                    accurate, current, and complete information. We reserve the
                    right to disallow the use of any user name that we deem
                    offensive or inappropriate. You will be responsible for
                    preserving the confidentiality of your password and for all
                    actions of persons accessing the websites through any
                    username/password assigned to you. You must notify NAFAU
                    immediately of any breach of security or unauthorized use of
                    your account. You may never use another’s account without
                    permission.
                  </li>
                  <li>
                    As a user, you may submit comments on various NAFAU blogs or
                    other material (collectively, “User Content”) to the
                    website. NAFAU reserves the right but does not assume the
                    obligation, to delete, move, condense or edit such User
                    Content for any reason and without prior notice. NAFAU also
                    reserves the right to suspend or terminate a user’s access
                    for posting User Content.
                  </li>
                  <li>
                    You are solely responsible for your User Content and the
                    consequences of posting or publishing it. Submissions may be
                    used by NAFAU for any purpose in any form without the
                    permission of the submitter and any consideration apart from
                    participation following these Terms of Use. By providing
                    User Content to NAFAU, you affirm, represent, and/or warrant
                    that you own or have the necessary licenses, rights,
                    consents, and permissions to use and authorize NAFAU to use
                    all patent, trademark, trade secret, copyright, or other
                    proprietary rights in and to any User Content you submit to
                    enable inclusion and use of such User Content in the manner
                    contemplated by these Terms of Use. You agree and represent
                    that, unless you are the owner of such rights or have
                    permission from the rightful owner, you will not submit
                    material that
                    <br />
                    <br />
                    (i) is copyrighted or protected by trademark or trade secret
                    or other intellectual property rights of others;
                    <br />
                    <br />
                    (ii) violates the privacy, publicity or other personal
                    rights of others; or
                    <br />
                    <br />
                    (iii) reveals confidential information or otherwise violates
                    the third-party proprietary rights of others. You further
                    agree and represent that you will not submit material that
                    is harassing, vulgar, unlawful, harmful, profane,
                    defamatory, violent, obscene, threatening, abusive, hateful,
                    or is potentially libelous or inflammatory or racially,
                    ethnically, or otherwise objectionable. The NAFAU reserves
                    the right to not post submissions and to remove submissions
                    without prior notice, at the sole discretion of NAFAU.
                  </li>

                  <li>
                    You retain all of your ownership rights in your User
                    Content. However, by submitting User Content to NAFAU, you
                    hereby grant NAFAU a worldwide, nonexclusive, royalty-free,
                    sublicenseable, perpetual, and transferable license to use,
                    reproduce, store, distribute, prepare derivative works of
                    and display your User Content (and your user name, voice,
                    appearance, and likeness) in any medium in connection with
                    the activities and operations of NAFAU, its related and
                    affiliated entities and successors, including, without
                    limitation, for promoting and redistributing part or all of
                    the NAFAU website (and derivative works thereof) in any form
                    or media, now known or hereafter devised, in all languages
                    throughout the world. You also give NAFAU the nonexclusive
                    right to use your name, voice, appearance, and likeness, as
                    well as any additional information you have provided about
                    yourself, in any material prepared by or for the NAFAU in
                    any form or media, now known or hereafter devised, in all
                    languages throughout the world. You also hereby grant each
                    user of the NAFAU websites a non-exclusive license to access
                    your User Content through the websites and to use, reproduce
                    and distribute such User Content for such user’s personal,
                    noncommercial purposes. You understand and agree that the
                    NAFAU may retain, reproduce, distribute, and otherwise use
                    for any purpose copies of User Content that has been removed
                    from the websites. The above licenses granted by you are
                    perpetual and irrevocable.
                  </li>
                  <li>
                    NAFAU does not endorse, authorize or ratify any User
                    Content, or any opinion, recommendation, or advice expressed
                    therein, and NAFAU expressly disclaims all liability in
                    connection with User Content. NAFAU does not permit
                    copyright infringing activities and infringement of
                    intellectual property rights on its websites, and NAFAU
                    reserves the right but does not assume the obligation, to
                    remove any content from the websites if notified that such
                    content infringes on another’s intellectual property rights.
                  </li>
                </ol>
              </PolicyAccordion>

              <PolicyAccordion
                title="4. Your Use of Content on the Site"
                content={[
                  `In addition to the restrictions above, the following
                  restrictions and conditions apply specifically to your use of
                  the content on the NAFAU websites.`,
                ]}
              >
                <ol className="sub-list">
                  <li className="list-item">
                    The content on the websites (other than your User Content),
                    including, without limitation, the text, software, scripts,
                    graphics, photos, sounds, music, videos, interactive
                    features, and the like (collectively, “NAFAU Content”) is
                    owned by or licensed by NAFAU. Similarly, the trademarks,
                    service marks, and logos contained on the website are owned
                    or licensed by NAFAU (“NAFAU Marks”). Except as otherwise
                    provided in these Terms of Use, NAFAU Content and NAFAU
                    Marks may not be downloaded, copied, reproduced,
                    distributed, transmitted, broadcast, displayed, sold,
                    licensed, or otherwise exploited for any purpose whatsoever
                    without the prior written consent of AFAU. NAFAU reserves
                    all rights not expressly granted in and to NAFAU Content and
                    NAFAU Marks.
                  </li>
                  <li>
                    The websites are made available for your personal,
                    noncommercial use only. As part of such use, you may
                    display, download, and/or print pages from the site; you may
                    link to the websites, and you may forward Website materials
                    to others for personal, non-commercial uses that are
                    reasonably related to the website’s purposes.
                  </li>
                  <li>
                    You understand that when using one of NAFAU’s websites, you
                    will be exposed to NAFAU Content, User Content, and other
                    third-party content from a variety of sources and that NAFAU
                    makes no warranty about the accuracy, usefulness, safety, or
                    intellectual property rights of or relating to such NAFAU
                    Content, User Content or other third-party content. You
                    further understand and acknowledge that you may be exposed
                    to User Content that may be inaccurate, offensive, indecent
                    or objectionable, and you agree to waive, and hereby do
                    waive, any legal or equitable rights or remedies you have or
                    may have against NAFAU with respect thereto
                  </li>

                  <li>
                    The websites may contain links to third-party websites that
                    are not owned or controlled by the NAFAU. NAFAU has no
                    control over and assumes no responsibility for, the content,
                    privacy policies, or practices of any third-party websites.
                    We encourage you to be aware when you leave one of the
                    websites and to read the terms and conditions and privacy
                    policy of each other website that you visit.
                  </li>
                </ol>
              </PolicyAccordion>

              <PolicyAccordion
                title="5. Copyright and Neighbouring Rights Protection Act 6 of 1994 "
                content={[
                  `If you are a copyright owner or an agent thereof and believe
                  that any NAFAU Content or User Content infringes upon your
                  copyright, you may submit a notification according to the
                  Copyright and Neighbouring Rights Protection Act 6 of 1994
                  by providing NAFAU’s Copyright Agent with the following
                  information in writing for further details):`,
                ]}
              >
                <ul className="sub-list">
                  <li className="list-item">
                    Identification of the copyrighted work claimed to have been
                    infringed;
                  </li>
                  <li>
                    Identification of the material that is claimed to be
                    infringing or to be the subject of infringing activity and
                    that is to be removed or access to which is to be disabled
                    and information reasonably sufficient to permit the NAFAU to
                    locate the material;
                  </li>
                  <li>
                    A statement that you have a good-faith belief that use of
                    the material in the manner complained of is not authorized
                    by the copyright owner, its agent, or the law;
                  </li>

                  <li>
                    A statement that the information in the notification is
                    accurate, and under penalty of perjury, that you are, or are
                    authorized to act on behalf of, the owner of an exclusive
                    right that is allegedly infringed;
                  </li>
                  <li>
                    Your name, mailing address, telephone number and e-mail
                    address; and
                  </li>
                  <li>
                    A physical or electronic signature of the owner of the
                    exclusive right that is allegedly infringed, or that of the
                    person authorized to act on behalf of the owner.
                  </li>
                  <li>
                    NAFAU’s designated Copyright Agent to receive notifications
                    of claimed infringement is NAFAU …………………. You acknowledge
                    that if you fail to comply with all of the above
                    requirements, your notice may not be valid.
                  </li>
                  <li>
                    For clarity, Copyright notices should go to the Copyright
                    Agent. Please use the Contact Us form for any other
                    feedback, comments, requests for technical support, or other
                    communications with NAFAU.
                  </li>
                </ul>
              </PolicyAccordion>

              <PolicyAccordion
                title="6. Disclaimer of Warranty"
                content={[
                  `NAFAU websites are provided to you “as is.” You agree that your
                  use of NAFAU websites shall be at your sole risk. To the fullest
                  extent permitted by law, NAFAU, as well as its officers,
                  directors, employees, and agents (collectively, “NAFAU
                  parties”), disclaim all warranties, express or implied, in
                  connection with the websites and your use thereof. NAFAU cannot
                  and do not warrant the accuracy, completeness, currentness,
                  noninfringement, merchantability, or fitness for a particular
                  purpose of the websites’ content or the content of any websites
                  linked to any of the NAFAU websites. Nor do NAFAU parties
                  guarantee that the websites will be error-free, or continuously
                  available, or that the websites will be free of viruses or other
                  harmful components. NAFAU parties do not warrant, guarantee or
                  assume responsibility for any product or service provided,
                  advertised, or offered by a third party through NAFAU’s websites
                  or any website hyperlinked or featured in any banner or other
                  advertising.`,
                ]}
              />

              <PolicyAccordion
                title="7. Limitation on Liability"
                content={[
                  `In no event shall NAFAU parties be liable to you or anyone
                  else for any indirect, incidental, special, punitive or
                  consequential damages whatsoever, whether or not they are
                  foreseeable, relating to NAFAU websites, including any damages
                  resulting from any <br />
                  <br />(i) errors, mistakes or inaccuracies of content;{" "}
                  <br />
                  <br />
                  (ii) personal injury or property damage, of any nature
                  whatsoever, resulting from your access to and use of our
                  websites; <br />
                  <br />
                  (iii) unauthorized access to or use of our secure servers
                  and/or any and all personal information and/or financial
                  information stored therein; <br />
                  <br />
                  (iv) interruption or cessation of transmission to or from our
                  websites; <br />
                  <br /> (v) bugs, viruses, trojan horses or the like, which may
                  be transmitted to or through our websites by any third party;
                  and/or
                  <br />
                  <br />
                  (vi) errors or omissions in any content or for any loss or
                  damage of any kind incurred as a result of your use of or
                  inability to use our websites or any content posted, e-mailed,
                  transmitted or otherwise made available via NAFAU websites,
                  whether based on warranty, contract, tort or any other legal
                  theory, and whether or not NAFAU is advised of the possibility
                  of such damages. You specifically acknowledge that NAFAU shall
                  not be liable for user content or the defamatory, offensive,
                  or illegal conduct of any third party and that the risk of
                  harm or damage from the foregoing rests entirely with you.
                  Certain state laws do not allow limitations on implied
                  warranties or the exclusion or limitation of certain damages.
                  To the extent these laws apply to you, some of the provisions
                  outlined in this agreement may not apply..`,
                ]}
              />

              <PolicyAccordion
                title="8. Indemnity"
                content={[
                  `You agree to defend, indemnify and hold harmless NAFAU, from
                  and against any claims, damages, obligations, losses,
                  liabilities, costs or debt and expenses (including, but not
                  limited to, attorney’s fees), of whatever nature, arising from
                  (i) your use of NAFAU websites; (ii) your violation of any
                  term of these Terms of Use; (iii) your violation of any
                  third-party right, including without limitation any copyright,
                  property or privacy right; or (iv) any claim that one of your
                  Submissions caused damage to a third party. This defense and
                  indemnification obligation will survive these Terms of Use and
                  your use of the NAFAU websites.`,
                ]}
              />

              <PolicyAccordion
                title="9. Miscellaneous"
                content={[
                  `These Terms of Use and Privacy Policy shall be governed by the
                  internal substantive laws of Namibia, without respect to its
                  conflict of laws principles. Any claim or dispute between you
                  and NAFAU that arises in whole or in part from the NAFAU
                  websites shall be decided exclusively by a court of competent
                  jurisdiction located in Windhoek.`,

                  `These Terms of Use and Privacy Policy, together with any other
                  legal notices published by NAFAU on the website, shall
                  constitute the entire agreement between you and NAFAU
                  concerning NAFAU’s websites and supersedes prior agreements,
                  if any, between you and NAFAU relating to any matter dealt
                  with in these Terms of Use and Privacy Policy. If any
                  provision of these Terms of Use or Privacy Policy is deemed
                  invalid by a court of competent jurisdiction, the invalidity
                  of such provision shall not  affect the validity of the remaining provisions, which shall
                  remain in full force and effect. No waiver of any term of
                  these Terms of Use and Privacy Policy shall be deemed a
                  further or continuing waiver of such term or any other term,
                  and NAFAU’s failure to assert any right or provision under
                  these Terms of Use and Privacy Policy shall not constitute a
                  waiver of such right or provision. These Terms of Use and
                  Privacy Policy, and any rights and licenses granted hereunder,
                  may not be transferred or assigned by you but may be assigned
                  by NAFAU without restriction. These Terms of Use and Privacy
                  Policy and the rights and obligations created hereunder shall
                  be binding upon and inure solely to the benefits of the
                  parties hereto and their respective successors and assigns,
                  and nothing in these Terms of Use and Privacy Policy, express
                  or implied, is intended or should be construed to confer upon
                  any other person any right, remedy or claim under or by these
                  Terms of Use and Privacy Policy. You and NAFAU agree that any
                  cause of action arising out of or related to NAFAU websites
                  must commence within one (1) year after the cause of action
                  accrues. Otherwise, such cause of action is permanently
                  barred.`,

                  `These Terms of Use and Privacy Policy shall be governed by the
                  internal substantive laws of Namibia, without respect to its
                  conflict of laws principles. Any claim or dispute between you
                  and NAFAU that arises in whole or in part from the NAFAU
                  websites shall be decided exclusively by a court of competent
                  jurisdiction located in Windhoek.`,
                  `These Terms of Use are made effective as of October 30, 2021.`,
                ]}
              />
            </ol>
            <a className="download" href={privacyPolicy} download>
              Download PDF <span uk-icon="icon: download; ratio: .8"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
