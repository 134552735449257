import { useNavigate } from "react-router-dom";

interface IProps {
  icon: string;
  label: string;
  routeTo: string;
}
const NavItem = (props: IProps) => {
  const { icon, label, routeTo } = props;

  const navigate = useNavigate();

  const navigateTo = () => {
    navigate(routeTo);
  };

  return (
    <div className="nav-item" onClick={navigateTo}>
      <span data-uk-icon={icon}></span> {label}
    </div>
  );
};

export default NavItem;


interface IBProps {
  icon: string;
  label: string;
  onClick: () => void;
}
export const NavButton = (props: IBProps) => {
  const { icon, label, onClick } = props;

  return (
    <button className="nav-button"
      onClick={onClick}>
      <span data-uk-icon={icon}>
      </span> {label}
    </button>
  );
};

