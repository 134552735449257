export const galleryImages = [
  {
    index: 2,
    year: 2012,
    img: "/assets/gallery/Elected.jpg",
  },
  {
    index: 3,
    year: 2012,
    img: "/assets/gallery/Elected1.jpg",
  },
  {
    index: 4,
    year: 2012,
    img: "/assets/gallery/Elected2.jpg",
  },
  {
    index: 5,
    year: 2012,
    img: "/assets/gallery/Elected3.jpg",
  },
  {
    index: 6,
    year: 2021,
    img: "/assets/gallery/nob.jpg",
  },
  {
    index: 7,
    year: 2012,
    img: "/assets/gallery/Congress1.jpg",
  },
  {
    index: 8,
    year: 1991,
    img: "/assets/gallery/Hartlief.jpg",
  },

  {
    index: 9,
    year: 1991,
    img: "/assets/gallery/Congress.png",
  },
  {
    index: 10,
    year: 1994,
    img: "/assets/gallery/Congress2.jpg",
  },
  {
    index: 11,
    year: 1994,
    img: "/assets/gallery/Fifthcongress.jpg",
  },
  {
    index: 12,
    year: 1992,
    img: "/assets/gallery/Picture1.png",
  },
  {
    index: 13,
    year: 1987,
    img: "/assets/gallery/Picture2.png",
  },
  {
    index: 14,
    year: 1993,
    img: "/assets/gallery/Picture3.png",
  },
  {
    index: 15,
    year: 2006,
    img: "/assets/gallery/Picture4.png",
  },
  {
    index: 16,
    year: 2006,
    img: "/assets/gallery/Picture5.png",
  },
  {
    index: 17,
    year: 1990,
    img: "/assets/gallery/Picture6.png",
  },
  {
    index: 18,
    year: 1994,
    img: "/assets/gallery/Picture7.png",
  },
  {
    index: 19,
    year: 1994,
    img: "/assets/gallery/Picture8.png",
  },
  {
    index: 20,
    year: 1997,
    img: "/assets/gallery/Picture9.png",
  },
  {
    index: 21,
    year: 2008,
    img: "/assets/gallery/Picture10.png",
  },
  {
    index: 22,
    year: 2006,
    img: "/assets/gallery/Picture11.png",
  },
  {
    index: 23,
    year: 2006,
    img: "/assets/gallery/Picture12.png",
  },
  {
    index: 24,
    year: 2006,
    img: "/assets/gallery/Picture13.png",
  },
  {
    index: 25,
    year: 1980,
    img: "/assets/gallery/Picture14.png",
  },
  {
    index: 26,
    year: 1994,
    img: "/assets/gallery/Picture15.png",
  },
  {
    index: 27,
    year: 2004,
    img: "/assets/gallery/Picture17.png",
  },
  //   &&&&&&&&&&&&&&&&&***************************************************************************************
  {
    index: 28,
    year: 1995,
    img: "/assets/gallery/Picture20.png",
  },
  {
    index: 29,
    year: 1988,
    img: "/assets/gallery/Picture21.png",
  },
  {
    index: 30,
    year: 1988,
    img: "/assets/gallery/Picture22.png",
  },
  {
    index: 31,
    year: 1992,
    img: "/assets/gallery/Picture23.png",
  },
  {
    index: 32,
    year: 1992,
    img: "/assets/gallery/Picture24.png",
  },
  {
    index: 33,
    year: 1992,
    img: "/assets/gallery/Picture25.png",
  },
  {
    index: 34,
    year: 1992,
    img: "/assets/gallery/Picture26.png",
  },
  {
    index: 35,
    year: 1992,
    img: "/assets/gallery/Picture27.png",
  },
  {
    index: 36,
    year: 1992,
    img: "/assets/gallery/Picture28.png",
  },
  {
    index: 37,
    year: 1992,
    img: "/assets/gallery/Picture29.png",
  },
  {
    index: 38,
    year: 1992,
    img: "/assets/gallery/Picture29.png",
  },
  {
    index: 39,
    year: 1992,
    img: "/assets/gallery/Picture30.png",
  },
  {
    index: 40,
    year: 1992,
    img: "/assets/gallery/Picture31.png",
  },
  {
    index: 41,
    year: 1992,
    img: "/assets/gallery/Picture32.png",
  },
  {
    index: 42,
    year: 1992,
    img: "/assets/gallery/Picture33.png",
  },
  {
    index: 43,
    year: 1992,
    img: "/assets/gallery/Picture34.png",
  },
  {
    index: 44,
    year: 1992,
    img: "/assets/gallery/Picture35.png",
  },
  {
    index: 45,
    year: 1992,
    img: "/assets/gallery/Picture36.png",
  },
  {
    index: 45,
    year: 1992,
    img: "/assets/gallery/Picture37.png",
  },
  {
    index: 47,
    year: 1992,
    img: "/assets/gallery/Picture38.png",
  },
  {
    index: 48,
    year: 1992,
    img: "/assets/gallery/Picture39.png",
  },
  {
    index: 49,
    year: 1992,
    img: "/assets/gallery/Picture40.png",
  },
  {
    index: 50,
    year: 1992,
    img: "/assets/gallery/Picture41.png",
  },
  {
    index: 51,
    year: 1992,
    img: "/assets/gallery/Picture42.png",
  },
  {
    index: 52,
    year: 1992,
    img: "/assets/gallery/Picture43.png",
  },
  {
    index: 53,
    year: 1992,
    img: "/assets/gallery/Picture44.png",
  },
  {
    index: 54,
    year: 1992,
    img: "/assets/gallery/Picture45.png",
  },
  {
    index: 55,
    year: 1992,
    img: "/assets/gallery/Picture46.png",
  },
  {
    index: 56,
    year: 1992,
    img: "/assets/gallery/Picture47.png",
  },
  {
    index: 57,
    year: 1992,
    img: "/assets/gallery/Picture48.png",
  },
  {
    index: 58,
    year: 1992,
    img: "/assets/gallery/Picture49.png",
  },
  {
    index: 59,
    year: 1992,
    img: "/assets/gallery/Picture50.png",
  },
  {
    index: 60,
    year: 1992,
    img: "/assets/gallery/Picture51.png",
  },
  {
    index: 61,
    year: 1992,
    img: "/assets/gallery/Picture52.png",
  },
  {
    index: 62,
    year: 1992,
    img: "/assets/gallery/Picture53.png",
  },
  {
    index: 63,
    year: 1992,
    img: "/assets/gallery/Picture54.png",
  },
  {
    index: 64,
    year: 1992,
    img: "/assets/gallery/Picture55.png",
  },
  {
    index: 65,
    year: 1992,
    img: "/assets/gallery/Picture56.png",
  },
  {
    index: 66,
    year: 1992,
    img: "/assets/gallery/Picture57.png",
  },
  {
    index: 67,
    year: 1995,
    img: "/assets/gallery/Picture58.png",
  },
  {
    index: 68,
    year: 1992,
    img: "/assets/gallery/Picture59.png",
  },
  {
    index: 69,
    year: 1992,
    img: "/assets/gallery/Picture60.png",
  },
  {
    index: 70,
    year: 1992,
    img: "/assets/gallery/Picture61.png",
  },
  {
    index: 71,
    year: 1992,
    img: "/assets/gallery/Picture62.png",
  },
  {
    index: 74,
    year: 2012,
    img: "/assets/gallery/Picture65.png",
  },
  {
    index: 75,
    year: 2012,
    img: "/assets/gallery/Picture66.png",
  },
  {
    index: 76,
    year: 1995,
    img: "/assets/gallery/Picture67.jpg",
  },
  {
    index: 77,
    year: 2006,
    img: "/assets/gallery/Picture68.jpg",
  },
  {
    index: 78,
    year: 2006,
    img: "/assets/gallery/Picture69.jpg",
  },
  {
    index: 79,
    year: 1995,
    img: "/assets/gallery/Picture70.png",
  },
  {
    index: 80,
    year: 1995,
    img: "/assets/gallery/Picture71.jpg",
  },
  {
    index: 81,
    year: 1995,
    img: "/assets/gallery/Picture72.png",
  },
  {
    index: 82,
    year: 1995,
    img: "/assets/gallery/Picture73.png",
  },
  //
  {
    index: 83,
    year: 2022,
    img: "/assets/gallery/Picture74.jpg",
  },
  {
    index: 84,
    year: 2022,
    img: "/assets/gallery/Picture75.jpg",
  },
  {
    index: 85,
    year: 2022,
    img: "/assets/gallery/Picture76.jpg",
  },
  {
    index: 86,
    year: 2022,
    img: "/assets/gallery/Picture77.jpg",
  },
  {
    index: 87,
    year: 2022,
    img: "/assets/gallery/Picture78.jpg",
  },
  {
    index: 88,
    year: 2022,
    img: "/assets/gallery/Picture79.jpg",
  },
  {
    index: 89,
    year: 2022,
    img: "/assets/gallery/Picture80.jpg",
  },
  {
    index: 90,
    year: 2022,
    img: "/assets/gallery/Picture81.jpg",
  },
  {
    index: 91,
    year: 2022,
    img: "/assets/gallery/Picture82.jpg",
  },
  {
    index: 92,
    year: 2012,
    img: "/assets/gallery/Picture83.jpg",
  },
];
