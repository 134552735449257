import { makeAutoObservable } from "mobx";
import IEducationPost from "../interfaces/IEducationPost";

export class EducationPost implements IEducationPost {
  id: string;
  title: string;
  content: string[];
  // createdAt: number;
  // updatedAt: number;

  constructor(post: IEducationPost) {
    makeAutoObservable(this);

    this.id = post.id;
    this.title = post.title;
    this.content = post.content;
    // this.createdAt = post.createdAt;
    // this.updatedAt = post.updatedAt;
  }

  get asJson(): IEducationPost {
    return {
      id: this.id,
      title: this.title,
      content: this.content,
      // createdAt: this.createdAt,
      // updatedAt: this.updatedAt,
    };
  }
}
