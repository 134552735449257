import React, { useEffect, useState } from "react";
import TimelineDots from "./TimelineDots";
import TimelineList from "./TimelineList";
import "./Timeline.scss";
import IHistoryTimelineItem from "../../../shared/interfaces/IHistoryTimelineItem";

const Timeline = () => {
  const [activeIndex, setactiveIndex] = useState(0);
  const path = process.env.PUBLIC_URL + "/assets/history/";

  const items: IHistoryTimelineItem[][] = [
    [
      {
        message:
          "<h3>After an intense campaign of workers mobilisation and organisation.</h3> The Namibia Food and Allied Workers Union (NAFAU) was launched on 20 September 1986 as the NUNW's first industrial affiliate, John Pandeni was elected as the first General Secretary of NAFAU.<h3>The Lalandi Strike of 1986</h3>Shortly after the union's formation in 1986, fishermen in Luderitz went on strike for better wages.The strike ended amicably with the first­ ever NAFAU recognition agreement being signed with Lalandi. The company agreed to pay the fisherman per day and per kilogram. The Luderitz fishermen became  the first members of NAFAU.",
        year: "1986",
        title: "",
        caption:
          "John Pandeni (left) and Barnabus Tjizu were the first General Secretaries of NAFAU and MANWU respectively.",
        image: path + "jonh pandeni.jpg",
      },
      {
        message: "",
        year: "1986",
        title: "",
        caption: "John Nankudhu was a NAFAU organiser in the early days.",
        image: path + "jonh mangudu.jpg",
      },
      {
        message: "",
        year: "1986",
        title: "",
        caption:
          "A NAFAU meeting in Hafeni Ndemula's house - NAFAU's first office in Walvis Bay.",
        image: path + "nafau meeting.jpg",
      },
      {
        message: "",
        year: "1986",
        title: "",
        caption: "A group of first-generation NAFAU members in Windhoek.",
        image: path + "first generation.jpg",
      },
    ],
    [
      {
        message:
          "<h3>The SWAVleis strike of 1987.</h3>One of the biggest strikes in the food industry ever seen in Namibia started on 14 May 1987 at the SWAVleis meat-processing factories in Windhoek and Okahandja. It was triggered by failed negotiations for higher wages, better working conditions, and the reinstatement of 12 workers who had been dismissed because they had not met their quotas.The strike lasted for about two weeks, and NAFAU scored a major victory when both the Okahandja and Windhoek managements agreed to reinstate the dismissed workers. However, they refused to reinstate the 12 who had been dismissed initially, holding that this issue was not negotiable.",
        year: "1987",
        title: "",
        caption:
          "Former NAFAU Deputy Secretary-General Macdonald Ntlabati (center) in discussion with workers during the SWAVleis strike.",
        image: path + "swavleis.jpg",
      },
      {
        message: "",
        year: "1987",
        title: "",
        caption: "An NUNW May Day rally in 1987.",
        image: path + "mayday.jpg",
      },
      {
        message: "",
        year: "1987",
        title: "",
        caption:
          "National Congress 1987: The 2nd NAFAU National Congress was held on 20 September 1987 at the Roman Catholic Church in Katutura. NAFAU's National Executive Committee was elected at the 2nd National Congress.",
        image: path + "executive commitee.jpg",
      },
    ],
    [
      {
        message: "<h3>May Day celebrations held in Luderitz in 1988.</h3>",
        year: "1988",
        title: "",
        caption:
          "Elizabeth Matheus at the May Day celebrations in Luderitz in 1988.",
        image: path + "maydaycelebration.jpg",
      },
      {
        message: "",
        year: "1988",
        title: "",
        caption:
          "United we stand, divided we fall - NAFAU workers in Walvis Bay.",
        image: path + "unitedwestand.jpg",
      },
    ],
    [
      {
        message:
          "<h3>The 3rd NAFAU National Congress.</h3>The 3rd NAFAU National Congress was held on 15th-18th April 1989, again at the Roman Catholic Church in Katutura. This congress was attended by over 600 delegates of all NAFAU branches and national structures, and representatives of the Congress of South African Trade Unions (COSATU), South Africa's Food and Allied Workers Union (FAWU), and the International Union of Food, Agricultural, Hotel, Catering, Tobacco and General Workers (IUF) based in Geneva, Switzerland. <br/><br/> <h3>The South West Breweries boycott of 1989.</h3>When the wage demands tabled by the shop-steward committee led by John Tjiramba were not met, brewery workers went on strike. South West Breweries, owned by the Olthaver & List Group, responded by dismissing 207 workers. On 5 September, workers standing outside company premises were shot at by police and several of them suffered serious injuries. Due to the length of the boycott and the tough stance of all the parties involved, The Namibian President; Sam Nujoma was requested to intervene. A meeting was arranged at the State House on 15 March 1991 between representatives of the dismissed workers, a representative of South West Breweries and Olthaver & List, and the President. All parties at this meeting expressed commitment to resolving the issue of reinstatement of the dismissed workers. However, the company later retracted the promises it made in this regard.",
        year: "1989",
        title: "",
        caption: "",
        image: path + "",
      },
    ],
    [
      {
        message:
          "<h3>1991 Congress.</h3>The 4th NAFAU National Congress 22-24 March 1991 was held on 22-24 March 1991 in Windhoek. The discussion focused largely on the need to address the lack of human resources in NAFAU and to appoint new office­ bearers to take control in areas of work in which human resources were sorely lacking.",
        year: "1991",
        title: "",
        caption: "Cuana Angula in front of the 1991 congress banner.",
        image: path + "1991 congress.png",
      },
    ],
    [
      {
        message: `<h3>The 5th NAFAU National Congress 16-17 September 1994.</h3> Was held on 16-17 September 1994 at the NUNW Centre in Katutura. This congress had a theme: Educate, Consolidate, Advance to Victory and Reconstruction of Industries and Jobs Now. Congress adopted a number of reso­ solutions, the major ones being; <ul><li>To launch a national campaign aimed at curbing the high rate of retrenchment in the country at the time; to address the ongoing lack of human resources in NAFAU by employing officials with experience in trade unionism.</li><li>To implement a financial policy for NAFAU; to implement the resolution adopted at the March L99r congress to provide in-service training and education programmes in the workplace.</li><li>To call for the establishment of a Wage Commission to determinate a minimum wage for all workers in the food and allied industries.</li><li>To fight for improvements in the working and living conditions of workers in in the food and allied industries.</li><li>To revoke the resolution of the previous congress regarding NAFAU's non-affiliation to international trade unions, and to affiliate to the IUF and to the International Federation of Commercial, Clerical, Professional and Technical Employees (FIET) based in Geneva, Switzerland.</li></ul>`,
        year: "1994",
        title: "",
        caption: "One of the posters prepared for the fifth congress.",
        image: path + "fifth congress.jpg",
      },
    ],
    [
      {
        message:
          "<h3>The Hartlief Strike of 1995.</h3>Approximately 200 workers at Hartlief Continental Meat Products went on strike in July 1995 after the company failed to meet their demands for improved working conditions. Seven workers were dismissed after the strike began, and their colleagues demanded their reinstatement. NAFAU and the company finally agreed on a wage increase of N$100, but as a result, the workers' annual bonuses were terminated. In NAFAU's view, the company was trying to victimise union activists, as all the dismissed workers happened to be shop stewards. Since that time the relationship between Hartlief and NAFAU has greatly improved. Today they consult one another and negotiate on a regular basis.",
        year: "1995",
        title: "",
        caption: "",
        image: path + "hartlief 1995.jpg",
      },
      {
        message: "",
        year: "1995",
        title: "",
        caption:
          "A show of Unity is strength during the Hartlief strike in 1995.",
        image: path + "unity is strength.jpg",
      },
    ],
    [
      {
        message: "<h3>NAFAU’s first education conference held in 1996.</h3>",
        year: "1996",
        title: "",
        caption:
          "Philemon Makambuli of Omaruru makes a point during a NAFAU education conference.",
        image: path + "phelemon.jpg",
      },
    ],
    [
      {
        message:
          "<h3>The 6th NAFAU National Congress 29 May 1997.</h3>The congress was held on 29 May to the 1st June 1997 at the Shalom Centre in Windhoek. The theme for this congress was Organise, Strengthen, Build and Fight for a Programme of Transformation. The proceedings were recorded on audiotapes, but the tapes were lost so a comprehensive written record could not be produced.",
        year: "1997",
        title: "",
        caption: "",
        image: path + "",
      },
    ],
    [
      {
        message: "<h3>NAFAU seminar for women in Windhoek  in 1998.</h3>",
        year: "1998",
        title: "",
        caption:
          "Persca Mafala of Luderitz in a seminar for women in Windhoek.",
        image: path + "seminar.jpg",
      },
    ],
    [
      {
        message:
          "<h3>April 1999.</h3>In April 1999 the NEC issued a mandate for the establishment of a national gender structure which should cooperate with the NUNW/gender structure. The NAFAU Women's Desk was established, and it currently operates from NAFAU's Education Department, but it is not running any activities due to a lack of funds for a programme on gender. Since gender activities are not budgeted for in NAFAU's national budget, there is a need to secure additional funding for such activities.",
        year: "1999",
        title: "",
        caption: "",
        image: path + "",
      },
    ],
    [
      {
        message:
          "<h3>Shop Steward training manual launched 2000.</h3>In 2000 the NAFAU Education Department launched a shop-steward training manual to support the work of the other departments and to serve as reference material for shop stewards.",
        year: "2000",
        title: "",
        caption: "NAFAU shop stewards in training",
        image: path + "training manual.jpg",
      },
      {
        message: "",
        year: "2000",
        title: "",
        caption:
          "Swakopmund shop stewards after a training workshop, pictured with Swakopmund Branch Organiser Karin Amunyela (standing center).",
        image: path + "swakopmund training.jpg",
      },
    ],
    [
      {
        message: "Coming soon",
        year: "2001",
        title: "",
        caption: "",
        image: path + "",
      },
    ],
    [
      {
        message: "Coming soon",
        year: "2002",
        title: "",
        caption: "",
        image: path + "",
      },
    ],
    [
      {
        message: "Coming soon",
        year: "2003",
        title: "",
        caption: "",
        image: path + "",
      },
    ],
  ];

  return (
    <div className="our-story">
      <TimelineDots
        activeIndex={activeIndex}
        setactiveIndex={setactiveIndex}
        items={items}
      />

      <TimelineList
        activeIndex={activeIndex}
        setactiveIndex={setactiveIndex}
        items={items}
      />
    </div>
  );
};

export default Timeline;
