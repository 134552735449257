import React from "react";

const Members = () => {
  return (
    <div className="members section-structure">
      <div className="content">
        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>
                    Members
                  </p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">
                    Staff members shall be appointed on a permanent basis from time to
                    time by the NEC on the recommendations of the GS. They shall be
                    responsible for the growth of the organization and the
                    consolidation of the union’s members (Organizers in particular).
                    Staff shall be appointed into other capacities as the NEC deems
                    fit for the smooth running of the organization.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Staff responsible for organizing department shall attend SSC
                    meetings, the BEC, CEC meetings and the NC with the exemption of
                    the NEC.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Staff shall have no voting rights at any of the constitutional
                    gatherings or meetings. Remunerations and other conditions of
                    employment for the entire union staff and any other paid official
                    of the union shall be fixed by the NOB through the GS office.
                    Anyone willing to become a candidate at any elected position
                    she/he has to relinquish the current or the occupied position in
                    the union on the date of the election before participating in such
                    an election.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Members;
