import { IUser, defaultUser } from "../../shared/interfaces/IUser";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../../shared/alert/Alert";
import { useAppContext } from "../../shared/utils/Context";
import "./users.scss";
import { observer } from "mobx-react-lite";

interface IToolbar {
  onBack: () => void;
}
const Toolbar = (props: IToolbar) => {
  return (
    <div className="section-toolbar uk-margin">
      <h5 className="title" onClick={() => props.onBack()}>
        <span data-uk-icon="icon: chevron-left; ratio: .8"></span> Back
      </h5>
    </div>
  );
};

interface UserProps {
  user: IUser;
  setUser: React.Dispatch<React.SetStateAction<IUser>>;
  passwordField?: boolean;
}
export const UserForm = observer((props: UserProps) => {
  const { user, setUser, passwordField } = props;

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div>
      <div className="uk-margin">
        <label className="uk-form-label" htmlFor="fulltname">
          Full name
        </label>
        <div className="uk-form-controls">
          <input
            className="uk-input"
            type="text"
            name="fullName"
            placeholder="Full Name"
            value={user.fullName}
            onChange={(e) => setUser({ ...user, fullName: e.target.value })}
            required
          />
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label" htmlFor="user-email">
          Email
        </label>
        <div className="uk-form-controls">
          <input
            className="uk-input"
            type="email"
            name="email"
            placeholder="Email"
            value={user.email}
            onChange={(e) =>
              passwordField
                ? setUser({ ...user, email: e.target.value })
                : () => {}
            }
            disabled={!passwordField}
            required
          />
        </div>
      </div>
      {passwordField && (
        <div>
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Password
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type={passwordShown ? "text" : "password"}
                placeholder="Password"
                value={user.password}
                onChange={(e) => setUser({ ...user, password: e.target.value })}
                required
              />
              <button
                className={"passwordbutton"}
                onClick={togglePassword}
                type="button"
              >
                {passwordShown ? "Hide" : "Show"}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="uk-margin">
        <label className="uk-form-label" htmlFor="user-branch">
          Branch
        </label>
        <div className="uk-form-controls">
          <select
            className="uk-select"
            name="branch"
            value={user.branch}
            onChange={(e) => setUser({ ...user, branch: e.target.value })}
          >
            <option value={""}>Select</option>
            <option value={"Central Regions"}>Central Regions</option>
            <option value={"Western Regions"}>Western Regions</option>
            <option value={"Walvis Bay"}>Walvis Bay</option>
            <option value={"Swakopmund"}>Swakopmund</option>
            <option value={"Southern Regions"}>Southern Regions</option>
            <option value={"Luderitz"}>Luderitz</option>
            <option value={"Keetmanshoop"}>Keetmanshoop</option>
            <option value={"Noordoewer"}>Noordoewer</option>
            <option value={"Northern Regions"}>Northern Regions</option>
            <option value={"Oshakati"}>Oshakati</option>
            <option value={"Tsumeb"}>Tsumeb</option>
            <option value={"Rundu"}>Rundu</option>
            <option value={"Katima Mulilo"}>Katima Mulilo</option>
            <option value={"Windhoek"}>Windhoek</option>
          </select>
        </div>
      </div>
      <div className="uk-margin">
        <label className="uk-form-label" htmlFor="user-role">
          Role
        </label>
        <div className="uk-form-controls">
          <select
            className="uk-select"
            value={user.role}
            name="role"
            onChange={(e) =>
              setUser({
                ...user,
                role: e.target.value as "Admin" | "NEO" | "MR",
              })
            }
            required
          >
            <option value={""}>Select...</option>
            <option value={"Admin"}>Administrator</option>
            <option value={"NEO"}>News, Education and Offices</option>
            <option value={"MR"}>Membership and Reported Issues</option>
          </select>
        </div>
      </div>
    </div>
  );
});

const CreateNewUser = observer(() => {
  const { api, store } = useAppContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userExistError, setUserExistError] = useState(false);
  const [user, setUser] = useState<IUser>({
    ...defaultUser,
  });

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (store.users.selected) {
        const $user = await api.users.updateUser(user);
        if ($user) await store.users.load([$user]);
        alert("User Updated");
      } else {
        const $user = await api.users.createUser(user);
        if ($user) await store.users.load([$user]);
        alert("User Created");
      }
    } catch (error) {
      alert("Failed");
    }
    setLoading(false);
    store.users.clearSelected();
    navigate("/a/users");
  };

  const onBack = () => {
    navigate("/a/users");
  };

  useEffect(() => {
    if (store.users.selected) setUser(store.users.selected);
    else setUser({ ...defaultUser });
    return () => {};
  }, [store.users.selected]);

  return (
    <div className="create uk-section">
      <div className="uk-container">
        <Toolbar onBack={onBack} />
        <div className="uk-card uk-card-default uk-width-1-2@m">
          {userExistError && (
            <ErrorAlert
              msg="User account already exist!"
              onClose={() => setUserExistError(false)}
            />
          )}
          <div className="uk-card-body">
            <form onSubmit={onSubmit}>
              <UserForm
                user={user}
                setUser={setUser}
                passwordField={!store.users.selected}
              />
              <div className="uk-flex create-user-form-footer">
                <div>
                  <button className="kit-btn kit-btn-primary" type="submit">
                    Submit
                    {loading && (
                      <div
                        className="uk-margin-small-right"
                        data-uk-spinner="ratio: 0.5"
                      ></div>
                    )}
                  </button>
                </div>
                <div>
                  <button
                    className="kit-btn kit-btn-primary uk-margin-left"
                    type="button"
                    onClick={onBack}
                  >
                    Back
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});

export default CreateNewUser;
