import { makeAutoObservable } from "mobx";
import { IMemberApplication } from "../interfaces/IMemberApplication";

export class MemberApplication implements IMemberApplication {
  id: string;
  surname: string;
  firstName: string;
  cellphoneNo: string;
  workTelephone: number;
  homeTelephone: number;
  email: string;
  residentialAddress: string;
  postalAddress: string;
  idNumber: string;
  dateOfBirth: string;
  gender: "Male" | "Female";
  companyName: string;
  companyAddress: string;
  dateOfEmployment: string;
  natureOfEmployment: "Permanent" | "Contract" | "Tempolary";
  occupation: string;
  department: string;
  dutyStation: string;
  region: string;
  basicSalary: number;
  employmentCode: number;
  termsConditions: true;
  applicationDate: string;
  officialName: string;
  officialSignature: string;
  officialTell: number;
  officialCell: number;
  officialRegion: string;
  officialBranch: string;
  dataCaptureName: string;
  dataCaptureDate: string;
  dataCapturePlace: string;
  dataCaptureSignature: string;
  status: string;

  constructor(member: IMemberApplication) {
    makeAutoObservable(this);
    this.id = member.id;
    this.surname = member.surname;
    this.firstName = member.firstName;
    this.cellphoneNo = member.cellphoneNo;
    this.email = member.email;
    this.workTelephone = member.workTelephone;
    this.homeTelephone = member.homeTelephone;
    this.residentialAddress = member.residentialAddress;
    this.postalAddress = member.postalAddress;
    this.idNumber = member.idNumber;
    this.dateOfBirth = member.dateOfBirth;
    this.gender = member.gender;
    this.companyName = member.companyName;
    this.companyAddress = member.companyAddress;
    this.dateOfEmployment = member.dateOfEmployment;
    this.natureOfEmployment = member.natureOfEmployment;
    this.occupation = member.occupation;
    this.department = member.department;
    this.dutyStation = member.dutyStation;
    this.region = member.region;
    this.basicSalary = member.basicSalary;
    this.employmentCode = member.employmentCode;
    this.termsConditions = member.termsConditions;
    this.applicationDate = member.applicationDate;
    this.officialName = member.officialName;
    this.officialSignature = member.officialSignature;
    this.officialTell = member.officialTell;
    this.officialCell = member.officialCell;
    this.officialRegion = member.officialRegion;
    this.officialBranch = member.officialBranch;
    this.dataCaptureName = member.dataCaptureName;
    this.dataCaptureDate = member.dataCaptureDate;
    this.dataCapturePlace = member.dataCapturePlace;
    this.dataCaptureSignature = member.dataCaptureSignature;
    this.status = member.status;
  }
  get asJson(): IMemberApplication {
    return {
      id: this.id,
      surname: this.surname,
      firstName: this.firstName,
      cellphoneNo: this.cellphoneNo,
      email: this.email,
      homeTelephone: this.homeTelephone,
      workTelephone: this.workTelephone,
      residentialAddress: this.residentialAddress,
      postalAddress: this.postalAddress,
      idNumber: this.idNumber,
      dateOfBirth: this.dateOfBirth,
      gender: this.gender,
      companyName: this.companyName,
      companyAddress: this.companyAddress,
      dateOfEmployment: this.dateOfEmployment,
      natureOfEmployment: this.natureOfEmployment,
      occupation: this.occupation,
      department: this.department,
      dutyStation: this.dutyStation,
      region: this.region,
      basicSalary: this.basicSalary,
      employmentCode: this.employmentCode,
      termsConditions: this.termsConditions,
      applicationDate: this.applicationDate,
      officialName: this.officialName,
      officialSignature: this.officialSignature,
      officialTell: this.officialTell,
      officialCell: this.officialCell,
      officialRegion: this.officialRegion,
      officialBranch: this.officialBranch,
      dataCaptureName: this.dataCaptureName,
      dataCaptureDate: this.dataCaptureDate,
      dataCapturePlace: this.dataCapturePlace,
      dataCaptureSignature: this.dataCaptureSignature,
      status: this.status,
    };
  }
}
