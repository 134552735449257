import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../shared/utils/Context";
import UsersRow from "./UsersRow";
import { defaultUser, IUser } from "../../shared/interfaces/IUser";
import "./users.scss";

const UsersTable = observer(() => {
  const { api, store } = useAppContext();
  const navigate = useNavigate();

  const onView = (user: IUser) => {
    store.users.select(user);
    navigate(`/a/users/${user.uid}`);
  };

  const onDelete = async (uid: string) => {
    if (!window.confirm("Do you want to delete?")) return;
    await api.users.deleteUserFromDB(uid);
  };

  useEffect(() => {
    api.users.loadAll();
    return () => {};
  }, []);

  return (
    <div className="admin-user-table uk-card uk-card-default">
      <table className="users-table uk-table uk-table-small uk-table-divider ">
        <thead>
          <tr>
            <th>#</th>
            <th>Full Names</th>
            <th>Email</th>
            <th>Branch</th>
            <th>Role</th>
            <th className="uk-text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          {store.users.all?.map((user, index) => (
            <tr key={user.uid}>
              <UsersRow
                index={index}
                user={user}
                onView={onView}
                onDelete={onDelete}
              />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default UsersTable;
