import Icons from "./Icons";
import "./Background.scss";

const Background = () => {
  return (
    <div
      className="background uk-section"
      data-aos="fade-in"
      data-aos-delay="200"
    >
      <div className="container uk-container">
        <div className="uk-grid-match uk-child-width-expand@s" data-uk-grid>
          <div>
            <div className="uk-card uk-card-default uk-card-body uk-text-center vision-mission">
              <h4 className="vision-mission-title">
                Our Vision, Mission & Values
              </h4>
            </div>
          </div>
          <div>
            <div className="uk-card uk-card-default uk-card-body vision">
              <h6 className="title">Our Vision.</h6>
              <p>
                To organize, represent and educate our members.
                <br />
                To settle differences between our members and their employers.
                <br />
                To strive for the improvement of working and living conditions
                for our members.
                <br />
                To engage stakeholders on behalf of our members.
                <br />
                To grow and strengthen the capacity of the Union.
                <br />
                To be the union of choice for all employees within our scope.
              </p>
            </div>
          </div>
          <div>
            <div className="uk-card uk-card-default uk-card-body mission">
              <h6 className="title">Our Mission.</h6>
              <p>
                To defend and protect the rights and interests of our members
                and negotiate on behalf of our members.
              </p>
            </div>
          </div>
        </div>
        <div className="values">
          <h6 className="title">Our Core Values</h6>
          <div
            className="uk-flex uk-flex-center uk-grid-small uk-child-width-1-2 uk-child-width-1-4@m uk-child-width-1-5@l uk-grid-match"
            data-uk-grid
            data-uk-scrollspy="target: .core-value; cls: uk-animation-slide-bottom; delay: 100"
          >
            <div>
              <div className="core-value uk-card uk-card-default uk-card-small uk-card-body uk-card-hover">
                <div className="icon-container">
                  <Icons.ElectectedIcon
                    className="icon"
                    fill="none"
                    height={80}
                    width={80}
                  />
                </div>

                <h6 className="value">ELECTED LEADERSHIP</h6>
                <p>The courage for capable leadership and succession.</p>
              </div>
            </div>

            <div>
              <div className="core-value uk-card uk-card-default uk-card-small uk-card-body uk-card-hover">
                <div className="icon-container">
                  <Icons.CollaborationIcon
                    className="icon"
                    fill="none"
                    height={80}
                    width={80}
                  />
                </div>
                <h6 className="value">COLLABORATION</h6>
                <p>Leveraging on our collective bargaining.</p>
              </div>
            </div>

            <div>
              <div className="core-value uk-card uk-card-default uk-card-small uk-card-body uk-card-hover">
                <div className="icon-container">
                  <Icons.IntegrityIcon
                    className="icon"
                    fill="none"
                    height={80}
                    width={80}
                  />
                </div>
                <h6 className="value">INTEGRITY</h6>
                <p>Transparency and truthfulness.</p>
              </div>
            </div>

            <div>
              <div className="core-value uk-card uk-card-default uk-card-small uk-card-body uk-card-hover">
                <div className="icon-container">
                  <Icons.AccountabilityIcon
                    className="icon"
                    fill="none"
                    height={80}
                    width={80}
                  />
                </div>
                <h6 className="value">ACCOUNTABILITY</h6>
                <p>Being responsible for our actions.</p>
              </div>
            </div>

            <div>
              <div className="core-value uk-card uk-card-default uk-card-small uk-card-body uk-card-hover">
                <div className="icon-container">
                  <Icons.PassionIcon
                    className="icon"
                    fill="none"
                    height={80}
                    width={80}
                  />
                </div>
                <h6 className="value">PASSION</h6>
                <p>Showing commitment in heart and mind.</p>
              </div>
            </div>

            <div>
              <div className="core-value uk-card uk-card-default uk-card-small uk-card-body uk-card-hover">
                <div className="icon-container">
                  <Icons.DiversityIcon
                    className="icon"
                    fill="none"
                    height={80}
                    width={80}
                  />
                </div>
                <h6 className="value">DIVERSITY</h6>
                <p>
                  Promote inclusive participation in the Union structures and
                  beyond.
                </p>
              </div>
            </div>

            <div>
              <div className="core-value uk-card uk-card-default uk-card-small uk-card-body uk-card-hover">
                <div className="icon-container">
                  <Icons.QualityIcon
                    className="icon"
                    fill="none"
                    height={80}
                    width={80}
                  />
                </div>
                <h6 className="value">QUALITY</h6>
                <p>Excellent service delivery.</p>
              </div>
            </div>

            <div>
              <div className="core-value uk-card uk-card-default uk-card-small uk-card-body uk-card-hover">
                <div className="icon-container">
                  <Icons.DedicationIcon
                    className="icon"
                    fill="none"
                    height={80}
                    width={80}
                  />
                </div>
                <h6 className="value">DEDICATION</h6>
                <p>Devoted to the aims and objectives of the Union.</p>
              </div>
            </div>

            <div>
              <div className="core-value uk-card uk-card-default uk-card-small uk-card-body uk-card-hover">
                <div className="icon-container">
                  <Icons.CompetencyIcon
                    className="icon"
                    fill="none"
                    height={80}
                    width={80}
                  />
                </div>
                <h6 className="value">COMPETENCY</h6>
                <p>
                  Being effective and efficient to act on behalf of the Union.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Background;
