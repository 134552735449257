import React from "react";

const SSC = () => {
  return (
    <div className="ssc section-structure">
      <h6 className="title">Shop Steward Committee (SSC)</h6>
      <div className="content">
        <ol className="list">
          <li className="list-item">
            <p className="list-item-text">
              In every institution or establishment where NAFAU members are
              employed, such members shall be entitled to elect from amongst
              themselves a Shop Steward/Workers Representative with alternatives
              shop stewards as Shop Steward Committee (SSC) and among themselves
              will appoint a senior shop steward.
            </p>
          </li>
          <li className="list-item">
            <p className="list-item-text">
              The elections of SSC shall take place at a meeting of NAFAU
              members employed at the institution and establishment within
              NAFAU’s scope. All nominations shall be duly proposed and seconded
              by such members, and they shall cast their votes by ballot or by
              raising their hands. An SSC shall consist not less than four (4)
              Shop Stewards representing the various work section from which the
              union has members at that institution or establishment.{" "}
            </p>
          </li>

          <li className="list-item">
            <p className="list-item-text">
              The SSC shall meet at least once a week, at their workplace. Shop
              Stewards shall hold office, not for a period of longer than two
              (2) years from the date of their election and shall vacate their
              positions due to any of the following circumstances:
            </p>
            <hr />
            <div className="uk-grid-match" data-uk-grid>
              <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
                <div className="uk-card uk-card-default uk-card-body card-title">
                  <div className="title">
                    <div>
                      <p>
                        The SSC circumstances
                      </p>
                    </div>
                    <div className="line-div">
                      <div className="line"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-width-expand@m">
                <div className="uk-card uk-card-default uk-card-body card-content">
                  <ol className="list">
                    <li className="list-item">
                      <p className="list-item-text">
                        If their contract of employment ceased at which he/she was
                        elected.
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="list-item-text">
                        Failing to attend three consecutive meetings of the SSC
                        without providing a valid reason to the branch Chairperson of
                        which the validity shall be decided by the SSC members and
                        shall hold the office until the next election, where they
                        shall be eligible for re-election.
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="list-item-text">
                        In the event of a vacancy arising on any SSC, a Bi election
                        shall be held at least 10 days of such a vacancy arising.
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="list-item-text">
                        The Shop Stewards elected shall hold office for the unexpired
                        period of his/her predecessor term of office in case of a held
                        by-election.
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="list-item-text">
                        The function of the SSC shall be to manage the affairs of
                        NAFAU within the institution or establishment where they are
                        employed.
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="list-item-text">
                        Shop Stewards should be responsible for receiving and
                        attending to complaints raised by members, concerning their
                        employment and where necessary to report such complaints to
                        the BEC.
                      </p>
                    </li>
                    <li className="list-item">
                      <p className="list-item-text">
                        They are responsible for reporting any contravention of status
                        or any improper or unfair employment practice to the BEC.
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default SSC;
