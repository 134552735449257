import React, { Fragment, useEffect, useState } from "react";
import IHistoryTimelineItem from "../../../shared/interfaces/IHistoryTimelineItem";
import TimelineItem from "./TimelineItem";

interface IProps {
  activeIndex: number;
  setactiveIndex: React.Dispatch<React.SetStateAction<number>>;
  items: IHistoryTimelineItem[][];
}
const TimelineList = (props: IProps) => {
  const { activeIndex, items } = props;

  const sliderItemsRef = React.useRef<HTMLUListElement>(null);
  const [sliderItemsWidth, setSliderItemsWidth] = useState(0);
  const [activeTimelineItemHeight, setActiveTimelineItemHeight] = useState<string>('auto');

  useEffect(() => {
    const sliderItems = sliderItemsRef.current;
    if (sliderItems) {
      setSliderItemsWidth(sliderItems.offsetWidth);
      sliderItems.style.transform = `translate3d(-${activeIndex * sliderItemsWidth
        }px, 0, 0)`;
    }
  }, [activeIndex, sliderItemsWidth]);

  useEffect(() => {
    const sliderItems = sliderItemsRef.current;
    if (sliderItems) {
      setSliderItemsWidth(sliderItems.offsetWidth);
      // sliderItemsRef.current.style.height = activeTimelineItemHeight
    } else {
      setSliderItemsWidth(0);
    }
  }, [sliderItemsRef, activeTimelineItemHeight]);


  return (
    <div
      className="timeline-list uk-position-relative uk-visible-toggle"
      tabIndex={-1}
      uk-slider="finite: true; center: true"
    >
      <ul
        id="timeline-slider-items"
        className="uk-slider-items uk-grid-small uk-child-width-1-1"
        data-uk-grid
        ref={sliderItemsRef}
        style={{
          height: activeTimelineItemHeight
        }}
      >
        {items.map((item, index) => (
          <Fragment key={index}>
            {(activeIndex === index) &&
              <TimelineItem index={index} item={item} isActive={activeIndex === index} setActiveTimelineItemHeight={setActiveTimelineItemHeight} />
            }
          </Fragment>
        ))}
      </ul>
    </div>
  );
};

export default TimelineList;
