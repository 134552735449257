import React from "react";
import Modal from "../shared/components/modal/Modal";
import LoginAdmin from "./dialogs/log-in-admin/LoginAdmin";
import DIALOG_NAMES from "./dialogs/Dialogs";
import Footer from "./footer/Footer";
import NavBar from "./navigation/NavBar";
import WebRouting from "./WebRouting";

const contentStyle: React.CSSProperties = {
  minHeight: "50vh",
};

const LoggedOut = () => {
  return (
    <>
      <div className="logged-out">
        <NavBar />
        <div className="content" style={contentStyle}>
          <WebRouting />
        </div>
        <Footer />
      </div>
      <Modal modalId={DIALOG_NAMES.LOG_IN_ADMIN}>
        <LoginAdmin />
      </Modal>
    </>
  );
};

export default LoggedOut;
