import React from "react";

const OfflineApplication = () => {
  const membershipForm = `${process.env.PUBLIC_URL}/assets/documents/NAFAU MEMBERSHIP APPLICATION FORM 2022.pdf`;

  return (
    <div className="offline-application">
      <div className="uk-card uk-card-default uk-card-body">
        <h5 className="title">Hand Delivered Application</h5>

        <a className="download " href={membershipForm} download>
          Download The Application Form <span data-uk-icon="download"></span>
        </a>
      </div>
    </div>
  );
};

export default OfflineApplication;
