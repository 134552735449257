import React from "react";
import SectionHeading from "../../../shared/components/section-heading/SectionHeading";
import IntroSection from "../../../shared/Intro/IntroSection";
import "./Benefits.scss";

const MemberBenefits = () => {
  const benefitsData = [
    {
      desciption:
        "<p>To improve employment conditions and unfair work environment.</p><p>Improve employment conditions through collective bargaining.</p>",
      img: "/assets/icons/Speaker3.svg",
    },
    {
      desciption: `Protects members from unfair labour practices which include unfair dismissals, retrenchment, and unfair labour practices.`,
      img: "/assets/icons/cases.svg",
    },
    {
      desciption: "Retrenchment package negotiations.",
      img: "/assets/icons/branches.svg",
    },
    {
      desciption: "<p>Represent members at disciplinary hearings.</p>",
      img: "/assets/icons/member.svg",
    },
    {
      desciption: "<p>Free legal assistance on labour matters.</p>",
      img: "/assets/icons/Accountability.svg",
    },
    {
      desciption: "Provides discussion platforms.",
      img: "/assets/icons/Speaker4.svg",
    },
  ];

  const title = [
    {
      id: "1",
      title: "Membership Benefits",
      desc: `Membership incentives provided by NAFAU.`,
      backgroundImage:
        process.env.PUBLIC_URL + "/assets/background/lestshake.jpg",
    },
  ];

  return (
    <div className="benefits">
      <IntroSection data={title} />
      <div className="member-benefits uk-section">
        <div className="container uk-container">
          <div className="content">
            <div className="uk-child-width-1-3@m  uk-grid-match" data-uk-grid>
              {benefitsData.map((benefit) => (
                <div
                  className="benefit-card uk-flex uk-flex-center uk-grid-small uk-child-width-1-2 uk-child-width-1-4@m uk-child-width-1-5@l uk-grid-match"
                  data-uk-grid
                  data-uk-scrollspy="target: .core-value; cls: uk-animation-slide-bottom; delay: 100"
                >
                  <div>
                    <div className="core-value uk-card uk-card-default uk-card-small uk-card-body uk-card-hover">
                      <div className="icon-container">
                        <img
                          src={process.env.PUBLIC_URL + benefit.img}
                          alt="icon"
                          className="icon"
                          height={80}
                          width={80}
                        />
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: benefit.desciption }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <SectionHeading
              heading="Membership APPLICATION process"
              subheading="How to become part of NAFAU?"
            />
            <div
              className="recruitment-process uk-grid-collapse uk-grid-match uk-child-width-expand@s uk-text-center uk-margin-large-top"
              data-uk-grid
            >
              <div data-aos="fade-in" data-aos-delay="100">
                <div className="uk-card uk-card-default">
                  <div className="uk-card-body">
                    <div className="icon-container">
                      <div className="circle">01</div>
                    </div>
                    <p> Fill in the membership form (physical or digital).</p>
                  </div>
                </div>
              </div>
              <div data-aos="fade-in" data-aos-delay="200">
                <div className="uk-card uk-card-default">
                  <div className="uk-card-body">
                    <div className="icon-container">
                      <div className="circle">02</div>
                    </div>
                    <p>
                      Submit the form digitally or to NAFAU offices for data
                      capturing.
                    </p>
                  </div>
                </div>
              </div>
              <div data-aos="fade-in" data-aos-delay="300">
                <div className="uk-card uk-card-default">
                  <div className="uk-card-body">
                    <div className="icon-container">
                      <div className="circle">03</div>
                    </div>
                    <p>On approval, enjoy NAFAU benefits.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberBenefits;

// <div>
//   <div
//     className="uk-card uk-card-default"
//     data-aos="fade-in"
//     data-aos-delay="100"
//   >
//     <div className="uk-card-media-top">
//       <img
//         src={`${process.env.PUBLIC_URL + benefit.img}`}
//         width="1800"
//         height="1200"
//         alt={benefit.desciption}
//       />
//     </div>
//     <div className="uk-card-body">
//       <p>{benefit.desciption}</p>
//     </div>
//   </div>
// </div>
// {
//   desciption: "Improve unfair work environment.",
//   img: "/assets/icons/know-your-right.jpg",
// },
// {
//   desciption: `Improve employment conditions through collective bargaining.`,
//   img: "/assets/icons/economic-gap.jpg",
// },
