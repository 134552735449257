import React from "react";
import IHistoryTimelineItem from "../../../shared/interfaces/IHistoryTimelineItem";


interface IProps {
  activeIndex: number;
  setactiveIndex: React.Dispatch<React.SetStateAction<number>>;
  items: IHistoryTimelineItem[][];
}
const TimelineDots = (props: IProps) => {
  const { activeIndex, setactiveIndex, items } = props;

  const onClick = (index: number) => {
    // setactiveIndex(index);
    setactiveIndex(() => (index))
  };

  return (
    <div className="timeline-dots">
      {items?.map((item, index) => (
        <div
          className={`dot ${(index === activeIndex) ? "active" : ""}`}
          key={index}
          onClick={() => onClick(index)}
        >
          <div className="year">{item[0].year}</div>
        </div>
      ))}
    </div>
  );
};

export default TimelineDots;
