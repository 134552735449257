import React from "react";
import { NavLink } from "react-router-dom";
import "./OffCanvasNavBar.scss";

const OffCanvasNavBar = () => {
  return (
    <div id="offcanvas-navbar" uk-offcanvas="overlay: true">
      <div className="uk-offcanvas-bar uk-flex uk-flex-column">
        <ul className="uk-nav uk-nav-primary uk-margin-auto-vertical">
          <li className="nav-item uk-active">
            <NavLink to={"/"}>Home</NavLink>
          </li>

          <li className="nav-item">
            <a href="#">About Us</a>
            <ul className="uk-nav-sub">
              <li>
                <NavLink to={"about-us/history"}>History</NavLink>
              </li>
              <li>
                <NavLink to={"about-us/gallery"}>Gallery</NavLink>
              </li>
              <li>
                <NavLink to={"about-us/scope"}>Scope of NAFAU</NavLink>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <a href="#">Governance</a>
            <ul className="uk-nav-sub">
              <li>
                <NavLink to={"governance/structure"}>Our structure</NavLink>
              </li>
              <li>
                <NavLink to={"governance/NOB"}>Our leaders</NavLink>
              </li>
              <li>
                <NavLink to={"governance/executive-committee"}>
                  Administration and Operation Committee
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <a href="#">Membership</a>
            <ul className="uk-nav-sub">
              <li>
                <NavLink to={"members/apply"}>Apply for Membership</NavLink>
              </li>
              <li>
                <NavLink to={"members/benefits"}>Member Benefits</NavLink>
              </li>
              <li>
                <NavLink to={"members/report-a-problem"}>
                  Report an Issue at Work
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <NavLink to={"education"}>Education</NavLink>
          </li>

          <li className="nav-item">
            <NavLink to={"news"}>News</NavLink>
          </li>

          <li className="nav-item">
            <NavLink to={"members/offices"}>Contact Us</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default OffCanvasNavBar;
