import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import IEducationPost from "../../../shared/interfaces/IEducationPost";
import { useAppContext } from "../../../shared/utils/Context";
import EducationItem from "./EducationItem";


interface IToolbar {
  onCreate: () => void;
}
const Toolbar = (props: IToolbar) => {
  return (
    <div className="section-toolbar uk-margin">
      <button
        className="kit-btn kit-btn-primary"
        onClick={() => props.onCreate()}
      >
        <span data-uk-icon="icon: plus-circle; ratio: .8"></span> New Post
      </button>
    </div>
  );
};

const Education = observer(() => {
  const { api, store } = useAppContext();
  const navigate = useNavigate();

  const onCreate = () => {
    navigate(`./new`);
  };

  const onView = (post: IEducationPost) => {
    store.education.select(post);
    navigate(`./${post.id}`);
  };

  const onDelete = async (id: string) => {
    await api.education.delete(id);
  };

  useEffect(() => {
    api.education.loadAll();
    return () => {};
  }, []);

  return (
    <div className="union-101 uk-section">
      <div className="container uk-container uk-container-large">
        <Toolbar onCreate={onCreate} />

        <div className="union-101-items">
          {store.education.all.map((post) => (
            <div key={post.id}>
              <EducationItem post={post} onView={onView} onDelete={onDelete} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default Education;
