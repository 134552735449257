import React, { ChangeEvent, useState } from "react";

interface IProps {
  src: string;
  setSrc: React.Dispatch<React.SetStateAction<string>>;
  isUploading: boolean;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>;
}
const PostThumbnail = (props: IProps) => {
  const { src, setSrc, isUploading, setSelectedFile } = props;

  const uploadingCss = isUploading ? "uploading" : "";

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const file =
      e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;

    if (!file) return;

    setSelectedFile(file);

    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        // convert image file to base64 string
        const $src = reader.result?.toString() || "";
        setSrc($src);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }

    e.target.value = "";
  };

  return (
    <label className="thumbnail uk-card uk-card-default uk-card-small uk-card-body uk-margin">
      <img src={src} alt="" />
      <div className={`tools ${uploadingCss}`}>
        <input type="file" accept="image/*" onChange={onFileChange} />
        <span data-uk-icon="icon: image; ratio: 3"></span>
        <span className="thumbnail-label">
          {isUploading ? "Uploading..." : "Upload Thumbnail"}
        </span>
      </div>
    </label>
  );
};

export default PostThumbnail;
