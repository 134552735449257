import React, { useEffect, useState } from "react";
import IHistoryTimelineItem from "../../../shared/interfaces/IHistoryTimelineItem";

interface IProps {
  isActive: boolean;
  index: number;
  item: IHistoryTimelineItem[];
  setActiveTimelineItemHeight: React.Dispatch<React.SetStateAction<string>>;
}
const TimelineItem = (props: IProps) => {
  const { index, item, isActive, setActiveTimelineItemHeight } = props;
  const ref = React.useRef<HTMLLIElement>(null);

  // removed from useEffect and worked
  const height = ref.current?.clientHeight;
  useEffect(() => {
    if (!ref.current) return;

    if (isActive) {
      // const height = ref.current?.clientHeight;
      console.log("Setting active height: ", height);
      setActiveTimelineItemHeight(height + "px");
    }
  }, [isActive, setActiveTimelineItemHeight, index, ref]);

  return (
    <li className="timeline-item" ref={ref}>
      <div className="uk-width-2-3@m">
        <div
          className="item-message"
          dangerouslySetInnerHTML={{ __html: item[0].message }}
        />
      </div>
      {item.map((histItem, i) => (
        <>
          <div
            className="item-grid uk-grid-small uk-margin"
            data-uk-grid
            key={i}
          >
            <div
              className="thumbnail uk-width-2-3@m"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <img src={histItem.image} alt="" />
            </div>

            <div className="content uk-width-1-3@m">
              <div>
                {/* <h6 className="year">Year: {histItem.year}</h6> */}
                <h6 className="title">{histItem.title}</h6>
                <p className="caption">{histItem.caption}</p>
              </div>
            </div>
          </div>
        </>
      ))}
    </li>
  );
};

export default TimelineItem;
