import NewsStore from "./NewsStore";
import ReportedIssuesStore from "./ReportedIssuesStore";
import EducationStore from "./EducationStore";
import MemberApplicationStore from "./MemberApplicationStore";
import UserStore from "./UserStore";
import OfficeStore from "./OfficeStore";

export default class AppStore {
  news: NewsStore;
  education: EducationStore;
  reportedIssues: ReportedIssuesStore;
  memberApplications: MemberApplicationStore;
  users: UserStore;
  offices: OfficeStore;
  user: any;

  constructor() {
    this.news = new NewsStore(this);
    this.education = new EducationStore(this);
    this.reportedIssues = new ReportedIssuesStore(this);
    this.memberApplications = new MemberApplicationStore(this);
    this.users = new UserStore(this);
    this.offices = new OfficeStore(this);
  }
}
