import {
  onSnapshot,
  doc,
  collection,
  deleteDoc,
  setDoc,
  getDoc,
  getDocs,
  query,
} from "firebase/firestore";
import { db } from "../utils/firebaseConfig";
import IOffice, { defaultOffice } from "../interfaces/IOffice";
import AppStore from "../stores/AppStore";

const $collection = "offices";

class OfficesApi {
  private colRef = collection(db, $collection);

  constructor(private store: AppStore) {}

  async loadAll() {
    const $query = query(collection(db, $collection));
    const querySnapshot = await getDocs($query);
    const offices = querySnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id } as IOffice;
    });
    this.store.offices.load(offices);
  }
  async loadById(id: string) {
    const $doc = await getDoc(doc(this.colRef, id));
    if (!$doc.exists()) return;
    const issues = [{ id: $doc.id, ...$doc.data() } as IOffice];
    this.store.offices.load(issues);
  }

  create(office: IOffice) {
    const $office = { ...office };
    if (!$office.id) $office.id = doc(this.colRef).id;
    return setDoc(doc(this.colRef, $office.id), $office, { merge: true });
  }

  async delete(id: string) {
    await deleteDoc(doc(this.colRef, id));
    this.store.offices.remove(id);
  }
}

export default OfficesApi;
