import IssuesTable from "./IssuesTable";
import "./ReportedIssues.scss";

const ReportedIssues = () => {
  return (
    <div className="admin-reported-issues uk-section">
      <div className="container uk-container uk-container-large">
        <div className="issues">
          <div className="uk-flex uk-flex-between">
            <h5 className="title">Reported Issues</h5>
          </div>

          <IssuesTable />
        </div>
      </div>
    </div>
  );
};

export default ReportedIssues;
