import React, { useState } from "react";
import { galleryImages } from "./galleryImages";
import "./Gallery.scss";

interface IGallery {
  year: number;
  img: string;
}

interface IImage {
  image: IGallery;
}

const GalleryItem = (props: IImage) => {
  const { image } = props;
  const { img } = image;
  const loadingGIF = `${process.env.PUBLIC_URL}/assets/gallery/Loading.gif`;
  const [isLoading, setIsLoading] = useState(true);

  function onLoad() {
    setTimeout(() => setIsLoading(false), 1000);
  }

  return (
    <div className="uk-card-default gallery-item">
      <img
        alt="Loading"
        src={loadingGIF}
        style={{ display: isLoading ? "block" : "none" }}
      />
      <img
        src={`${process.env.PUBLIC_URL + img}`}
        alt="NAFAU"
        style={{ display: isLoading ? "none" : "block" }}
        onLoad={onLoad}
      />
    </div>
  );
};

const GalleryGrid = () => {
  const years = galleryImages.map((image) => image.year);
  const yearlyImages = (year: number) => {
    return galleryImages.filter((image) => image.year === year);
  };

  const yearImages = () => {
    return Array.from(new Set(years));
  };

  return (
    <div>
      {yearImages()
        .sort((a, b) => (a < b ? 1 : a > b ? -1 : 0))
        .map((year) => (
          <div className="uk-margin" key={year}>
            <h6 className="year-title">{year}</h6>
            <div
              className="gallery-grid uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l"
              data-uk-grid="masonry: true"
              data-uk-lightbox="animation: fade"
              data-uk-scrollspy="target: .gallery-item; cls: uk-animation-slide-bottom; delay: 100"
            >
              {yearlyImages(year).map((image) => (
                <a
                  href={image.img}
                  data-caption={image.year}
                  data-alt={image.index}
                >
                  <GalleryItem image={image} />
                </a>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

const Gallery = () => {
  return (
    <div className="gallery uk-section uk-section-small">
      <div className="uk-container uk-container-xlarge">
        <GalleryGrid />
      </div>
    </div>
  );
};

export default Gallery;
