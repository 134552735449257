import React from "react";
import { User } from "../../shared/models/User";
import { IUser } from "../../shared/interfaces/IUser";

interface IProps {
  index: number;
  user: User;
  onView: (user: IUser) => void;
  onDelete: (uid: string) => void;
}

const UsersRow = (props: IProps) => {
  const { index, user } = props;

  const onView = () => {
    props.onView(user.asJson);
  };

  const onDelete = () => {
    props.onDelete(user.uid);
  };

  return (
    <>
      <td>{index + 1}</td>
      <td>{user.fullName}</td>
      <td>{user.email}</td>
      <td>{user.branch}</td>
      <td>{user.role}</td>
      <td className="controls uk-text-right">
        <button
          className="kit-btn-text kit-btn-primary uk-margin-small-right"
          onClick={onView}
        >
          <span data-uk-icon="file-edit"></span> View
        </button>
        <button className="kit-btn-text kit-btn-danger" onClick={onDelete}>
          <span data-uk-icon="trash"></span> Delete
        </button>
      </td>
    </>
  );
};
export default UsersRow;
