import React, { MouseEvent, useState } from "react";
import SectionHeading from "../../../shared/components/section-heading/SectionHeading";
import "./ExecutiveCommittee.scss";

interface ICommitteeMemberItem {
  imgUrl: string;
  name: string;
  role: string;
}
const CommitteeMemberItem = (props: ICommitteeMemberItem) => {
  return (
    <div className="committee-member-item uk-card uk-card-default uk-card-small">
      <div className="thumbnail uk-card-media-top">
        <img src={props.imgUrl} alt="" />
      </div>
      <div className="description uk-card-body">
        <h6 className="name">{props.name}</h6>
        <p className="role">{props.role}</p>
      </div>
    </div>
  );
};

const ExecutiveCommittee = () => {
  return (
    <div className="executive-committee uk-section">
      <div className="container uk-container">
        <SectionHeading
          heading="Administration and operation Committee."
          subheading="It is composed of leaders that act as a steering committee for the NOB."
        />

        <div
          className="uk-margin uk-grid-small uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m uk-text-center"
          data-uk-grid
        >
          <div>
            <CommitteeMemberItem
              imgUrl={
                process.env.PUBLIC_URL +
                "/assets/NOB pictures/General Secretary.jpg"
              }
              name={"Cde. Jacob Penda"}
              role={"General Secretary"}
            />
          </div>

          <div>
            <CommitteeMemberItem
              imgUrl={
                process.env.PUBLIC_URL +
                "/assets/NOB pictures/Deputy General Secretary.jpg"
              }
              name={"Cde. Wilhelma Absalom"}
              role={"Deputy General Secretary"}
            />
          </div>
          <div>
            <CommitteeMemberItem
              imgUrl={
                process.env.PUBLIC_URL + "/assets//NOB pictures/marianna.jpg"
              }
              name={"Cde. Mariana Shaakumeni"}
              role={"Personal Assistant (Office of the General Secretary)"}
            />
          </div>
        </div>
        <div
          className="uk-margin uk-grid-small uk-grid-match uk-child-width-1-2@s uk-child-width-1-4@m uk-text-center"
          data-uk-grid
        >
          <div>
            <CommitteeMemberItem
              imgUrl={
                process.env.PUBLIC_URL +
                "/assets/NOB pictures/Senior Finance Hafeni.png"
              }
              name={"Cde. Hafeni Hamukoto"}
              role={"Senior Finance Officer"}
            />
          </div>

          <div>
            <CommitteeMemberItem
              imgUrl={
                process.env.PUBLIC_URL +
                "/assets/NOB pictures/Ngaifiwa_Festus_Shilombuelua.png"
              }
              name={"Cde. Ngaifiwa Festus Shilombuelua"}
              role={"National Membership Administrator"}
            />
          </div>

          <div>
            <CommitteeMemberItem
              imgUrl={process.env.PUBLIC_URL + "/assets/user_placeholder.png"}
              name={"Cde. Hilifavali Shikongo"}
              role={"National Coordinator"}
            />
          </div>
          <div>
            <CommitteeMemberItem
              imgUrl={process.env.PUBLIC_URL + "/assets/user_placeholder.png"}
              name={"Cde. Lister Tawana"}
              role={"National Education & Research Coordinator"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExecutiveCommittee;
