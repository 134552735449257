import { makeObservable, runInAction, toJS } from "mobx";
import IOffice from "../interfaces/IOffice";
import { OfficePost } from "../models/OfficePost";
import AppStore from "./AppStore";
import Store from "./Store";

export default class OfficeStore extends Store<IOffice, OfficePost> {
  items = new Map<string, OfficePost>();

  constructor(store: AppStore) {
    super(store);

    makeObservable(this, {
      items: true,
      selected: true,
    });

    this.store = store;
  }

  load(items: IOffice[] = []) {
    runInAction(() => {
      items.forEach((item) => this.items.set(item.id, new OfficePost(item)));
    });
  }
}
