import React from "react";

interface IProps {
  isActive: (nav: string) => " active" | "";
}

const StructureNav = (props: IProps) => {
  const { isActive } = props;

  return (
    <ul className="structure-nav uk-card uk-card-default">
      <li className={"structure-nav-item" + isActive("#overview")}>
        <a href="#overview" className="structure-nav-link">
          Overview
        </a>
      </li>
      <li className={"structure-nav-item" + isActive("#aos")}>
        <a href="#aos" className="structure-nav-link">
          Administrative Organisation Structure
        </a>
      </li>
      <li className={"structure-nav-item" + isActive("#nc")}>
        <a href="#nc" className="structure-nav-link">
          National Congress (NC)
        </a>
      </li>
      <li className={"structure-nav-item" + isActive("#cec")}>
        <a href="#cec" className="structure-nav-link">
          Central Executive Committee (CEC)
        </a>
      </li>
      <li className={"structure-nav-item" + isActive("#nec")}>
        <a href="#nec" className="structure-nav-link">
          National Executive Committee (NEC)
        </a>
      </li>
      <li className={"structure-nav-item" + isActive("#nob")}>
        <a href="#nob" className="structure-nav-link">
          National Office Bearers (NOB)
        </a>
      </li>
      <li className={"structure-nav-item" + isActive("#rbc")}>
        <a href="#rbc" className="structure-nav-link">
          Regional Bi-annual Conference (RBC)
        </a>
      </li>
      <li className={"structure-nav-item" + isActive("#rec")}>
        <a href="#rec" className="structure-nav-link">
          Regional Executive Committee (REC)
        </a>
      </li>
      <li className={"structure-nav-item" + isActive("#bbac")}>
        <a href="#bbac" className="structure-nav-link">
          Branch Bi-Annual Committee (BBAC)
        </a>
      </li>
      <li className={"structure-nav-item" + isActive("#bec")}>
        <a href="#bec" className="structure-nav-link">
          Branch Executive Committee (BEC)
        </a>
      </li>
      <li className={"structure-nav-item" + isActive("#ssc")}>
        <a href="#ssc" className="structure-nav-link">
          Shop Steward Committee (SSC)
        </a>
      </li>
      <li className={"structure-nav-item" + isActive("#members")}>
        <a href="#members" className="structure-nav-link">
          Members
        </a>
      </li>
    </ul>
  );
};

export default StructureNav;
