import React from "react";
import { Navigate } from "react-router-dom";
import Drawer from "./drawer/Drawer";
import "./LoggedIn.scss";
import Main from "./main/Main";

const LoggedIn = () => {
  return (
    <div className="admin-system">
      <Drawer />
      <Main />
    </div>
  );
};

export default LoggedIn;
