import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../shared/components/loading/Loading";
import IEducationPost, {
  defaultEducationPost,
} from "../../../shared/interfaces/IEducationPost";
import { useAppContext } from "../../../shared/utils/Context";
import PostParagraphs from "../../shared/components/post-paragraphs/PostParagraphs";
import "../union.scss";


interface IToolbar {
  onBack: () => void;
}
const Toolbar = (props: IToolbar) => {
  return (
    <div className="section-toolbar uk-margin">
      <h5 className="title" onClick={() => props.onBack()}>
        <span data-uk-icon="icon: chevron-left; ratio: .8"></span> Back
      </h5>
    </div>
  );
};


const ViewEducationPost = observer(() => {
  const { api, store } = useAppContext();
  const { postId } = useParams();

  const navigate = useNavigate();
  const [post, setPost] = useState<IEducationPost>({
    ...defaultEducationPost,
  });

  const [paragraphs, setParagraphs] = useState([""]);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const $post: IEducationPost = { ...post, content: paragraphs };

    try {
      await api.education.create($post);

      onNavigateBack();
    } catch (error) {
      console.log("Error!");
    }
  };

  const onNavigateBack = () => {
    navigate("/a/education/");
  };

  const loadFromDB = async (id: string) => {
    try {
      setLoading(true);
      await api.education.loadById(id);
      setLoading(false);

      const $post = store.education.items.get(id);
      if ($post) {
        setPost($post);
        setParagraphs($post.content);
      } else {
        onNavigateBack();
      }
    } catch (error) {
      onNavigateBack();
    }
  };

  const loadExistingPost = useCallback(() => {
    if (!store.education.selected) {
      if (postId) loadFromDB(postId);
      else onNavigateBack();
    } else {
      setPost(store.education.selected);
      setParagraphs(store.education.selected.content);
    }
  }, [store.education.selected]);

  const loadNewPost = useCallback(() => {
    store.education.clearSelected();
    setPost({ ...defaultEducationPost });
    setParagraphs([""]);
  }, []);

  useEffect(() => {
    if (postId === "new") loadNewPost();
    else loadExistingPost();
  }, []);

  if (loading) return <Loading fullWidth />;

  return (
    <div className="admin-union-101-item uk-section">
      <div className="container uk-container uk-container-large">
        <Toolbar onBack={onNavigateBack} />
        <form
          className="uk-card uk-card-default uk-card-small uk-card-body uk-margin"
          onSubmit={onSubmit}
          autoComplete="off"
        >
          <div className="heading uk-margin">
            <label className="post-heading">
              <span data-uk-icon="icon: link; ratio: .8"></span> Title or
              Question.
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                id="post-heading"
                type="text"
                placeholder="e.g. What is a Union?"
                value={post.title}
                onChange={(e) => setPost({ ...post, title: e.target.value })}
                required
              />
            </div>
          </div>

          <PostParagraphs
            paragraphs={paragraphs}
            setParagraphs={setParagraphs}
          />

          <div className="uk-text-right">
            <button className="kit-btn kit-btn-tertiary" type="submit">
              <span data-uk-icon="icon: check; ratio: .8"></span> Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
});

export default ViewEducationPost;
