import { FormEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorAlert } from "../../../shared/alert/Alert";
import { useAppContext } from "../../../shared/utils/Context";
import { IUser, defaultUser } from "../../../shared/interfaces/IUser";
import { hideModalFromId } from "../../../shared/utils/ModalShow";
import DIALOG_NAMES from "../../dialogs/Dialogs";
import "./Login.scss";

const LoginAdmin = () => {
  const { api } = useAppContext();

  type LocationState = {
    from: {
      hash: string;
      search: string;
      pathname: string;
    };
  };

  const location = useLocation();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userNotFoundError, setUserNotFoundError] = useState(false);

  const [logInForm, setLogInForm] = useState<IUser>({ ...defaultUser });

  const onSignIn = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, password } = logInForm;
    setLoading(true);
    const $user = await api.users.signIn(email, password);
    if (!$user) {
      setUserNotFoundError(true);
      setLoading(false);
      return;
    } else {
      const loc = location.state as LocationState;
      if (!loc) {
        navigate("/a");
      } else {
        navigate(loc.from.pathname);
      }
    }
    setLoading(false);
  };
  // const { api } = useAppContext();

  // type LocationState = {
  //   from: {
  //     hash: string;
  //     search: string;
  //     pathname: string;
  //   };
  // };

  // const location = useLocation();

  // const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  // const [userNotFoundError, setUserNotFoundError] = useState(false);

  // const [logInForm, setLogInForm] = useState<IUser>(defaultUser);

  // const onSignIn = async (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   const { email, password } = logInForm;
  //   setLoading(true);
  //   const $user = await api.users.signIn(email, password);
  //   if (!$user) {
  //     setUserNotFoundError(true);
  //     setLoading(false);
  //     return;
  //   } else {
  //     const loc = location.state as LocationState;
  //     if (!loc) {
  //       navigate("/a");
  //     } else {
  //       navigate(loc.from.pathname);
  //       setUserNotFoundError(true);
  //     }
  //   }
  //   setLoading(false);
  // };

  return (
    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical log-in-page">
      <button className="uk-modal-close-default" type="button" data-uk-close />
      <div className="dialog-content uk-position-relative">
        {userNotFoundError && (
          <ErrorAlert
            msg="User account doesn't exist. Contact your administrator"
            onClose={() => setUserNotFoundError(false)}
          />
        )}
        <div className="logo">
          <img src={process.env.PUBLIC_URL + "/logo264.png"} alt="" />
        </div>
        <form className="uk-form-stacked" onSubmit={onSignIn}>
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="user-login-email">
              Email
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                id="user-login-email"
                type="email"
                placeholder="Email"
                value={logInForm.email}
                onChange={(e) =>
                  setLogInForm({ ...logInForm, email: e.target.value })
                }
                required
              />
            </div>
          </div>
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="user-login-password">
              Password
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                id="user-login-password"
                type="password"
                placeholder="Password"
                value={logInForm.password}
                onChange={(e) =>
                  setLogInForm({
                    ...logInForm,
                    password: e.target.value,
                  })
                }
                required
              />
            </div>
          </div>
          <div className="uk-margin">
            <button className="login-in-btn uk-margin-right" type="submit">
              Login
              {loading && (
                <div
                  className="uk-margin-small-left"
                  data-uk-spinner="ratio: 0.5"
                />
              )}
            </button>
            <button
              className="login-in-btn uk-margin-left uk-modal-close"
              type="button"
              // onClick={() => hideModalFromId(DIALOG_NAMES.LOG_IN_ADMIN)}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginAdmin;
