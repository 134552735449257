import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./OfficePost.scss";
import { useAppContext } from "../../../shared/utils/Context";
import IOffice, { defaultOffice } from "../../../shared/interfaces/IOffice";
import { observer } from "mobx-react-lite";

interface IToolbar {
  onBack: () => void;
}
const Toolbar = (props: IToolbar) => {
  return (
    <div className="section-toolbar uk-margin uk-width-1-2@m">
      <h5 className="title" onClick={() => props.onBack()}>
        <span data-uk-icon="icon: chevron-left; ratio: .8"></span> Back
      </h5>
    </div>
  );
};

const ViewOfficePost = observer(() => {
  const { api, store } = useAppContext();
  const { officeId } = useParams();

  const navigate = useNavigate();
  const [office, setOffice] = useState<IOffice>({
    ...defaultOffice,
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Create office
    setLoading(true);
    try {
      await api.offices.create(office);
      onNavigateBack();
    } catch (error) {
      console.log("Error!");
    }
    setLoading(false);
    navigate("/a/members/offices");
  };

  const loadPost = ($office: IOffice) => {
    setOffice($office);
  };

  const loadFromDB = async (id: string) => {
    try {
      setLoading(true);
      await api.news.loadById(id);
      setLoading(false);

      const $office = store.offices.items.get(id);
      if ($office) loadPost($office);
      else onNavigateBack();
    } catch (error) {
      onNavigateBack();
    }
  };

  const loadExistingPost = useCallback(() => {
    if (!store.offices.selected) {
      if (officeId) loadFromDB(officeId);
      else onNavigateBack();
    } else {
      loadPost(store.offices.selected);
    }
  }, [store.offices.selected]);

  const loadOfficePost = useCallback(() => { }, []);

  const onNavigateBack = () => {
    navigate("/a/members/offices/");
  };

  useEffect(() => {
    if (officeId === "new") loadOfficePost();
    else loadExistingPost();

    return () => { };
  }, []);

  return (
    <div className="uk-section">
      <div className="uk-container uk-container-large">
        <Toolbar onBack={onNavigateBack} />
        <form
          className="uk-card uk-card-default uk-card-small uk-card-body"
          autoComplete="off"
          onSubmit={onSubmit}
        >
          <div className="" data-uk-grid>
            <div className="uk-width-1-2@m">
              <label className="office-heading">Branch</label>
              <div className="uk-form-controls">
                <select
                  className="uk-select uk-form-small"
                  id="office-branch"
                  placeholder="Branch"
                  value={office.branch}
                  onChange={(e) =>
                    setOffice({ ...office, branch: e.target.value })
                  }
                >
                  <option value={""}>Select</option>
                  <option value={"Central Region"}>Central Region</option>
                  <option value={"Western Region"}>Western Region</option>
                  <option value={"Walvis Bay"}>Walvis Bay</option>
                  <option value={"Swakopmund"}>Swakopmund</option>
                  <option value={"Southern Region"}>Southern Region</option>
                  <option value={"Luderitz"}>Luderitz</option>
                  <option value={"Keetmanshoop"}>Keetmanshoop</option>
                  <option value={"Noordoewer"}>Noordoewer</option>
                  <option value={"Northern Region"}>Northern Region</option>
                  <option value={"Oshakati"}>Oshakati</option>
                  <option value={"Otjiwarongo"}>Otjiwarongo</option>
                  <option value={"Tsumeb"}>Tsumeb</option>
                  <option value={"Rundu"}>Rundu</option>
                  <option value={"Katima Mulilo"}>Katima Mulilo</option>
                  <option value={"Windhoek"}>Windhoek</option>

                </select>
              </div>
            </div>
            <div className="uk-width-1-2@m">
              <label className="office-heading">Region</label>
              <div className="uk-form-controls">
                <select
                  className="uk-select uk-form-small"
                  id="office-region"
                  placeholder="Region"
                  value={office.region}
                  onChange={(e) =>
                    setOffice({ ...office, region: e.target.value })
                  }
                >
                  <option value={""}>Select</option>
                  <option value={"central"}>Central</option>
                  <option value={"northern"}>Northern</option>
                  <option value={"southern"}>Southern</option>
                  <option value={"western"}>Western</option>
                  <option value={"north-eastern"}>North-Eastern</option>
                </select>
              </div>
            </div>
            <div className="uk-width-1-2@m">
              <label className="office-heading">Contact Person</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small"
                  id="contact_person"
                  type="text"
                  placeholder="Contact person"
                  value={office.contact_person}
                  onChange={(e) =>
                    setOffice({ ...office, contact_person: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="uk-width-1-2@m">
              <label className="office-heading">Location </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small"
                  id="location"
                  type="text"
                  placeholder="Location"
                  value={office.location}
                  onChange={(e) =>
                    setOffice({ ...office, location: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="" data-uk-grid>
            <div className="uk-width-1-2@m">
              <label className="office-heading">Telephone </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small"
                  id="tel"
                  type="tel"
                  placeholder="Tel"
                  value={office.tel}
                  onChange={(e) =>
                    setOffice({ ...office, tel: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="uk-width-1-2@m">
              <label className="office-heading">Phone </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small"
                  id="phone"
                  type="tel"
                  placeholder="Phone"
                  value={office.phone}
                  onChange={(e) =>
                    setOffice({ ...office, phone: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="uk-width-1-2@m">
              <label className="office-heading">Fax </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small"
                  id="fax"
                  type="tel"
                  placeholder="Fax"
                  value={office.fax}
                  onChange={(e) =>
                    setOffice({ ...office, fax: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="uk-width-1-2@m">
              <label className="office-heading">Email</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small"
                  id="office-mail"
                  type="email"
                  placeholder="Email"
                  value={office.mail}
                  onChange={(e) =>
                    setOffice({ ...office, mail: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="uk-text-right uk-margin">
            <button className="kit-btn kit-btn-primary" type="submit">
              Save
              {loading && <div data-uk-spinner="ratio: .6"></div>}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
});

export default ViewOfficePost;
