import React, { Fragment, useEffect } from "react";
import OfficePostItem from "./OfficePostItem";
import "./Office.scss";
import { observer } from "mobx-react-lite";
import { useAppContext } from "../../../shared/utils/Context";
import { useNavigate } from "react-router-dom";
import IOffice from "../../../shared/interfaces/IOffice";

interface IProps {
  onCreate: () => void;
}
const Toolbar = (props: IProps) => {
  return (
    <div className="toolbar uk-margin">
      <button
        className="kit-btn kit-btn-primary"
        onClick={() => props.onCreate()}
      >
        <span data-uk-icon="icon: plus-circle; ratio: .8"></span> Create new
      </button>
    </div>
  );
};

const News = observer(() => {
  const { api, store } = useAppContext();

  const navigate = useNavigate();

  const onCreate = () => {
    navigate(`./new`);
  };

  const onView = (office: IOffice) => {
    store.offices.select(office);
    navigate(`./${office.id}`);
  };

  const onDelete = async (id: string) => {
    await api.offices.delete(id);
  };

  const regions = store.offices.all.map((office) => office.region);
  const regionalOffices = (region: string) => {
    return store.offices.all.filter((office) => office.region === region);
  };

  const uniqueRegions = () => {
    return Array.from(new Set(regions));
  };

  useEffect(() => {
    api.offices.loadAll();
    return () => {};
  }, []);

  return (
    <div className="admin-offices uk-section">
      <div className="container uk-container uk-container-large">
        <Toolbar onCreate={onCreate} />
        {uniqueRegions().map((region) => (
          <div className="uk-margin" key={region}>
            <h6 className="regional-title">{region} Offices</h6>
            <div
              className="uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-grid-match"
              data-uk-grid
              data-uk-scrollspy="target: .office-item; cls: uk-animation-slide-bottom; delay: 200"
            >
              {regionalOffices(region).map((office) => (
                <div key={office.id}>
                  <OfficePostItem
                    key={office.id}
                    office={office}
                    onView={onView}
                    onDelete={onDelete}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default News;
