import { makeObservable, runInAction } from "mobx";
import { IReportedIssue } from "../interfaces/IReportedIssue";
import { ReportedIssue } from "../models/ReportedIssue";
import AppStore from "./AppStore";
import Store from "./Store";

export default class ReportedIssuesStore extends Store<
  IReportedIssue,
  ReportedIssue
> {
  
  items = new Map<string, ReportedIssue>();

  constructor(store: AppStore) {
    super(store);

    makeObservable(this, {
      items: true,
      selected: true,
    });

    this.store = store;
  }

  load(items: IReportedIssue[] = []) {
    runInAction(() => {
      items.forEach((item) => this.items.set(item.id, new ReportedIssue(item)));
    });
  }
}
