import React from "react";
import "./buttons.scss";

interface IProps {
  iconsOnly?: true;
  onView: () => void;
  onDelete: () => void;
}
const EditDeleteButtons = (props: IProps) => {
  const { iconsOnly, onView, onDelete } = props;

  if (iconsOnly)
    return (
      <div className="controls">
        <button
          className="btn-edit kit-btn kit-btn-primary uk-margin-small-right"
          onClick={() => onView()}
        >
          <span data-uk-icon="pencil"></span>
        </button>
        <button
          className="btn-delete kit-btn kit-btn-danger"
          onClick={() => onDelete()}
        >
          <span data-uk-icon="trash"></span>
        </button>
      </div>
    );

  return (
    <div className="controls">
      <button
        className="btn edit-btn uk-margin-small-right"
        onClick={() => onView()}
      >
        <span data-uk-icon="icon: pencil; ratio: .7"></span>Edit
      </button>
      <button
        className="btn delete-btn"
        onClick={() => onDelete()}
      >
        <span data-uk-icon="icon: trash; ratio: .7"></span>Delete
      </button>
    </div>
  );
};

export default EditDeleteButtons;
