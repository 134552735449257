import React, { Fragment, useState } from "react";
import { escape } from "lodash";
import { Link } from "react-router-dom";

const ContactForm = () => {
  const [loading, setLoading] = useState(false);

  const API_PATH = "https://nafau.org.na/php/nafau.php?";
  let contactForm = "";
  let _fullname = "";
  let _email = "";
  let _subject = "";
  let _message = "";
  let _nearestbranch = "";

  const postMail = (path) => {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
      }
      if (this.readyState === 4 && this.status === 500) {
      }
    };
    xhttp.open("GET", path, true);
    xhttp.send();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const recipient = "info@nafau.org.na";
    const alt_recipient = "info@nafau.org.na";
    const fullname = _fullname.value;
    const email = _email.value;
    const subject = _subject.value;
    const message = _message.value;
    const nearestbranch = _nearestbranch.value;

    const url = `fullname=${escape(fullname)}
        &email=${escape(email)}
        &subject=${escape(subject)}
        &message=${escape(message)}
        &nearestbranch = ${escape(nearestbranch)}
        &recipient=${escape(recipient)}
         &alt_recipient=${escape(alt_recipient)}`;
    setLoading(true);
    postMail(API_PATH + url);
    contactForm.reset();
    setLoading(false);
    console.log(url);
  };

  return (
    <div className="contact-form">
      <div className="form-title">
        <h4>Get in touch</h4>
      </div>
      <div>
        <form
          ref={(form) => (contactForm = form)}
          onSubmit={onSubmit}
          className="uk-form form"
          autoComplete="off"
        >
          <div>
            <label className="uk-form-label" htmlFor="report-branch">
              Select nearest NAFAU branch
            </label>
            <div className="uk-form-controls">
              <select
                className="uk-select"
                id="report-branch"
                ref={(input) => (_nearestbranch = input)}
                required
              >
                <option value="Mungunda Street, NUNW Center, Windhoek">
                  Windhoek
                </option>
                <option value="Office of SWAPO Party Reivilo Street, Gobabis ">
                  Gobabis{" "}
                </option>
                <option value="Dr Libertina Amadhila Street , Otjiwarongo">
                  Otjiwarongo
                </option>
                <option value="Nathaniel Maxwilili Street, Walvis Bay">
                  Walvis Bay
                </option>
                <option value="Rakotoka Street, Swakopmund">Swakopmund</option>
                <option value="Diaz Street, Area One, Luderitz">
                  Luderitz
                </option>
                <option value="Wurigor Building No. 9, Keetmanshoop">
                  Keetmanshoop
                </option>
                <option value="Continental Building No.1, Oshakati">
                  Oshakati
                </option>
                <option value="Presidential Street , Tsumeb">Tsumeb</option>
                <option value="Office of SWAPO Party , Rundu">Rundu</option>
                <option value="NAFAU House, Office 1, Ngweze Street, Katima Mulilo">
                  Katima Mulilo
                </option>
              </select>
            </div>
          </div>
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="contact-fullname">
              Full name
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                id="contact-fullname"
                type="text"
                placeholder="Type your full name here..."
                ref={(input) => (_fullname = input)}
                required
              />
            </div>
          </div>

          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="contact-mail">
              Email
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                id="contact-mail"
                type="email"
                placeholder="Type your email address here..."
                ref={(input) => (_email = input)}
                required
              />
            </div>
          </div>
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="contact-subject">
              Subject
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                id="contact-subject"
                type="text"
                placeholder="Subject..."
                ref={(input) => (_subject = input)}
                required
              />
            </div>
          </div>

          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="contact-message">
              Message
            </label>
            <div className="uk-form-controls">
              <textarea
                className="uk-textarea"
                id="contact-message"
                placeholder="Comments or message for us."
                rows={4}
                ref={(input) => (_message = input)}
                required
              />
            </div>
          </div>

          <div>
            <div className="uk-margin checkbox">
              <label
                className="uk-form-label"
                htmlFor="report-privacy-agreement"
              >
                <input required className="uk-checkbox" type="checkbox" /> Agree
                to the{" "}
                <Link to={"/about-us/privacy-policy"}>Privacy Policy</Link> of
                NAFAU
              </label>
            </div>
          </div>
          <div className="uk-margin">
            <div>
              <button className="uik-button" type="submit">
                Submit
                {loading && (
                  <div
                    className="uk-margin-small-left"
                    data-uk-spinner="ratio: 0.5"
                  ></div>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ContactForm;
