import { makeAutoObservable } from "mobx";
import INewsPost, { INewsCategory } from "../interfaces/INewsPost";

export class NewsPost implements INewsPost {
  id: string;
  heading: string;
  highlight: string;
  content: string[];
  thumbnailUrl: string;
  category: INewsCategory;
  date: string;
  dateInMillis: number;

  constructor(post: INewsPost) {
    makeAutoObservable(this);

    this.id = post.id;
    this.heading = post.heading;
    this.highlight = post.highlight;
    this.content = post.content;
    this.thumbnailUrl = post.thumbnailUrl;
    this.category = post.category;
    this.dateInMillis = post.dateInMillis;
    this.date = post.date;
  }

  get asJson(): INewsPost {
    return {
      id: this.id,
      heading: this.heading,
      highlight: this.highlight,
      content: this.content,
      thumbnailUrl: this.thumbnailUrl,
      category: this.category,
      date: this.date,
      dateInMillis: this.dateInMillis,
    };
  }
}
