import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../shared/utils/Context";
import ApplicationsRow, { AdminApplicationTabs } from "./ApplicationsRow";
import { IMemberApplication } from "../../../shared/interfaces/IMemberApplication";

const ApplicationsTable = observer(() => {
  const { api, store } = useAppContext();
  const navigate = useNavigate();

  const onView = (member: IMemberApplication) => {
    store.memberApplications.select(member);
    navigate(`./${member.id}`);
  };

  const onDelete = async (id: string) => {
    if (!window.confirm("Do you want to delete?")) return;
    await api.memberApplications.delete(id);
  };

  useEffect(() => {
    api.memberApplications.loadAll();
    return () => {};
  }, []);

  const [activeTab, setActiveTab] = useState<
    "pending" | "approved" | "rejected"
  >("pending");

  const onPending = () => {
    setActiveTab("pending");
  };

  const onApproved = () => {
    setActiveTab("approved");
  };

  const onRejected = () => {
    setActiveTab("rejected");
  };

  const pendings = store.memberApplications.all.filter(
    (pending) => pending.status === "Pending"
  );

  const approves = store.memberApplications.all.filter(
    (pending) => pending.status === "Approved"
  );
  const rejects = store.memberApplications.all.filter(
    (pending) => pending.status === "Rejected"
  );

  return (
    <div className="application-table uk-card uk-card-default uk-card-body uk-card-small">
      <AdminApplicationTabs
        activeTab={activeTab}
        onPending={onPending}
        onApproved={onApproved}
        onRejected={onRejected}
      />
      <table className="uk-table uk-table-small uk-table-divider">
        <thead>
          <tr>
            <th>#</th>
            <th>Full Names</th>
            <th>ID/Passport</th>
            <th>Created on</th>
            <th>Company name</th>
            <th>Application Status</th>
            <th className="uk-text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          {activeTab === "pending" && (
            <>
              {pendings.length !== 0 ? (
                <>
                  {pendings.map((member, index) => (
                    <tr key={member.id}>
                      <ApplicationsRow
                        index={index}
                        member={member}
                        onView={onView}
                        onDelete={onDelete}
                      />
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={3}>No pending applications</td>
                </tr>
              )}
            </>
          )}
          {activeTab === "approved" && (
            <>
              {approves.length !== 0 ? (
                <>
                  {approves.map((member, index) => (
                    <tr key={member.id}>
                      <ApplicationsRow
                        index={index}
                        member={member}
                        onView={onView}
                        onDelete={onDelete}
                      />
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={3}>No approved applications</td>
                </tr>
              )}
            </>
          )}
          {activeTab === "rejected" && (
            <>
              {rejects.length !== 0 ? (
                <>
                  {rejects.map((member, index) => (
                    <tr key={member.id}>
                      <ApplicationsRow
                        index={index}
                        member={member}
                        onView={onView}
                        onDelete={onDelete}
                      />
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={3}>No rejected applications</td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
});

export default ApplicationsTable;
