import React, { useEffect } from "react";
import "./Loader.scss";

interface LoadingProps {
  fullWidth?: boolean;
}
const Loading = (props: LoadingProps) => {
  return (
    <div className={props.fullWidth ? "loader full-width-loader" : "loader"}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export const LoadingEllipsis = (props: LoadingProps) => {
  return (
    <div className={props.fullWidth ? "loader full-width-loader" : "loader"}>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loading;


const addLoaded = () => {
  document.body.classList.add('loaded');
};

export const Preloader = () => {

  useEffect(() => {
    window.addEventListener('load', addLoaded);

    return (() => {
      window.removeEventListener('load', addLoaded)
    });
  }, []);

  return (
    <div className="preloader">
      <div className='sk-folding-cube'>
        <div className='sk-cube sk-cube-1'></div>
        <div className='sk-cube sk-cube-2'></div>
        <div className='sk-cube sk-cube-4'></div>
        <div className='sk-cube sk-cube-3'></div>
      </div>
    </div>
  );
};

