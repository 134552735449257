import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../shared/components/loading/Loading";
import {
  defaultReportedIssue,
  IReportedIssue,
} from "../../../shared/interfaces/IReportedIssue";
import { useAppContext } from "../../../shared/utils/Context";
import Attachment from "./Attachment";
import "./ViewReportedIssue.scss";

interface IToolbar {
  onBack: () => void;
}
const Toolbar = (props: IToolbar) => {
  return (
    <div className="section-toolbar uk-margin">
      <h5 className="title" onClick={() => props.onBack()}>
        <span data-uk-icon="icon: chevron-left; ratio: .8"></span> Back
      </h5>
    </div>
  );
};

const ViewReportedIssue = observer(() => {
  const { api, store } = useAppContext();
  const { issueId } = useParams();

  const navigate = useNavigate();
  const [issue, setIssue] = useState<IReportedIssue>({
    ...defaultReportedIssue,
  });
  const [loading, setLoading] = useState(false);

  const onNavigateBack = () => {
    navigate("/a/reported-issues/");
  };

  const loadFromDB = async (id: string) => {
    setLoading(true);
    await api.reportedIssues.loadById(id);
    setLoading(false);

    const $issue = store.reportedIssues.items.get(id);

    if ($issue) setIssue($issue);
    else onNavigateBack();
  };

  useEffect(() => {
    if (!store.reportedIssues.selected) {
      if (issueId) loadFromDB(issueId);
      else onNavigateBack();
    } else setIssue(store.reportedIssues.selected);
  }, [store.reportedIssues.selected]);

  if (loading) return <Loading fullWidth />;

  return (
    <div className="admin-view-reported-issue uk-section">
      <div className="container uk-container uk-container-large">
        <Toolbar onBack={onNavigateBack} />

        <div className="issue-content uk-card uk-card-default uk-card-small uk-card-body">
          <div className="uk-flex uk-flex-between uk-margin">
            <p className="from">
              {issue.reporter} | {issue.category}
            </p>
            <p className="date">Reported on {issue.reportedOn}</p>
          </div>

          <h5 className="title">{issue.subject}</h5>
          <p className="message">{issue.message}</p>
          <h5 className="title">{issue.subject}</h5>
          <p className="message">Company : {issue.companyName}</p>
          <p className="message">Reporter Cellphone :{issue.cellphone}</p>
          <p className="message"> Nearest Branch :{issue.nearestBranch}</p>
        </div>
      </div>
    </div>
  );
});

export default ViewReportedIssue;
