import React from "react";
import { IMemberApplication } from "../../../../shared/interfaces/IMemberApplication";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";

const image = process.env.PUBLIC_URL + "/logo512.png";

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

const getBase64ImageFromURL = async (url: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0);

      const dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };
    img.onerror = (error) => {
      reject(error);
    };

    img.src = url;
  });
};

const FormPDFGen = async (member: IMemberApplication) => {
  const formName = `${
    member.firstName + " " + member.surname
  } Membership Application Document`;

  var docDefinition = {
    content: [
      {
        columns: [
          {
            image: await getBase64ImageFromURL(`${image}`),
            fit: [100, 100],
            width: "auto",
            height: "auto",
          },
          {
            type: "none",
            ol: [
              {
                text: "Namibian Food and Allied Workers Union (NAFAU)",
                bold: true,
              },
              { text: "Head Office", bold: true },
              "P.O. Box 1553, Windhoek",
              "Tel: +264 61 218 213 | Fax: +264 61 263 714",
              "Matias Libereki House | NUNW Center | Mungunda Street",
              "Katutura | Windhoek | Namibia",
              "Website: www.nafau.org.na | Email: info@nafau.org.na",
            ],
          },
        ],
        style: "header",
      },

      { text: "MEMBERSHIP APPLICATION FORM", style: "heading" },
      { text: "MEMBER’S PERSONAL DETAILS", style: "heading" },
      {
        alignment: "justify",
        columns: [
          {
            text: [
              "Surname : ",
              { text: `${member.surname}`, style: "italic" },
            ],
            style: "text",
          },
          {
            text: [
              "First Name : ",
              { text: `${member.firstName}`, style: "italic" },
            ],
            style: "text",
          },
        ],
      },
      {
        alignment: "justify",
        columns: [
          {
            text: [
              "ID Number : ",
              { text: `${member.idNumber}`, style: "italic" },
            ],
            style: "text",
          },
          {
            text: [
              "Date oF Birth : ",
              { text: `${member.dateOfBirth}`, style: "italic" },
            ],
            style: "text",
          },
        ],
      },
      {
        text: ["Gender : ", { text: `${member.gender}`, style: "italic" }],
        style: "text",
      },
      { text: "", margin: [0, 3] },
      {
        alignment: "justify",
        columns: [
          {
            text: [
              "Residential Address : ",
              { text: `${member.residentialAddress}`, style: "italic" },
            ],
            style: "text",
          },
          {
            text: [
              "Postal Address : ",
              { text: `${member.postalAddress}`, style: "italic" },
            ],
            style: "text",
          },
        ],
      },
      { text: "", margin: [0, 3] },
      { text: "Contact Details", style: "text" },
      {
        columns: [
          {
            text: [
              "Tell ( Work ) : ",
              { text: `${member.workTelephone}`, style: "italic" },
            ],
            style: "text",
          },
          {
            text: [
              "Tell ( Home ) : ",
              { text: `${member.homeTelephone}`, style: "italic" },
            ],
            style: "text",
          },
        ],
      },
      {
        columns: [
          {
            text: [
              "Cell : ",
              { text: `${member.cellphoneNo}`, style: "italic" },
            ],
            style: "text",
          },
          {
            text: [
              "Email Address : ",
              { text: `${member.email}`, style: "italic" },
            ],
            style: "text",
          },
        ],
      },
      { text: "MEMBER’S EMPLOYMENT DETAILS", style: "heading" },
      {
        alignment: "justify",
        columns: [
          {
            text: [
              "Company Name : ",
              { text: `${member.companyName}`, style: "italic" },
            ],
            style: "text",
          },
          {
            text: [
              "Address of Employer : ",
              { text: `${member.companyAddress}`, style: "italic" },
            ],
            style: "text",
          },
        ],
      },
      {
        alignment: "justify",
        columns: [
          {
            text: [
              "Date of Employment : ",
              { text: `${member.dateOfEmployment}`, style: "italic" },
            ],
            style: "text",
          },
          {
            text: [
              "Nature of Employment : ",
              { text: `${member.natureOfEmployment}`, style: "italic" },
            ],
            style: "text",
          },
        ],
      },
      {
        alignment: "justify",
        columns: [
          {
            text: [
              "Occupation : ",
              { text: `${member.occupation}`, style: "italic" },
            ],
            style: "text",
          },
          {
            text: [
              "Department : ",
              { text: `${member.department}`, style: "italic" },
            ],
            style: "text",
          },
        ],
      },
      {
        alignment: "justify",
        columns: [
          {
            text: [
              "Duty Station : ",
              { text: `${member.dutyStation}`, style: "italic" },
            ],
            style: "text",
          },
          {
            text: ["Region : ", { text: `${member.region}`, style: "italic" }],
            style: "text",
          },
        ],
      },
      {
        alignment: "justify",
        columns: [
          {
            text: [
              "Basic Salary : (N$) : ",
              { text: `${member.basicSalary}`, style: "italic" ,bold: true, },
            ],
            style: "text",
          },
          {
            text: [
              "Salary No/Employment Code : ",
              { text: `${member.employmentCode}`, style: "italic" },
            ],
            style: "text",
          },
        ],
      },
      { text: "", margin: [0, 4] },
      {
        text: "STOP ORDER AUTHORIZATION FOR DEDUCTION OF MONTHLY TRADE UNION SUBSCTRIPTION",
        bold: true,
        fontSize: 10,
      },
      {
        text: "I the undersigned, not having authorized a stop-order for any other Trade  Union/having withdrawn all other such authorizations, hereby agree to the monthly deduction of 1.3% (one point three percent) from my Basic Salary/Wage, which shall be paid over to the NAMIBIAN FOOD AND ALLIED WORKERS UNION (NAFAU) in respect of my membership to the union. My deduction shall be varied to meet any change in subscription fees notified to me from time to time by NAFAU.",
        style: "text",
      },
      { text: "", margin: [0, 2] },
      {
        alignment: "justify",
        columns: [
          {
            text: [
              "Digitaly Signed By : ",
              {
                text: `${member.firstName + " " + member.surname}`,
                style: "italics",
              },
            ],
            style: "text",
          },
          {
            text: [
              "Date : ",
              { text: `${member.applicationDate}`, style: "italics" },
            ],
            style: "text",
          },
        ],
      },
      { text: "", margin: [0, 2] },
      { text: "APPROVAL INFORMATION", bold: true, color: "red" },
      { text: "", margin: [0, 1] },
      { text: "Approved By :", style: "heading" },
      {
        alignment: "justify",
        columns: [
          {
            text: [
              "Name : ",
              { text: `${member.officialName}`, style: "italics" },
            ],
            style: "text",
          },
          {
            text: [
              "Signature : ",
              { text: `${member.officialSignature}`, style: "italics" },
            ],
            style: "text",
          },
        ],
      },
      {
        alignment: "justify",
        columns: [
          {
            text: [
              "Contact Details: Tel (Work) : ",
              { text: `${member.officialTell}`, style: "italics" },
            ],
            style: "text",
          },
          {
            text: [
              "Cell : ",
              { text: `${member.officialCell}`, style: "italics" },
            ],
            style: "text",
          },
        ],
      },
      {
        alignment: "justify",
        columns: [
          {
            text: [
              "Region : ",
              { text: `${member.officialRegion}`, style: "italics" },
            ],
            style: "text",
          },
          {
            text: [
              "Branch : ",
              { text: `${member.officialBranch}`, style: "italics" },
            ],
            style: "text",
          },
        ],
      },
      { text: "", margin: [0, 2] },
      { text: "DATA ENTRY", style: "heading" },
      {
        alignment: "justify",
        columns: [
          {
            text: [
              "Name of data capture : ",
              { text: `${member.dataCaptureName}`, style: "italics" },
            ],
            style: "text",
          },
          {
            text: [
              "Date : ",
              { text: `${member.dataCaptureDate}`, style: "italics" },
            ],
            style: "text",
          },
        ],
      },
      {
        alignment: "justify",
        columns: [
          {
            text: [
              "Place : ",
              { text: `${member.dataCapturePlace}`, style: "italics" },
            ],
            style: "text",
          },
          {
            text: [
              "Signature : ",
              { text: `${member.dataCaptureSignature}`, style: "italics" },
            ],
            style: "text",
          },
        ],
      },
      { text: "", margin: [0, 2] },
    ],
    styles: {
      header: {
        fontSize: 11,
        alignment: "justify",
        margin: [10, 10],
      },
      heading: {
        fontSize: 10,
        decoration: "underline",
        bold: true,
        margin: [0, 8],
      },
      text: {
        margin: [0, 2],
        fontSize: 10,
      },
      italic: {
        fontSize: 9,
        italics: true,
      },
      italics: {
        italics: true,
        decoration: "underline",
        decorationStyle: "dotted",
        color: "blue",
        fontSize: 9,
      },
    },
  };

  const newWindow = window.open(formName);
  pdfMake.createPdf((await docDefinition) as any).open({}, newWindow);
};
export default FormPDFGen;

// pdfMake
//   .createPdf((await docDefinition) as any)
//   .download(formName)
