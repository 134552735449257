import React from "react";
import "./intro.scss";

interface IntroProps {
  data: any;
}

const Intro = (props: IntroProps) => {
  const { data } = props;

  return (
    <div
      className="intro-card overlay uk-child-width-1-1 uk-child-width-1-2@m uk-child-width-1-2@l"
      style={{ backgroundImage: `url(${data.backgroundImage})` }}
    >
      <div className="uk-card uk-card-default uk-card-body">
        <h3 className="uk-card-title">{data.title}</h3>
        <p>{data.desc}</p>
      </div>
    </div>
  );
};

interface dataProps {
  data: any;
}
const IntroSection = (props: dataProps) => {
  const { data } = props;

  return (
    <div className="intro-content">
      {data &&
        data.map((single: any, key: number) => {
          return (
            <div key={key}>
              <Intro data={single} key={key} />
            </div>
          );
        })}
    </div>
  );
};

export default IntroSection;
