import { useAppContext } from "../../shared/utils/Context";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { defaultUser } from "../../shared/interfaces/IUser";
import { observer } from "mobx-react-lite";

const NavBar = observer(() => {
  const { api, store } = useAppContext();
  const [user, setUser] = useState(defaultUser);
  const navigate = useNavigate();
  const userAbbrev = user.fullName;
  var matches = userAbbrev.match(/\b(\w)/g);
  var userAcronym = matches?.join("");

  useEffect(() => {
    if (!store.users.currentUser) return;
    setUser(store.users.currentUser.asJson);
    return () => {};
  }, [store.users.currentUser]);

  return (
    <div
      className="sticky"
      data-uk-sticky="sel-target: .uk-navbar; cls-active: uk-navbar-sticky"
    >
      <nav className="uk-navbar-container" data-uk-navbar>
        <div className="navbar-right uk-navbar-right">
          <ul className="uk-navbar-nav">
            <li className="uk-inline">
              <button className="user-profile">
                <div className="profile">
                  <span>
                    <h3 className="uk-margin-remove uk-text-bold uk-text-uppercase">
                      {userAcronym}
                    </h3>
                  </span>
                </div>
              </button>
              <div
                className="profile-dropdown-container"
                data-uk-dropdown="mode: click; pos: bottom-justify"
              >
                <ul className="profile-dropdown uk-nav uk-dropdown-nav uk-text-center">
                  <li className="uk-text-center">
                    <h3>{user.fullName}</h3>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
});
export default NavBar;
