import { Route, Routes, Navigate } from "react-router-dom";
import ExecutiveCommittee from "./about/executive-committee/ExecutiveCommittee";
import Gallery from "./about/gallery/Gallery";
import History from "./about/history/History";
import NOB from "./about/nob/NOB";
import PrivacyPolicy from "./about/privacy-policy/PrivacyPolicy";
import AimsObjectives from "./about/aim-objective/AimsObjectives";
import Scope from "./about/scope/Scope";
import Home from "./home/Home";
import MemberBenefits from "./members/benefits/MemberBenefits";
import ApplyMembership from "./members/apply-membership/ApplyMembership";
import Offices from "./members/offices/Offices";
import ReportAProblem from "./members/report-a-problem/ReportAProblem";

import Education from "./education/Education";
import Structure from "./governance/structure/Structure";
import NewsHighlights from "./news/news-highlight/NewsHighlights";
import ReadNews from "./news/read-news/ReadNews";
import Disclaimer from "./about/disclaimer/Disclaimer";

const WebRouting = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />

      {/* About Us */}
      <Route path="about-us/gallery" element={<Gallery />} />
      <Route path="about-us/history" element={<History />} />
      <Route path="about-us/scope" element={<Scope />} />
      <Route path="about-us/scope" element={<Scope />} />
      <Route path="about-us/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="about-us/aim-objective" element={<AimsObjectives />} />
      <Route path="about-us/disclaimer" element={<Disclaimer />} />

      {/* Governance */}
      <Route path="governance/structure" element={<Structure />} />
      <Route path="governance/NOB" element={<NOB />} />
      <Route
        path="governance/executive-committee"
        element={<ExecutiveCommittee />}
      />

      {/* Members */}
      <Route path="members/offices" element={<Offices />} />
      <Route path="members/benefits" element={<MemberBenefits />} />
      <Route path="members/report-a-problem" element={<ReportAProblem />} />
      <Route path="members/apply" element={<ApplyMembership />} />

      {/* News */}
      <Route path="news" element={<NewsHighlights />} />
      <Route path="news/:postId" element={<ReadNews />} />

      {/* Education */}
      <Route path="education" element={<Education />} />

      <Route path="*" element={<Navigate to={"/"} />} />
    </Routes>
  );
};

export default WebRouting;
