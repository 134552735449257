import axios from "axios";
import { stringify } from "querystring";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";

export default class MessageApi {
  API_URI: string;

  constructor(private api: AppApi, private store: AppStore, URI: string) {
    this.API_URI = URI;
  }

  // Send
  async sendVerificationCode(mobile: string, code: string) {
    const body = {
      mobile,
      code,
    };

    const uri = `${this.API_URI}${stringify(body)}`;

    axios
      .get(uri)
      .then((response) => {
        //   Snackbar
        console.log("Successful request => ", response.data);
      })
      .catch((error) => {
        //   Snackbar
        console.log(error);
        alert(error);
      });
  }
}
