import showModalFromId from "../../shared/utils/ModalShow";
import DIALOG_NAMES from "../dialogs/Dialogs";
import { Link, useNavigate } from "react-router-dom";
import "./Footer.scss";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="footer">
      <div className="page-footer">
        <div className="container uk-container">
          <div
            className="uk-grid-collapse uk-child-width-expand@s"
            data-uk-grid
          >
            <div>
              <div className="about-company uk-padding">
                <div>
                  <a href="/home">
                    <img
                      className="brand"
                      src={process.env.PUBLIC_URL + "/logo264.png"}
                      alt=""
                    />
                  </a>
                </div>
                <div>
                  <p>Namibian Allied {"&"} Food Workers Union (NAFAU)</p>
                </div>
                <div className="uk-flex">
                  <a
                    className="media-icon"
                    href="https://www.linkedin.com/company/nafau/"
                    target={"_blank"}
                  >
                    <span data-uk-icon="linkedin"></span>{" "}
                  </a>
                  <a
                    className="media-icon"
                    href="https://www.facebook.com/Namibian-Food-and-Allied-Workers-Union-100854262692920/"
                    target={"_blank"}
                  >
                    <span data-uk-icon="facebook"></span>{" "}
                  </a>
                </div>
              </div>
            </div>

            <div>
              <div className="contact-us uk-padding">
                <h5 className="title">Contact Us</h5>

                <a className="phone" href="tel:+264 61 218 213">
                  <span data-uk-icon="icon: receiver"></span>
                  +264 61 218 213
                </a>
                <a className="phone">
                  <span data-uk-icon="icon: print"></span>
                  +264 61 263 714
                </a>
                <a className="mail" href="mail:  info@nafau.org.com">
                  <span data-uk-icon="icon: mail"></span>
                  info@nafau.org.na
                </a>

                <p>Do you have questions or comments for us?</p>
                <div className="uk-margin uk-flex">
                  <button
                    onClick={() => navigate("/members/offices")}
                    className="btn"
                  >
                    {" "}
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div className="quick-links uk-padding">
                <h5 className="title">Quick Links</h5>
                <div className="links">
                  <button
                    onClick={() => showModalFromId(DIALOG_NAMES.LOG_IN_ADMIN)}
                    className="quick-link btn"
                  >
                    Log in
                  </button>
                  <button
                    onClick={() => navigate("/members/apply")}
                    className="quick-link btn"
                  >
                    Become a Member
                  </button>
                  <button
                    onClick={() => navigate("/members/report-a-problem")}
                    className="quick-link btn"
                  >
                    Report an Issue
                  </button>
                  <button
                    onClick={() => navigate("/news#vacancies")}
                    className="quick-link btn"
                  >
                    Vacancies
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright-footer">
        <div className="container uk-container">
          <div className="left">
            <p className="copyright">
              {new Date().getFullYear()} &#169; Copyright
            </p>
          </div>
          <div className="right">
            <Link className="uk-margin-right" to={"about-us/disclaimer"}>
              Disclaimer
            </Link>
            <Link to={"/about-us/privacy-policy"}>Privacy Policy</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
