import MembershipOnlineApplication from "./online-application/MembershipOnlineApplication";
import "./ApplyMembership.scss";
import OfflineApplication from "./OfflineApplication";

const ApplyMembership = () => {
  return (
    <div className="apply-for-membership">
      <div className="bg"></div>

      <div className="uk-section">
        <div className="container uk-container">
          <MembershipOnlineApplication />
          <OfflineApplication />
        </div>
      </div>
    </div>
  );
};

export default ApplyMembership;
