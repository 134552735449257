import AppStore from "../stores/AppStore";
import NewsApi from "./NewsApi";
import ReportedIssuesApi from "./ReportedIssuesApi";
import EducationApi from "./Union101Api";
import MemberApplicationsAPi from "./MemberApplicationsAPi";
import UserApi from "./UserApi";
import MailApi from "./MailApi";
import MessageApi from "./MessageApi";
import OfficesApi from "./OfficeAPi";

export default class AppApi {
  private mailUri = "https://nafau.org.na/php/notify.php?";
  private messageUri = "https://lots.unicomms.app/mail_php/twilio/message.php?";

  // api endpoints
  news: NewsApi;
  education: EducationApi;
  reportedIssues: ReportedIssuesApi;
  memberApplications: MemberApplicationsAPi;
  users: UserApi;
  mail: MailApi;
  messageApi: MessageApi;
  offices: OfficesApi;

  constructor(private store: AppStore) {
    this.news = new NewsApi(this.store);
    this.education = new EducationApi(this.store);
    this.reportedIssues = new ReportedIssuesApi(this.store);
    this.memberApplications = new MemberApplicationsAPi(this.store);
    this.users = new UserApi(this.store);
    this.mail = new MailApi(this, store, this.mailUri);
    this.messageApi = new MessageApi(this, store, this.messageUri);
    this.offices = new OfficesApi(this.store);
  }
}
