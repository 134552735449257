import React from "react";

interface IProps {
  heading: string;
  subheading?: string;
}

const SectionHeading = (props: IProps) => {
  return (
    <h4 className="section-heading">
      {props.heading}
      {props.subheading && <span>{props.subheading}</span>}
    </h4>
  );
};

export default SectionHeading;
