import React from "react";
import { useNavigate } from "react-router-dom";
import INewsPost from "../../../shared/interfaces/INewsPost";
import EditDeleteButtons from "../../shared/components/edit-delete-buttons/EditDeleteButtons";
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";
interface IProps {
  post: INewsPost;
  contentHeight?: number;
  onView: (issue: INewsPost) => void;
  onDelete: (id: string) => Promise<void>;
}

const NewsPostItem = (props: IProps) => {
  const { post, contentHeight = 2 } = props;
  const {
    id,
    thumbnailUrl,
    date,
    dateInMillis,
    category,
    heading,
    highlight,
    content,
  } = post;

  const navigate = useNavigate();

  const contentBrief = content?.join(". ");

  const cssContentHeight = {
    height: contentHeight * 19.5 + "px",
  };

  const onView = () => {
    navigate("/a/news/post");
    props.onView(post);
  };

  const onDelete = () => {
    if (!window.confirm("Do you want to delete?")) return;
    props.onDelete(id);
  };

  return (
    <div>
      <div className="card-post uk-card uk-card-default">
        <div className="uk-card-media-top thumbnail">
          <img src={thumbnailUrl} alt="" />
          <p className="date">{date}</p>
        </div>
        <div className="uk-card-body">
          <div className="uk-card-badge uk-label">{category}</div>
          <h4 className="uk-card-title">{heading}</h4>
          <p>{highlight.substring(0, 30)}...</p>
          <EditDeleteButtons onView={onView} onDelete={onDelete} />
        </div>
        <div className="uk-card-footer">
          <div className="uk-flex">
            <FacebookShareButton
              url={`http://nafau.org.na/news/${id}`}
              quote={highlight}
              hashtag={category}
            >
              <FacebookIcon lightingColor={"white"} round={true} size={30} />
            </FacebookShareButton>
            <LinkedinShareButton
              url={`http://nafau.org.na/news/${id}`}
              summary={highlight}
              source={category}
            >
              <LinkedinIcon lightingColor={"white"} round={true} size={30} />
            </LinkedinShareButton>
            <WhatsappShareButton
              url={`http://nafau.org.na/news/${id}`}
              title={heading}
              separator={category}
            >
              <WhatsappIcon lightingColor={"white"} round={true} size={30} />
            </WhatsappShareButton>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewsPostItem;
