import React from "react";
import { ReportedIssue } from "../../../shared/models/ReportedIssue";
import { IReportedIssue } from "../../../shared/interfaces/IReportedIssue";

interface IProps {
  index: number;
  issue: ReportedIssue;
  onView: (issue: IReportedIssue) => void;
  onDelete: (id: string) => void;
}

const IssueRow = (props: IProps) => {
  const { index, issue } = props;

  const onView = () => {
    props.onView(issue.asJson);
  };

  const onDelete = () => {
    props.onDelete(issue.id);
  };

  return (
    <>
      <td>{index + 1}</td>
      <td>{issue.reportedOn}</td>
      <td>{issue.reporter}</td>
      <td>{issue.category}</td>
      <td>{issue.subject}</td>
      <td className="controls uk-text-right">
        <button
          className="kit-btn-text kit-btn-primary uk-margin-small-right"
          onClick={onView}
        >
          <span data-uk-icon="file-edit"></span> View
        </button>
        <button className="kit-btn-text kit-btn-danger" onClick={onDelete}>
          <span data-uk-icon="trash"></span> Remove
        </button>
      </td>
    </>
  );
};

export default IssueRow;
