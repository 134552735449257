import React from "react";
import IOffice from "../../../shared/interfaces/IOffice";

const colorCode = (region: string) => {
  switch (region) {
    case "central":
      return "primary";
    case "western":
      return "secondary";
    case "southern":
      return "davy-grey ";
    case "northern":
      return "grey";
    case "north-eastern":
      return "muted";
    default:
      return "";
  }
};

interface IProps {
  office: IOffice;
}

const OfficeItem = (props: IProps) => {
  const { office } = props;
  const {
    id,
    branch,
    contact_person,
    location,
    tel,
    fax,
    phone,
    mail,
    region,
  } = office;

  const colorCss = colorCode(region);

  return (
    <div
      className={`office-item uk-card uk-card-default uk-card-small ${colorCss}`}
    >
      <div className="location uk-card-body">
        <span className="icon" data-uk-icon="location"></span>
        {branch}
      </div>

      <div className="contact-person uk-card-body">
        <p className="label">
          <span
            className="icon"
            data-uk-icon="icon: location; ratio: .7"
          ></span>
          {location}
        </p>
      </div>

      <div className="contact-person uk-card-body">
        <p className="label">
          <span className="icon" data-uk-icon="icon: user; ratio: .7"></span>
          {contact_person}
        </p>
      </div>

      <div className="mail uk-card-body">
        <a className="label" href={`mailto:${mail}`}>
          <span className="icon" data-uk-icon="icon: mail; ratio: .7"></span>
          {mail}
        </a>
      </div>

      <div className="phone uk-card-body">
        <a className="label" href={`tel:${tel}`}>
          <span
            className="icon"
            data-uk-icon="icon: receiver; ratio: .7"
          ></span>
          {tel}
        </a>
      </div>

      <div className="tel uk-card-body">
        <a className="label" href={`tel:${phone}`}>
          <span
            className="icon"
            data-uk-icon="icon: receiver; ratio: .7"
          ></span>
          {phone}
        </a>
      </div>

      <div className="fax uk-card-body">
        <span className="label">
          <span className="icon" data-uk-icon="icon: print; ratio: .7"></span>
          {fax}
        </span>
      </div>
    </div>
  );
};

export default OfficeItem;
