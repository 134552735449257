import { observer } from "mobx-react-lite";
import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../shared/components/loading/Loading";
import {
  defaultMembers,
  IMemberApplication,
} from "../../../shared/interfaces/IMemberApplication";
import { useAppContext } from "../../../shared/utils/Context";
import FormPDFGen from "./applicationPDF/FormPDFGen";
import "./Applications.scss";

interface IToolbar {
  onBack: () => void;
  exportPDF: () => void;
}
const Toolbar = (props: IToolbar) => {
  const { onBack, exportPDF } = props;
  return (
    <div className="section-toolbar uk-margin">
      <div>
        <h5 className="title" onClick={onBack}>
          <span data-uk-icon="icon: chevron-left; ratio: .8"></span> Back
        </h5>
      </div>
      <div>
        <button className="kit-btn kit-btn-primary" onClick={exportPDF}>
          <span data-uk-icon="icon: file-pdf; ratio: .8"></span>Export PDF
        </button>
      </div>
    </div>
  );
};

const ViewApplication = observer(() => {
  const { api, store } = useAppContext();
  const { memberId } = useParams();

  const navigate = useNavigate();
  const [member, setMember] = useState<IMemberApplication>({
    ...defaultMembers,
  });
  const [loading, setLoading] = useState(false);
  const goodMsg = "Your membership application has been approved";
  const badMsg = "Your membership application has been rejected";
  const subject = "NAFAU MEMBERSHIP APPLICATION";

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      {
        member.status === "Approved" &&
          (await api.mail.emailNotification(member.email, subject, goodMsg));
      }
      {
        member.status === "Rejected" &&
          (await api.mail.emailNotification(member.email, subject, badMsg));
      }
      await api.memberApplications.create(member);
      alert("Updated!");
    } catch (error) {
      console.log("Error! ");
    }
    setLoading(false);
    navigate("/a/members/applications");
  };

  const onNavigateBack = () => {
    navigate("/a/members/applications");
  };

  const loadFromDB = async (id: string) => {
    setLoading(true);
    await api.memberApplications.loadById(id);
    setLoading(false);

    const $member = store.memberApplications.items.get(id);

    if ($member) setMember($member);
    else onNavigateBack();
  };

  const ExportToPDF = async () => {
    await FormPDFGen(member);
  };

  useEffect(() => {
    if (!store.memberApplications.selected) {
      if (memberId) loadFromDB(memberId);
      else onNavigateBack();
    } else setMember(store.memberApplications.selected);
  }, [store.memberApplications.selected]);

  // if (loading) return <Loading fullWidth />;

  return (
    <div className="admin-view-application uk-section">
      <div className="uk-container">
        <div>
          <Toolbar onBack={onNavigateBack} exportPDF={ExportToPDF} />
        </div>

        <div className="uk-card uk-card-default">
          <form
            className="application-form"
            autoComplete="off"
            onSubmit={onSubmit}
          >
            <div className="uk-grid uk-child-width-1-1" data-uk-grid>
              <div className="uk-width-1-2@s input-margin">
                <label className="uk-form-label" htmlFor="member-surname">
                  Surname
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input uk-form-small"
                    id="member-surname"
                    type="text"
                    placeholder="Member's surname"
                    value={member.surname}
                    onChange={(e) =>
                      setMember({ ...member, surname: e.target.value })
                    }
                    required
                  />
                </div>
              </div>

              <div className="uk-width-1-2@s input-margin">
                <label className="uk-form-label" htmlFor="member-firstname">
                  First name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input uk-form-small"
                    id="member-firstname"
                    type="text"
                    placeholder="Member's first name."
                    value={member.firstName}
                    onChange={(e) =>
                      setMember({ ...member, firstName: e.target.value })
                    }
                    required
                  />
                </div>
              </div>

              <div className="uk-width-1-3@s input-margin">
                <label className="uk-form-label" htmlFor="member-id-no">
                  ID Number
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input uk-form-small"
                    id="member-id-no"
                    type="text"
                    placeholder="Member's ID number"
                    value={member.idNumber}
                    onChange={(e) =>
                      setMember({ ...member, idNumber: e.target.value })
                    }
                    required
                  />
                </div>
              </div>

              <div className="uk-width-1-3@s input-margin">
                <label className="uk-form-label" htmlFor="member-dob">
                  Date of Birth
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input uk-form-small"
                    id="member-dob"
                    type="date"
                    placeholder="Member's date of birth."
                    value={member.dateOfBirth}
                    onChange={(e) =>
                      setMember({ ...member, dateOfBirth: e.target.value })
                    }
                    required
                  />
                </div>
              </div>

              <div className="uk-width-1-3@s input-margin">
                <label className="uk-form-label" htmlFor="member-gender">
                  Gender
                </label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select uk-form-small"
                    id="member-gender"
                    value={member.gender}
                    onChange={(e) =>
                      setMember({
                        ...member,
                        gender: e.target.value as "Male" | "Female",
                      })
                    }
                    required
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </div>
              <div className="uk-width-1-2@s input-margin">
                <label className="uk-form-label" htmlFor="member-id-no">
                  Telephone (Home)
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input uk-form-small"
                    id="member-id-no"
                    type="text"
                    placeholder="Home telephone number"
                    value={member.homeTelephone}
                    onChange={(e) =>
                      setMember({
                        ...member,
                        homeTelephone: Number(e.target.value),
                      })
                    }
                    required
                  />
                </div>
              </div>

              <div className="uk-width-1-2@s input-margin">
                <label className="uk-form-label" htmlFor="member-id-no">
                  Telephone (Work)
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input uk-form-small"
                    id="member-id-no"
                    type="text"
                    placeholder="Work telephone number"
                    value={member.workTelephone}
                    onChange={(e) =>
                      setMember({
                        ...member,
                        workTelephone: Number(e.target.value),
                      })
                    }
                    required
                  />
                </div>
              </div>
              <div className="uk-width-1-2@s input-margin">
                <label className="uk-form-label" htmlFor="member-id-no">
                  Cellphone
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input uk-form-small"
                    id="member-id-no"
                    type="text"
                    placeholder="Cellhone number"
                    value={member.cellphoneNo}
                    onChange={(e) =>
                      setMember({
                        ...member,
                        cellphoneNo: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>

              <div className="uk-width-1-2@s input-margin">
                <label className="uk-form-label" htmlFor="member-id-no">
                  Email Address
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input uk-form-small"
                    id="member-id-no"
                    type="email"
                    placeholder="Email Address"
                    value={member.email}
                    onChange={(e) =>
                      setMember({ ...member, email: e.target.value })
                    }
                    required
                  />
                </div>
              </div>
              <div className="uk-width-1-2@s input-margin">
                <label
                  className="uk-form-label"
                  htmlFor="member-residential-addr"
                >
                  Residential Address
                </label>
                <div className="uk-form-controls">
                  <textarea
                    className="uk-textarea"
                    id="member-residential-addr"
                    rows={3}
                    placeholder="Member's residential address"
                    value={member.residentialAddress}
                    onChange={(e) =>
                      setMember({
                        ...member,
                        residentialAddress: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>

              <div className="uk-width-1-2@s input-margin">
                <label className="uk-form-label" htmlFor="member-postal-addr">
                  Postal Address
                </label>
                <div className="uk-form-controls">
                  <textarea
                    className="uk-textarea"
                    id="member-postal-addr"
                    rows={3}
                    placeholder="Member's Postal address"
                    value={member.postalAddress}
                    onChange={(e) =>
                      setMember({ ...member, postalAddress: e.target.value })
                    }
                    required
                  />
                </div>
              </div>
              <div className="uk-width-1-2@s input-margin">
                <label className="uk-form-label" htmlFor="member-company-name">
                  Company Name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input uk-form-small"
                    id="member-company-name"
                    type="text"
                    placeholder="Member's company name"
                    value={member.companyName}
                    onChange={(e) =>
                      setMember({ ...member, companyName: e.target.value })
                    }
                    required
                  />
                </div>
              </div>

              <div className="uk-width-1-2@s input-margin">
                <label className="uk-form-label" htmlFor="member-company-addr">
                  Company Address
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input uk-form-small"
                    id="member-company-addr"
                    type="text"
                    placeholder="Company address"
                    value={member.companyAddress}
                    onChange={(e) =>
                      setMember({ ...member, companyAddress: e.target.value })
                    }
                    required
                  />
                </div>
              </div>

              <div className="uk-width-1-2@s input-margin">
                <label className="uk-form-label" htmlFor="member-dob">
                  Date of Employment
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input uk-form-small"
                    id="member-dob"
                    type="date"
                    placeholder="Member's date of employment."
                    value={member.dateOfEmployment}
                    onChange={(e) =>
                      setMember({ ...member, dateOfEmployment: e.target.value })
                    }
                    required
                  />
                </div>
              </div>

              <div className="uk-width-1-2@s input-margin">
                <label className="uk-form-label" htmlFor="member-gender">
                  Nature of employment
                </label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select uk-form-small"
                    id="member-gender"
                    value={member.natureOfEmployment}
                    onChange={(e) =>
                      setMember({
                        ...member,
                        natureOfEmployment: e.target.value as
                          | "Permanent"
                          | "Contract"
                          | "Tempolary",
                      })
                    }
                    required
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    <option value="Permanent">Permanent</option>
                    <option value="Contract">Contract</option>
                    <option value="Tempolary">Tempolary</option>
                  </select>
                </div>
              </div>

              <div className="uk-width-1-2@s input-margin">
                <label className="uk-form-label" htmlFor="member-occupation">
                  Occupation
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input uk-form-small"
                    id="member-occupation"
                    type="text"
                    placeholder="Occupation"
                    value={member.occupation}
                    onChange={(e) =>
                      setMember({ ...member, occupation: e.target.value })
                    }
                    required
                  />
                </div>
              </div>

              <div className="uk-width-1-2@s input-margin">
                <label className="uk-form-label" htmlFor="member-department">
                  Department
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input uk-form-small"
                    id="member-department"
                    type="text"
                    placeholder="Department"
                    value={member.department}
                    onChange={(e) =>
                      setMember({ ...member, department: e.target.value })
                    }
                    required
                  />
                </div>
              </div>

              <div className="uk-width-1-2@s input-margin">
                <label className="uk-form-label" htmlFor="member-duty-station">
                  Duty Station
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input uk-form-small"
                    id="member-duty-station"
                    type="text"
                    placeholder="Duty station"
                    value={member.dutyStation}
                    onChange={(e) =>
                      setMember({ ...member, dutyStation: e.target.value })
                    }
                    required
                  />
                </div>
              </div>

              <div className="uk-width-1-2@s input-margin">
                <label className="uk-form-label" htmlFor="member-region">
                  Region
                </label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select uk-form-small"
                    id="member-region"
                    value={member.region}
                    onChange={(e) =>
                      setMember({ ...member, region: e.target.value })
                    }
                    required
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    <option value="Khomas">Khomas</option>
                    <option value="Erongo">Erongo</option>
                    <option value="Oshana">Oshana</option>
                    <option value="Hardap">Hardap</option>
                    <option value="Kunene">Kunene</option>
                    <option value="Otjozondjupa">Otjozondjupa</option>
                    <option value="Omusati">Omusati</option>
                    <option value="Oshikoto">Oshikoto</option>
                    <option value="Kavango West">Kavango West</option>
                    <option value="Kavango East">Kavango East</option>
                    <option value="!Kharas">!Kharas</option>
                    <option value="Ohangwena">Ohangwena</option>
                    <option value="Zambezi">Zambezi</option>
                    <option value="Omaheke">Omaheke</option>
                  </select>
                </div>
              </div>

              <div className="uk-width-1-2@s input-margin">
                <label className="uk-form-label" htmlFor="member-basic-salary">
                  Basic Salary
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input uk-form-small"
                    id="member-basic-salary"
                    type="text"
                    placeholder="Basic salary"
                    value={member.basicSalary}
                    onChange={(e) =>
                      setMember({
                        ...member,
                        basicSalary: Number(e.target.value),
                      })
                    }
                    required
                  />
                </div>
              </div>

              <div className="uk-width-1-2@s input-margin">
                <label className="uk-form-label" htmlFor="member-emp-code">
                  Salary No/Employment Code:
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input uk-form-small"
                    id="member-emp-code"
                    type="text"
                    placeholder="Employment code."
                    value={member.employmentCode}
                    onChange={(e) =>
                      setMember({
                        ...member,
                        employmentCode: Number(e.target.value),
                      })
                    }
                    required
                  />
                </div>
              </div>
            </div>
            <ul data-uk-accordion>
              <li>
                <a className="uk-accordion-title official-use" href="#">
                  APPROVAL INFORMATION
                </a>
                <div className="uk-accordion-content">
                  <div className="uk-grid uk-child-width-1-1" data-uk-grid>
                    <p className="uk-margin-small">Approved By :</p>
                    <div className="uk-width-1-2@s input-margin">
                      <label
                        className="uk-form-label"
                        htmlFor="data-capture-place"
                      >
                        Application Status
                      </label>
                      <div className="uk-form-controls">
                        <select
                          className="uk-select uk-form-small"
                          value={member.status}
                          onChange={(e) =>
                            setMember({
                              ...member,
                              status: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="Pending">Pending</option>
                          <option value="Approved">Approved</option>
                          <option value="Rejected">Rejected</option>
                        </select>
                      </div>
                    </div>
                    <div className="uk-width-1-2@s input-margin">
                      <label className="uk-form-label" htmlFor="official-name">
                        Full Name
                      </label>
                      <div className="uk-form-controls">
                        <input
                          className="uk-input uk-form-small"
                          id="official-name"
                          type="text"
                          value={member.officialName}
                          onChange={(e) =>
                            setMember({
                              ...member,
                              officialName: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="uk-width-1-2@s input-margin">
                      <label
                        className="uk-form-label"
                        htmlFor="official-signature"
                      >
                        Signature
                      </label>
                      <div className="uk-form-controls">
                        <input
                          className="uk-input uk-form-small"
                          id="official-signature"
                          type="text"
                          value={member.officialSignature}
                          onChange={(e) =>
                            setMember({
                              ...member,
                              officialSignature: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="uk-width-1-2@s input-margin">
                      <label className="uk-form-label" htmlFor="official-tell">
                        Contact Details: Tell
                      </label>
                      <div className="uk-form-controls">
                        <input
                          className="uk-input uk-form-small"
                          id="official-tell"
                          type="number"
                          value={member.officialTell}
                          onChange={(e) =>
                            setMember({
                              ...member,
                              officialTell: Number(e.target.value),
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="uk-width-1-2@s input-margin">
                      <label className="uk-form-label" htmlFor="official-cell">
                        Cell
                      </label>
                      <div className="uk-form-controls">
                        <input
                          className="uk-input uk-form-small"
                          id="official-tell"
                          type="number"
                          value={member.officialCell}
                          onChange={(e) =>
                            setMember({
                              ...member,
                              officialCell: Number(e.target.value),
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="uk-width-1-2@s input-margin">
                      <label
                        className="uk-form-label"
                        htmlFor="official-region"
                      >
                        Region
                      </label>
                      <select
                        className="uk-select uk-form-small"
                        id="official-region"
                        value={member.officialRegion}
                        onChange={(e) =>
                          setMember({
                            ...member,
                            officialRegion: e.target.value,
                          })
                        }
                      >
                        <option value="" disabled>
                          Select...
                        </option>
                        <option value="Khomas">Khomas</option>
                        <option value="Erongo">Erongo</option>
                        <option value="Oshana">Oshana</option>
                        <option value="Hardap">Hardap</option>
                        <option value="Kunene">Kunene</option>
                        <option value="Otjozondjupa">Otjozondjupa</option>
                        <option value="Omusati">Omusati</option>
                        <option value="Oshikoto">Oshikoto</option>
                        <option value="Kavango West">Kavango West</option>
                        <option value="Kavango East">Kavango East</option>
                        <option value="!Kharas">!Kharas</option>
                        <option value="Ohangwena">Ohangwena</option>
                        <option value="Zambezi">Zambezi</option>
                        <option value="Omaheke">Omaheke</option>
                      </select>
                    </div>

                    <div className="uk-width-1-2@s input-margin">
                      <label
                        className="uk-form-label"
                        htmlFor="official-branch"
                      >
                        Branch
                      </label>
                      <div className="uk-form-controls">
                        <input
                          className="uk-input uk-form-small"
                          id="official-branch"
                          type="text"
                          value={member.officialBranch}
                          onChange={(e) =>
                            setMember({
                              ...member,
                              officialBranch: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <label
                      className="uk-form-label data-capture"
                      htmlFor="company-data-entry"
                    >
                      DATA CAPTURE ENTRY
                    </label>
                    <div className="uk-width-1-2@s input-margin">
                      <label
                        className="uk-form-label"
                        htmlFor="data-capture-name"
                      >
                        Name of Data Capture
                      </label>
                      <div className="uk-form-controls">
                        <input
                          className="uk-input uk-form-small"
                          id="data-capture-name"
                          type="text"
                          value={member.dataCaptureName}
                          onChange={(e) =>
                            setMember({
                              ...member,
                              dataCaptureName: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="uk-width-1-2@s input-margin">
                      <label
                        className="uk-form-label"
                        htmlFor="data-capture-date"
                      >
                        Date
                      </label>
                      <input
                        className="uk-input uk-form-small"
                        id="data-capture-date"
                        type="date"
                        value={member.dataCaptureDate}
                        onChange={(e) =>
                          setMember({
                            ...member,
                            dataCaptureDate: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="uk-width-1-2@s input-margin">
                      <label
                        className="uk-form-label"
                        htmlFor="data-capture-place"
                      >
                        Place
                      </label>
                      <div className="uk-form-controls">
                        <input
                          className="uk-input uk-form-small"
                          id="data-capture-place"
                          type="text"
                          value={member.dataCapturePlace}
                          onChange={(e) =>
                            setMember({
                              ...member,
                              dataCapturePlace: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="uk-width-1-2@s input-margin">
                      <label
                        className="uk-form-label"
                        htmlFor="data-capture-signature"
                      >
                        Signature
                      </label>
                      <div className="uk-form-controls">
                        <input
                          className="uk-input uk-form-small"
                          id="data-capture-signature"
                          type="text"
                          value={member.dataCaptureSignature}
                          onChange={(e) =>
                            setMember({
                              ...member,
                              dataCaptureSignature: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <div className="uk-margin">
              <button className="kit-btn kit-btn-primary" type="submit">
                Save Changes
                {loading && <div data-uk-spinner="ratio: .6"></div>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

export default ViewApplication;
