import React from "react";

const BEC = () => {
  return (
    <div className="bec section-structure">
      <h6 className="title">Branch Executive Committee (BEC)</h6>
      <hr />
      <div className="content">
        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>
                    The Branch Executive Committee (BEC) consists of
                  </p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">Branch Chairperson</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Deputy Branch Chairperson</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Branch Secretary</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Deputy Branch Secretary</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Branch Treasurer</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Deputy Branch Treasure</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Branch Secretary for Information and Publicity
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Deputy Branch Secretary for Information and Publicity
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Branch Education Coordinator</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Deputy Branch Education Coordinator
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Branch Secretary for Gender</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Deputy Branch Secretary for Gender</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    All chairperson and Secretary of each Shop Stewards Committee
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default BEC;
