import { makeObservable, runInAction, toJS } from "mobx";
import IEducationPost from "../interfaces/IEducationPost";
import { EducationPost } from "../models/Union101Post";
import AppStore from "./AppStore";
import Store from "./Store";

export default class EducationStore extends Store<
  IEducationPost,
  EducationPost
> {
  items = new Map<string, EducationPost>();

  constructor(store: AppStore) {
    super(store);

    makeObservable(this, {
      items: true,
      selected: true,
    });

    this.store = store;
  }

  load(items: IEducationPost[] = []) {
    runInAction(() => {
      items.forEach((item) => this.items.set(item.id, new EducationPost(item)));
    });
  }
}
