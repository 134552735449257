import React, { Fragment, useEffect } from "react";
import SectionHeading from "../../shared/components/section-heading/SectionHeading";
import EducationItem from "./EducationItem";
import IEducationPost from "../../shared/interfaces/IEducationPost";
import "./Education.scss";
import { observer } from "mobx-react-lite";
import { useAppContext } from "../../shared/utils/Context";
import { Link } from "react-router-dom";
import IntroSection from "../../shared/Intro/IntroSection";

const Education = observer(() => {
  const { api, store } = useAppContext();
  const labourAct = `${process.env.PUBLIC_URL}/assets/documents/Labour Act 11 of 2007.pdf`;

  useEffect(() => {
    api.education.loadAll();
    return () => {};
  }, []);

  const educate = [
    {
      id: "1",
      title: "Education",
      desc: `Learn about the union.`,
      backgroundImage: process.env.PUBLIC_URL + "/assets/gallery/Congress1.jpg",
    },
  ];

  return (
    <div className="education">
      <IntroSection data={educate} />
      <div className="education uk-section">
        <div className="container uk-container">
          <div className="uk-card uk-card-default">
            <div className="uk-padding">
              <div
                className="education-questions uk-accordion"
                data-uk-accordion
              >
                <div className="education-item uk-accordion-item uk-card uk-card-default uk-card-small uk-card-body">
                  <div className="title uk-accordion-title">Who is NAFAU?</div>
                  <div className="uk-accordion-content">
                    <p>
                      The abbreviation NAFAU stands for “Namibia Food and Allied
                      Workers Union”
                    </p>
                    <p>
                      ● NAFAU’s mandate is To organize and unite all workers
                      employed in various job categories in the food and allied
                      industries. To further group them together according to
                      the nature of work they are engaged in and assist them in
                      forming worker’s committees of their own under the
                      auspices of NAFAU.
                    </p>
                    <p>
                      ● To strive for the betterment of economic and social
                      welfare for all NAMIBIAN workers as well as justice in
                      general.
                    </p>
                  </div>
                </div>
                <div className="education-item uk-accordion-item uk-card uk-card-default uk-card-small uk-card-body">
                  <div className="title uk-accordion-title">
                    How can I become a member of NAFAU?
                  </div>
                  <div className="uk-accordion-content">
                    <p>
                      By applying for membership.{" "}
                      <Link to={"/members/apply"}>Apply now</Link>{" "}
                    </p>
                  </div>
                </div>
                <div className="education-item uk-accordion-item uk-card uk-card-default uk-card-small uk-card-body">
                  <div className="title uk-accordion-title">
                    Why should I become a Member of NAFAU?
                  </div>
                  <div className="uk-accordion-content">
                    <p>
                      Unions give workers the power to negotiate for more
                      favorable working conditions and other benefits through
                      collective bargaining.
                      <br />
                      <Link to={"/members/benefits"}>
                        Read about member benefits
                      </Link>{" "}
                    </p>
                  </div>
                </div>
                <div className="education-item uk-accordion-item uk-card uk-card-default uk-card-small uk-card-body">
                  <div className="title uk-accordion-title">
                    What if I do not fall under the scope of NAFAU?
                  </div>
                  <div className="uk-accordion-content">
                    <p>
                      {" "}
                      <a
                        href={
                          "https://en.wikipedia.org/wiki/National_Union_of_Namibian_Workers"
                        }
                        target="_blank"
                      >
                        Read about 9 affiliated member unions of NUNW{" "}
                      </a>{" "}
                    </p>
                  </div>
                </div>

                {store.education.all.map((post) => (
                  <Fragment key={post.id}>
                    <EducationItem title={post.title} content={post.content} />
                  </Fragment>
                ))}
                <div className="education-item uk-accordion-item uk-card uk-card-default uk-card-small uk-card-body">
                  <div className="title uk-accordion-title">Labour Act</div>
                  <div className="uk-accordion-content">
                    <p>
                      <a className="download " href={labourAct} download>
                        Download Labout Act 11 of 2007{" "}
                        <span data-uk-icon="download"></span>
                      </a>
                    </p>
                  </div>
                </div>
                <div className="education-item uk-accordion-item uk-card uk-card-default uk-card-small uk-card-body">
                  <div className="title uk-accordion-title">
                    NAFAU Constitution.
                  </div>
                  <div className="uk-accordion-content">
                    <p>N/A</p>
                  </div>
                </div>
                <div className="education-item uk-accordion-item uk-card uk-card-default uk-card-small uk-card-body">
                  <div className="title uk-accordion-title">
                    NAFAU ACTIVITIES
                  </div>
                  <div className="uk-accordion-content">
                    <ul data-uk-accordion>
                      <li>
                        <div className="education-item uk-card uk-card-default uk-card-small uk-card-body">
                          <div className="title">WHAT IS MAYDAY?</div>
                          <div className="uk-accordion-content">
                            <p>
                              May Day is a public holiday for workers. Other
                              names for this holiday are; Workers' Day, and
                              Labor Day. The day is commemorated on 1st May of
                              each year.
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="education-item uk-card uk-card-default uk-card-small uk-card-body">
                          <div className="title">national congress</div>
                          <div className="uk-accordion-content">
                            <p></p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="education-item uk-card uk-card-default uk-card-small uk-card-body">
                          <div className="title">Education conference</div>
                          <div className="uk-accordion-content">
                            <p></p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="education-item uk-card uk-card-default uk-card-small uk-card-body">
                          <div className="title">Seminar for women</div>
                          <div className="uk-accordion-content">
                            <p></p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Education;

// <SectionHeading
//heading="Education."
//subheading="Learn about the Union"
///>
