import React, { MouseEvent, useState } from "react";
import SectionHeading from "../../../shared/components/section-heading/SectionHeading";
import IntroSection from "../../../shared/Intro/IntroSection";
import "./Scope.scss";

const industries = [
  {
    image: process.env.PUBLIC_URL + "/assets/scope/casino.jfif",
    title: "Casino & Gaming (Gambling) Industry",
    description: `The casino and Gambling industry includes persons employed in casinos, gambling houses, bars, shebeens, or by 
    the manufacturers & suppliers who import, manufacture, sell, lease, make available, distribute, maintain, or repairs gambling machines.`,
    more: ``,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/biscuit-manu2.jpg",
    title: "Baking Industry",
    description: `The baking industry includes those involved in the production, distribution, sale, packaging, importation or
     exportation, wholesaling, and retailing, and includes but is not limited to bread`,
    more: `cakes, pastries, biscuits, cookies, crackers, and tortillas, as well as the production, distribution, sale, and packaging,
     importation or exportation, wholesaling and retailing of any complementary commodity or ingredient used in baking, flouring, extracting,
      and jelly powder condiment.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/breweries.jpg",
    title: "Brewing Industry",
    description: `The brewing industry includes those involved in the production (malting, mashing, milling, lautering, boiling,
       fermenting, conditioning, filtering).`,
    more: `packaging, distribution, wholesaling, sale and import and export of beer and malt liquor
    and includes but is not limited to microbreweries, brewpubs, taproom breweries, regional breweries, contract brewing companies, and alternating proprietors.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/construction.jpg",
    title: "Building Materials Industry",
    description: `The manufacturing, selling, packaging, distribution, and sales of building materials and include but are not 
    limited to cement, brick, concrete, tiles, sand, aggregates, gravel manufacturers, steel, glass, wood, plastics, and supplies to related
     industries such as wiring and furniture manufacturers.`,
    more: `The industry further includes green building materials which include but is not limited to products such as cork, bamboo and woven
     flooring, concrete production from recycled materials along with wood from sustainable forests, energy-efficient lighting fixtures and
      water-efficient plumbing fixtures.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/wines.jpg",
    title: "Alcohol & Non-Alcohol Trading Industry",
    description:
      "All persons involved in the supply, wholesaling, sale and trading of alcoholic liquor and non-alcoholic drinks either on consumption or off-sales.",
    more: ``,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/charcoal.jpg",
    title: "Charcoal & Wood Industry",
    description: `The charcoal and wood industry includes those involved in the discovery, production, processing, packaging, distribution, retail,
     sale and import and export of charcoal, wood, and coal. `,
    more: ``,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/cold-storage.jfif",
    title: "Cold Storage Industry",
    description: `The cold storage industry involves those that provide storage for products and materials that require some form of temperature
     control, most typically food and pharmaceuticals.`,
    more: ` These also include those that distribute, refrigerate, cure, prepare for export, market, and sell frozen produce, and preserve food by cold storage.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/cool-drinks-minerals.jpg",
    title: "Cool Drinks and Mineral Water",
    description:
      "The manufacturing, bottling, packaging, sale and distribution of cool drinks, soda, energy drinks and mineral water.",
    more: ``,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/milk.jpg",
    title: "Dairy Industry",
    description: `The Dairy industry includes those involved in the packaging, processing, manufacturing, selling and distribution of milk, sterilised milk,
     condensed milk, milk powder, skim milk, skim milk powder,`,
    more: `casein, cream, butterfat, buttermilk powder, butter, process butter, cheese, process cheese, and includes margarine and all substitutes for butter
     made from vegetable or animal fats, or a combination of vegetable and animal fats.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/ecommerce.jpg",
    title: "E-Commerce Industry",
    description: `The electronic commerce (e-commerce) industry involves those that sell and trade goods and services over the internet and/ or over the phone and/ or
     the transmitting of funds or data, over an electronic network. The e-commerce industry operates in different markets and can include either,`,
    more: `business-to-business (B2B), business-to-consumer (B2C), consumer-to-consumer or consumer-to-business. E-commerce businesses can include but are
    not limited to online shops, on-demand food ordering and delivery services, online catalogues and over-the-phone ordering.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/electronics.jpg",
    title: "Electronics & Appliances Industry",
    description: `The electronics and appliance industry includes those involved in the manufacturing, selling, packaging, distribution,  and sales and import
     and export of all types of electronics and appliances, including but not limited to IT hardware.`,
    more: `(including but not limited to computer hardware and technology,
      peripheral devices, infrastructure, and semiconductors), home electronics and appliances (including but not limited to refrigerators, freezers, ovens, microwaves,
        dishwashers and washing machines, coffee makers and kettles, vacuums and blenders), office electronics (including but not limited to air conditioning, telephone
          equipment, photocopiers and printers, storage equipment and fax machines, scanners) and appliances and industrial electronics and appliances (cooktops, range
            hoods & vents, gas & electric ranges, and kitchen appliance parts).`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/sports.jpg",
    title: "Entertainment & Sports Industry",
    description: `The entertainment and sports industry includes those that are involved in the entrainment and sports but exclude the artists and sports
     professions and can include but are not limited to those that work at cinemas, theatres, galleries, sports clubs, entertainment lounges, sporting
      facilities, fitness centres and recreational facilities.`,
    more: ``,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/culinary.jpg",
    title: "Food Service (Culinary) Industry",
    description: `The food service industry includes those involved in the preparation, cooking, presenting, and serving of food and beverages. This
     can include culinary professionals that work in restaurants, hotels, hostels, catering services and mobile food trucks. `,
    more: ``,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/fishing.jpg",
    title: "Fishing Industry",
    description: `The fishing industry includes those involved in any industry or activity concerned with catching, culturing, processing, preserving, canning,
     storing, preparing, transporting, marketing, and selling fish or fish products, including all marine resources.`,
    more: `In addition, this industry also includes recreational, subsistence and commercial fishing, and the related harvesting of fish and all marine resources,
     including the salting, drying, smoking, pickling of fish, fish products and all marine resources.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/fruits-veg.jpg",
    title: "Fresh Fruits and Vegetables & Processing Industry",
    description: `The fruits and vegetables processing industry include those involved in the alteration, production processing, sale, packaging, distribution
    , export and import, preservation of fresh fruits and vegetables and creation of value-added food products for human consumption.`,
    more: `Industry products include but it is not limited to the following products: fresh fruits and vegetables, canned and bottled fruits and vegetables, frozen fruit and vegetables, jam,
     fruit juice, squashes and cordials, dried fruit and mixed dried fruit, soup, glace and crystallised fruit, pickles and sauces, herbs, spices and condiments,
      chutney, infant and invalid food, dehydrated fruits, and vegetables.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/furniture.jpg",
    title: "Furniture Industry",
    description: `The furniture industry includes those involved in the manufacturing, selling, packaging, distribution, sales and import and export of all
     types of furniture, including but not limited to household furniture, office furniture, commercial furniture and industrial furniture.`,
    more: ``,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/hardware.jpg",
    title: "Hardware Industry",
    description: `The hardware industry includes those involved in the manufacturing, selling, packaging, distribution, and sales and import and export of all
     types of hardware.`,
    more: `including but not limited to power tools and equipment, paint and sundries, plumbing fixtures and supplies, hand tools and accessories, electrical 
    supplies, dimensional lumber and garden products and equipment.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/hospitality.jpg",
    title: "Hospitality Industry",
    description: `The hospitality industry includes persons involved in the food & beverage, travel & tourism, lodging, and recreation sectors. These sectors
     include but are not limited to travel agencies, guesthouses, hotels, lodges, hostels, campsites, restaurants, conference centres, catering services, cafés,`,
    more: `kiosks, and leisure activities including but not limited to wellness, boat cruise, game drives, quad biking, and other leisure activities.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/meat.png",
    title: "Meat Industry",
    description: `The meat industry includes persons who are producers of livestock, those involved in the slaughtering of livestock, the preparation, processing,
     preservation, packaging, export, distribution and sale of livestock, meat, and meat products including but not limited to bacon, polony, pate, ham, salami,
      sausages, boerewors, and biltong.`,
    more: `The industry also includes those involved in the manufacturing of meat by-products including but not limited to bone meal, raw skin and hides and canning meat.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/macarroni.jpg",
    title: "Milling Industry",
    description: `The milling industry includes those involved in the milling of wheat, maize, millet, sorghum and other cereals, peanuts and seed oil, and the
     manufacturing, sale packaging and distribution of wheat flour, maize flour and rice, stamp rice, breakfast cereal,`,
    more: `animal feeds, edible oil and cake, margarine, peanut butter, seed oil, pasta, beans, peas and land lentils, glucose sugar and starches.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/greenhouse.jpg",
    title: "Nursery, Greenhouse, & Herbs Industry",
    description: `The nursery, greenhouse and herbs industry includes growers and producers of plants, wholesalers and hirers of plants, wholesale and production
     nurseries, retailers of plants and related products, gardens and landscapers & garden care operators, allied traders (suppliers of amongst others growing media.`,
    more: `chemicals, containers, tools and equipment) those involved in the production, processing, packaging, distribution, and sale of nursery, greenhouse and herbs
    products such as plants, flowers, herbs, and greenhouse crops such as tomatoes, peppers and spinach. The industry also includes those involved in hydroponics
     and aquaponics and the manufacturers, suppliers, distributors, retailers, wholesalers, and exporters/importers of aquaponic & hydroponic systems and equipment`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/pharmaceutical.jpg",
    title: "Pharmaceutical & Dermatology Industry",
    description: `The pharmaceutical and dermatology industry includes those involved in the production, processing, packaging, distribution, storage, sale, and
     import and export of pharmaceutical surgical, clinical, consumer products, medicine, soap, body lotion, and all dermatological products and detergents.`,
    more: ``,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/poultry.jpg",
    title: "Poultry Industry",
    description: `The poultry industry includes those involved in animal (poultry) farming (including but not limited to chicken, duck, turkey, and goose), the
     slaughtering of poultry, production (including but not limited feed factories, hatcheries, breeding farms, and processing plants).`,
    more: `the preparation and preservation of poultry meat, packaging, distribution, sale and import and export of live poultry, poultry meat and eggs.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/salt-processing.jpg",
    title: "Salt Industry",
    description: `The salt industry includes those involved in the extraction, manufacturing, and/ or production, mining, crushing, purifying, refining and packaging,
     distribution, exploitation, sale and import and export of salt for both human consumption (table salt) and non-human consumption (industry salt production and other uses).`,
    more: ``,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/spices.jpg",
    title: "Seasoning & Spices Industry",
    description: `The seasoning and spices industry includes those involved in the manufacturing, processing, packaging, distribution, storage, sale and import and export
     of all types of seasoning (including but not limited to saline seasonings, acid seasonings and hot seasonings) and spices.`,
    more: `(including but not limited to pepper, ginger, cinnamon, cumin, turmeric, coriander, cardamom and cloves), and includes fresh and dried herbs such as but
     not limited to garlic, oregano, mint, parsley, rosemary and fennel.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/service-station.jpg",
    title: "Service (Fuel) Stations Industry",
    description: `The service station and/ or fuel station industry includes those involved in the retail automotive fuels (including but not limited to gasoline,
       diesel fuel, gasohol), automotive oils and/ or lubricants (including but not limited to motor oil, engine oil, or engine lubricant)`,
    more: `, and/ or retailers that offer these products or a combination of these products (retail automotive fuels and oils together with convenience store items.
        The industry mostly involves but is not limited to petrol attendants, cashiers, car detailers, and any other employee in the industry.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/corn.jpg",
    title: "Snack Food Production Industry",
    description: `The snack food industry includes those involved in the production, and/ or manufacturing, processing, packaging, distribution, supply, sale and import
     and export of all types of snacking food either as frozen foods, dried products, and even liquids and includes but not limited to potato chips.`,
    more: `Peanuts, fruit snacks, cookies, cakes, dried meat products, and candy.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/cool-drinks-minerals.jpg",
    title: "Soft Drinks & Soda Industry",
    description: `The soft drink and soda industry includes those involved in the manufacturing, bottling, processing, packaging, distribution, supply, sale, marketing
     and import and export of non-alcoholic, carbonated, flavoured, and sweetened, water-based beverages, such as but not limited to cool drinks, diet fizzy drinks,`,
    more: `juice, soda, energy and sports drinks, and mineral water (sparkling or still water), hot and iced coffee and tea.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/sugar.jpg",
    title: "Sugar Industry",
    description: `The sugar industry includes those involved in the growing, milling, refining, processing, production, sale, packaging, distribution and import
     and export of raw cane sugar or refined sugarcane, sugar beets or starches to finished sucrose,`,
    more: `glucose or fructose, sucrose, saccharose and syrups, specialised sugars and a range of by-products including but not limited to beet pulp and inedible molasses.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/sweets.jpg",
    title: "Sweets & Confectionery Industry",
    description: `The sweets and confectionery industry include those involved in the production, manufacturing, making, sale, packaging, distribution and import
     and export of sweets and confectionery products such as but not limited to chocolates, non-chocolate, gum and various sugar-based and cocoa products, and any
      commodity, or ingredient used in making sweets and chocolates.`,
    more: ``,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/tobacco.jpg",
    title: "Tobacco Industry",
    description: `The tobacco industry includes those involved in the farming (growing), manufacturing, processing, sale, packaging, and distribution (wholesale),
     and import and export of tobacco (including but not limited to cigarettes (including e-cigarettes), cigars, snuff, chewing tobacco, dip, snus, and pipe tobacco`,
    more: `(including hookah or water pipe) and tobacco-related products such as but not limited to e-vaporizers, bidis, and kreteks.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/spirits.jpg",
    title: "Wine & Spirits Industry",
    description: `The wine (including champagne and sparkling wines) and spirit (including, liqueurs) industry include those involved in the production,
     fermentation, distilling, fortifying, packaging, distribution, sale and import and export of all types of wines (including but not limited to red wines,`,
    more: `white wines,  rosé wines, dessert wines, fortified wines, sparkling wines and champagne) and spirits (including but not limited to vodka, tequila,
       gin, rum, whiskey, mezcal and brandy).`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/cleaning.jfif",
    title: "Cleaning Service Industry",
    description: `The cleaning industry includes those involved in cleaning services performed at residential (homes), commercial (offices, schools,
       car wash, and other public areas), industrial, manufacturing and warehouse facilities. `,
    more: `The cleaning industry also includes but is not limited to those involved in the production, packaging, distribution, retail, and sale of
     cleaning products such as laundry detergent, laundry care products, household cleaners, dishwashing detergents, and cleaning tools.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/combine-textile-garment.jpg",
    title: "Clothing (Apparel, Garment & Textiles) Industry",
    description: `The clothing industry includes those involved in the designing, production, manufacturing, textile, retailing, packaging, distribution,
     sale, value chain and import and export of clothing, apparel, garments and textiles and related products.`,
    more: ``,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/coffee-chocory.jpg",
    title: "Coffee, Tea, & Chicory Industry",
    description: `The coffee, tea and chicory industry include those involved in the production, packaging, distribution, sale and processing of tea, coffee
     and chicory roasting of coffee chicory, and the manufacturing of instant tea or coffee powder essence or extracts, ready-to-serve products, instant, and
      brewed ingredients.`,
    more: ``,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/agar-gelatin.jpg",
    title: "Agar & Gelatin Industry",
    description: `The agar and gelatine industry includes those involved in the production, manufacturing, cooking, canning, storage, milling, preservation,
     bottling, sale and import and export of any food liquid consumable by a human being, livestock, animals and at the same time selling any material/services
      related to these activities.`,
    more: `This includes but is not limited to medicine/pharmaceutical products of which the purpose is agar and gelatin, packaging and distributing, merchandising
     and sale of any of the above.`,
  },
  {
    image: process.env.PUBLIC_URL + "/assets/scope/farming.jpg",
    title: "Agricultural & Farming Industry",
    description: `The agricultural and farming industry includes those involved following activities but are not limited to agricultural production (farming),
     horticulture and cropping, animal husbandry, livestock production, agro-processing and marketing services, irrigation-based agronomic production,`,
    more: `import/export marketing of local fresh produce including the production, manufacturing, processing, and sale of products used /rendering of services
     from or to the abovementioned activities including but is not limited to farming materials, timber, fencing materials, pools and wires, animal products
      clearing materials and equipment, seeds, animal/livestock feeding products, animal/livestock medicine, fertilizers, as well as feeding products and medicine for wild animals.`,
  },
];

const scope = [
  {
    id: "1",
    title: "THE UNION SCOPE",
    desc: `All workers engaged in the food, retail, wholesale, and allied industries shall be eligible for membership in the Union. The food, retail, wholesale,
     and allied industries‘’ shall mean and include the industries in which the employees and the employers are associated or for any or more of any workplaces
      or organizations(s) in which the selling of any products/ rendering of services falls or is associated with the following sectors and industries:`,
    backgroundImage: process.env.PUBLIC_URL + "/assets/scope/farming.jpg",
  },
];

const Scope = () => {
  const loadingGIF = `${process.env.PUBLIC_URL}/assets/gallery/Loading.gif`;
  const [isLoading, setIsLoading] = useState(true);
  function onLoad() {
    setTimeout(() => setIsLoading(false), 1000);
  }

  const readMore = () => {
    var dots = document.getElementById("dots") as HTMLElement;
    var moreText = document.getElementById("more") as HTMLElement;
    var btnText = document.getElementById("myBtn") as HTMLButtonElement;

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less";
      moreText.style.display = "inline";
    }
  };

  return (
    <div className="scope">
      <IntroSection data={scope} />
      <div className="uk-section">
        <div className="container uk-container">
          <div
            className="uk-child-width-1-3@m uk-grid-small uk-grid-match"
            data-uk-grid
            data-uk-scrollspy="target: .long-card; cls: uk-animation-slide-bottom; delay: 100"
          >
            {industries
              .sort((a, b) => (a.title > b.title ? 1 : -1))
              .map((industry, index) => (
                <div key={index}>
                  <div className="short-card uk-card uk-card-default uk-card-hover">
                    <div className="uk-card-media-top thumbnail">
                      <img
                        alt="Loading"
                        src={loadingGIF}
                        style={{ display: isLoading ? "block" : "none" }}
                      />
                      <img
                        src={industry.image}
                        width="1800"
                        height="1200"
                        alt=""
                        style={{ display: isLoading ? "none" : "block" }}
                        onLoad={onLoad}
                      />
                    </div>
                    <div className="uk-card-body">
                      <h3 className="uk-card-title">{industry.title}</h3>
                      <p
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        {industry.description}
                      </p>
                      {industry.more.length !== 0 && (
                        <ul
                          data-uk-accordion
                          style={{
                            marginTop: 0,
                          }}
                        >
                          <li className={`read-more`}>
                            <div className="uk-accordion-content">
                              <p>{industry.more}</p>
                            </div>
                            <a className={`uk-accordion-title`} href="#">
                              Read More
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Scope;

{
  /* <ul data-uk-accordion>
                        <li className="uk-open">
                          <a className="uk-accordion-title read-more" href="#">
                            Read more
                          </a>
                          <div className="uk-accordion-content">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua.
                            </p>
                          </div>
                        </li>
                      </ul> */
}

// const scope = [https://movieshd.watch/
//   {
//     id: "1",
//     title: "Our Scope",
//     desc: `All workers engaged in the Food, Retail, Wholesale and Allied industries, shall be eligible for membership of the Union. ‘’Food, Retail, Wholesale and Allied industry ‘’ shall mean the industry in which the employees and the employers are associated or the purpose of any or more of the following workplaces or institution(s) selling any products/ rendering services in the sectors that are mentioned below.`,
//     backgroundImage: process.env.PUBLIC_URL + "/assets/scope/farming.jpg",
//   },
// ];

// const shortIndustries = [
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/casino.jfif",
//     title: "Accommodation establishments, Casino and Liquor trade",
//     description:
//       "Guesthouses, hotels, lodges, hostels, campsites, gambling and casino, restaurant, including on-demand food ordering and delivery services, catering, café, kiosks, and liquor stores.",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/biscuit-manu2.jpg",
//     title: "Biscuits and Baking Manufacturing",
//     description:
//       "The manufacturing, sale, packaging, and distribution of biscuits, wafers, cones, flouring, extracting and jelly powder condiment bread and confectionery.",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/wines.jpg",
//     title: "Brewing, Wines and Spirits",
//     description:
//       "The brewing, packaging, distribution and sale of beer and malt liquor. \nThe manufacturing, distilling, packaging, distribution and sales of wine and spirits.",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/charcoal.jpg",
//     title: "Charcoal ",
//     description:
//       "The production, processing, packaging, distribution, and sale of charcoal.",
//   },
//   {Entertainment
//     image: process.env.PUBLIC_URL + "/assets/scope/cold-storage.jfif",
//     title: "Cold Storage",
//     description:
//       "Distribution, marketing and sale of frozen produce and the preservation of food by cold storage.",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/cool-drinks-minerals.jpg",
//     title: "Cool Drinks and Mineral Water",
//     description:
//       "The manufacturing, bottling, packaging, sale and distribution of cool drinks, soda, energy drinks and mineral water.",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/milk.jpg",
//     title: "Dairy Products Industry",
//     description:
//       "Includes packaging or bottling, sale and distribution of whole or skimmed milk. The manufacturing of cream, butter, cheese, yogurt, buttermilk, ice cream, condensed milk and milk powder.",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/cleaning.jfif",
//     title: "Entertainment establishment, Sports and Cleaning Services",
//     description: "Clubs, bars, all sports, and provide cleaning services.",
//   },

//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/nuts.jpg",
//     title: "Nuts and Snacks",
//     description:
//       "The packaging, distribution, sale and processing of edible nuts and snacks.",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/pharmaceutical.jpg",
//     title: "Pharmaceutical & Dermatological Products",
//     description:
//       "Production, processing, packaging, distribution and sale of pharmaceutical surgical, clinical, consumer products, medicine, soap, body lotion and all dermatological detergents.",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/poultry.jpg",
//     title: "Poultry and Eggs",
//     description:
//       "Poultry farming, slaughtering of poultry, the preparation and preservation of poultry meat, packaging, distribution and sale of live poultry, poultry meat and eggs.",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/combine-textile-garment.jpg",
//     title: "Retailing, Garment, and Textiles",
//     description:
//       "Textile retailing shops, backpackers, garment manufacturing plants, clothes and related retailing shops. ",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/salt-processing.jpg",
//     title: "Salt Processing",
//     description:
//       "Manufacturing, mining, packaging, distribution, extracting and exploitation of salt miscellaneous food products. ",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/service-station.jpg",
//     title: "Service Stations",
//     description: "Petrol Attendants, cashiers, fuel and lubrication supplies. ",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/sugar.jpg",
//     title: "Sugar Manufacturing and Refining",
//     description:
//       "Refining processing, production, sale, packaging and distribution of sugar sucrose and sugar by-products.",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/sweets.jpg",
//     title: "Sweets and Chocolates",
//     description:
//       "Cocoa and chocolate powder manufacturing, the making, sale, packaging and distribution of sweets and chocolates and any commodity, or ingredient used in making sweets and chocolates.",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/coffee-chocory.jpg",
//     title: "Tea, Coffee, and Chicory",
//     description:
//       "The making, packaging, distribution, sale and processing of tea, coffee and chicory roasting of coffee chicory, and the manufacturing of instant tea or coffee powder essence or extracts.",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/tobacco.jpg",
//     title: "Tobacco",
//     description:
//       "The manufacturing, processing, sale, packaging, and distribution of tobacco and its derivative products.",
//   },
// ];

// const longIndustries = [
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/fishing.jpg",
//     title: "Fishing and Fish Processing",
//     description:
//       "The processing, sale, packaging, distribution, or preservation of fish, canning and carrying.",
//     more: " Example: Salting, drying, smoking, pickling, and catching of lobster as well as other seafood, fish meal, fish oil, and fishing operations incidental to these activities as well as seagoing workers.",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/fruits-veg.jpg",
//     title: "Fruit and Vegetable Processing",
//     description:
//       "The processing, sale, packaging, distribution, preservation of fruits and vegetables.",
//     more: "The section includes the following products: canned, bottled fruits and vegetables, frozen fruit and vegetables, jam, fruit juice, squashes, cordials, dried fruit, mixed dried fruit, soup, glace, crystalised fruit, pickles, sauces, herbs, spices, condiments, chutney, infant and invalid food, dehydrated fruits and vegetables.",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/meat.png",
//     title: "Meat Processing and Packaging",
//     description:
//       "The slaughtering of livestock, the preparation, preservation, export, distribution, and sale of livestock.",
//     more: "The sales includes meat, bacon, polony, sausages, biltong manufacturing, and meat packaging, including by-products e.g., the manufacturing of blood meal, bone meal, raw skin, and hides, and canning meat.",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/macarroni.jpg",
//     title: "Milling",
//     description:
//       "Milling of wheat, maize and other cereals, peanuts and seed oil, and the manufacturing, sale packaging.",
//     more: "This includes distribution of wheat flour, maize flour and rice, stamp rice, breakfast cereal, animal feeds, edible oil and cake, margarine, peanut butter, macaroni, spaghetti, beans, peas, land lentils, glucose sugar and starches.",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/agar-gelatin.jpg",
//     title: "Agar-gelatin manufacturing",
//     description:
//       "Include all operation incidental or any of the aforesaid activities of manufacturing, cooking, canning and storage.",
//     more: "The process also involves milling, preservation, bottling or any food liquid consumable by a human being, livestock, animals and at the same time selling any material/services related to these activities. This includes medicine/pharmaceutical products, packaging and distributing, merchandising and sale of any of the above.",
//   },
//   {
//     image: process.env.PUBLIC_URL + "/assets/scope/farming.jpg",
//     title: "Agricultural and Farming sectors",
//     description:
//       "Composed of the following activities: agricultural production, horticulture, and cropping, animal husbandry, livestock production, agro-processing and marketing services, irrigation-based agronomic production.",
//     more: "This includes the export marketing of local fresh produce including the production, manufacturing, processing, and sale of products used /rendering of services from or to the abovementioned activities including farming materials, timber, fencing materials, pools and wires, animal products clearing materials and equipment, seeds, animal/livestock feeding products, animal/livestock medicine, as well as feeding products and medicine for wild animals.",
//   },
// ];

{
  /* <div
            className="uk-child-width-1-3@m uk-grid-small uk-grid-match"
            data-uk-grid
            data-uk-scrollspy="target: .short-card; cls: uk-animation-slide-bottom; delay: 100"
          >
            {industries
              .sort((a, b) => (a.title > b.title ? 1 : -1))
              .map((industry, index) => (
                <div key={index}>
                  <div className="short-card uk-card uk-card-default uk-card-hover">
                    <div className="uk-card-media-top thumbnail">
                      <img
                        alt="Loading"
                        src={loadingGIF}
                        style={{ display: isLoading ? "block" : "none" }}
                      />
                      <img
                        src={industry.image}
                        width="1800"
                        height="1200"
                        alt=""
                        style={{ display: isLoading ? "none" : "block" }}
                        onLoad={onLoad}
                      />
                    </div>
                    <div className="uk-card-body uk-padding-small">
                      <h3 className="uk-card-title">{industry.title}</h3>
                      <p>{industry.description}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div> */
}
