import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./NewsPost.scss";
import PostParagraphs from "../../shared/components/post-paragraphs/PostParagraphs";
import PostThumbnail from "./PostThumbnail";
import { useAppContext } from "../../../shared/utils/Context";
import INewsPost, {
  defaultNewsPost, INewsCategory,
} from "../../../shared/interfaces/INewsPost";
import { uploadFile } from "../../../shared/apis/FileApi";
import { observer } from "mobx-react-lite";

interface IToolbar {
  onBack: () => void;
}
const Toolbar = (props: IToolbar) => {
  return (
    <div className="section-toolbar uk-margin">
      <h5 className="title" onClick={() => props.onBack()}>
        <span data-uk-icon="icon: chevron-left; ratio: .8"></span> Back
      </h5>
    </div>
  );
};

const ViewNewsPost = observer(() => {
  const { api, store } = useAppContext();
  const { postId } = useParams();

  const navigate = useNavigate();
  const [post, setPost] = useState<INewsPost>({
    ...defaultNewsPost,
  });
  const [paragraphs, setParagraphs] = useState([""]);
  const [src, setSrc] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [loading, setLoading] = useState(false);
  const [isUploading, setisUploading] = useState(false);

  const onDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    const dateInMillis = new Date(date).getTime();
    setPost({ ...post, date, dateInMillis });
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isFormValid()) {
      console.log("Pls select a thumbnail!");
      return; // Pls select a file
    }

    if (selectedFile) {
      // Upload thumbnail
      uploadImage();
    } else {
      console.log("No file selected");
      uploadPost();
    }
  };

  const isFormValid = (): boolean => {
    const $post = { ...post };
    // Check if file is available
    const fileAvailability = $post.thumbnailUrl ? true : false;
    // Check if file is selected
    const isFileSelected = selectedFile ? true : false;

    return fileAvailability || isFileSelected;
  };

  const uploadImage = () => {
    const $post = { ...post };

    if (!selectedFile) return; // Update doc
    setisUploading(true);

    // Get post id
    const id = $post.id ? $post.id : api.news.getDocId;
    if (!$post.id) {
      console.log("Setting post id => ", { ...$post, id });
      setPost({ ...$post, id });
    }

    uploadFile(selectedFile, `news/${id}`, (url) => {
      setisUploading(false);
      // Url
      // console.log("Post with ID => ", $post);
      // Create post.
      uploadPost(url);
    });
  };

  const uploadPost = async (url?: string) => {
    // console.log("$Post => ", post);

    const $post: INewsPost = url
      ? { ...post, content: paragraphs, thumbnailUrl: url }
      : { ...post, content: paragraphs };

    // console.log("Post => ", $post);

    // Create post
    try {
      await api.news.create($post);
      onNavigateBack();
    } catch (error) {
      console.log("Error!");
    }
  };

  const loadPost = ($post: INewsPost) => {
    setPost($post);
    setSrc($post.thumbnailUrl);
    setParagraphs($post.content);
  };

  const loadFromDB = async (id: string) => {
    try {
      setLoading(true);
      await api.news.loadById(id);
      setLoading(false);

      const $post = store.news.items.get(id);
      if ($post) loadPost($post);
      else onNavigateBack();
    } catch (error) {
      onNavigateBack();
    }
  };

  const loadExistingPost = useCallback(() => {
    if (!store.news.selected) {
      if (postId) loadFromDB(postId);
      else onNavigateBack();
    } else {
      loadPost(store.news.selected);
    }
  }, [store.news.selected]);

  const loadNewPost = useCallback(() => { }, []);

  const onNavigateBack = () => {
    navigate("/a/news/");
  };

  useEffect(() => {
    if (postId === "new") loadNewPost();
    else loadExistingPost();

    return () => { };
  }, []);

  return (
    <div className="admin-news-post uk-section">
      <div className="container uk-container uk-container-large">
        <Toolbar onBack={onNavigateBack} />
        <PostThumbnail
          src={src}
          setSrc={setSrc}
          setSelectedFile={setSelectedFile}
          isUploading={isUploading}
        />

        <form
          className="uk-card uk-card-default uk-card-small uk-card-body uk-margin"
          autoComplete="off"
          onSubmit={onSubmit}
        >
          <div className="uk-grid-small uk-child-width-1-2@s" data-uk-grid>
            <div className="date">
              <p className="post-labels">
                <span data-uk-icon="icon: calendar; ratio: .8"></span> Date
              </p>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small"
                  id="post-date"
                  type="date"
                  placeholder="date"
                  value={post.date}
                  onChange={onDateChange}
                  required
                />
              </div>
            </div>

            <div className="category">
              <p className="post-labels">
                <span data-uk-icon="icon: hashtag; ratio: .8"></span> Category
              </p>
              <div className="uk-form-controls">
                <select
                  className="uk-select uk-form-small"
                  id="post-hashtag"
                  placeholder="Category"
                  value={post.category}
                  onChange={(e) =>
                    setPost({ ...post, category: e.target.value as INewsCategory })
                  }
                >
                  <option value={"Disputes"}>Disputes</option>
                  <option value={"Strikes"}>Strikes</option>
                  <option value={"Recognition agreements"}>Recognition agreements</option>
                  <option value={"Vacancies"}>Vacancies</option>
                  <option value={"Education"}>Education</option>
                  <option value={"Events and ceremonies"}> Events and ceremonies</option>
                  <option value={"General News"}>General News</option>
                </select>
              </div>
            </div>
          </div>

          <div className="heading uk-margin">
            <label className="post-heading">
              <span data-uk-icon="icon: link; ratio: .8"></span> Heading
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                id="post-heading"
                type="text"
                placeholder="Heading"
                value={post.heading}
                onChange={(e) => setPost({ ...post, heading: e.target.value })}
                required
              />
            </div>
          </div>
          <div className="heading uk-margin">
            <label className="post-heading">
              <span data-uk-icon="icon: tag; ratio: .8"></span>Highlights
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                id="post-heading"
                type="text"
                placeholder="Highlights"
                value={post.highlight}
                onChange={(e) =>
                  setPost({ ...post, highlight: e.target.value })
                }
                required
              />
            </div>
          </div>

          <PostParagraphs
            paragraphs={paragraphs}
            setParagraphs={setParagraphs}
          />

          <div className="uk-text-right">
            <button className="kit-btn kit-btn-primary" type="submit">
              <span data-uk-icon="icon: check; ratio: .8"></span> Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
});

export default ViewNewsPost;
