import React from "react";
import { useNavigate } from "react-router-dom";

const NOB = () => {
  const navigate = useNavigate();

  return (
    <div className="nob section-structure">
      <h6 className="title">
        National Office Bearers (NOB){" "}
        <button
          className="kit-btn-link kit-btn-primary"
          title="View the full list of NOB members"
          onClick={() => navigate("/governance/NOB")}
        >
          <span data-uk-icon="link"></span>
        </button>
      </h6>

      <div className="content">
        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p> There shall be a NOB of NAFAU comprising of the following:</p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">President</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Deputy President</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">National Treasurer</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Deputy National Treasurer</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    General Secretary – No voting rights
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Deputy General Secretary – No voting rights
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <hr />

        <h6 className="sub-title">
          Meeting of the National Office Bearer (NOB)
        </h6>
        <p className="description">
          The NOB shall meet at least once after every two months and as
          regularly as it may become necessary. By invitation of the NOB General
          Secretary, the Senior Finance Officer, National Membership
          Administrator, Education and Research Coordinator and National
          Coordinator may attend the NOB meetings.
        </p>

        <hr />

        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>The function of the National Office Bearers (NOB)</p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">
                    To receive reports from the secretariat.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    To assist the General Secretary with the day to day running of
                    union activities.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    To execute the union's decisions as determined by the Central
                    Executive Committee (CEC) and the National Executive Committee
                    (NEC).
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    To allocate float amount and other funds to the respective
                    branches and departments of the union.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    To provide political policies and organizational directives to the
                    union structures i.e., NEC and CEC.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>


        <hr />
        <h6 className="sub-title">
          Duties of the National Office Bearers (NOB)
        </h6>
        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>The President</p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">
                    The President shall preside and maintain order at all meetings of
                    the National Congress (NC), the CEC and the NEC as well as
                    ensuring that the constitution, standing rules and procedures are
                    being adhered to.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The President shall in conjunction with the NEC take steps to
                    ensure the carrying out of resolutions passed with as little delay
                    as possible he/she shall in general ensure the observance of the
                    constitution and sign all minutes of the meeting over which he or
                    she presides.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The president shall in conjunction with the other office bearers,
                    supervise NAFAU as a union. S/he shall have a casting vote and the
                    co-signatory to all financial documents.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <hr />

        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>The Deputy President</p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">
                    The Deputy President shall exercise the power and perform the duties
                    of the President in his/her absence and DP will assist the president
                    in the discharge of his/her duty.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <hr />
        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>Acting President</p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">
                    In the absence of both the President and the Deputy President from any
                    meeting, the members present shall elect one of their members to be
                    called “Acting President” to carry out duties of the president at such
                    meetings.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <hr />

        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>National Treasurer</p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">
                    The National Treasure shall supervise the keeping of proper
                    financial records.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The National Treasure shall be assisted by the General Secretary
                    in keeping proper books of accounts and shall have the rights to
                    inspect all records relating to the finances and books of account
                    of the union.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The National Treasure shall represent a financial report or
                    financial statement to the NEC as well as a statement of income
                    and expenditure to each meeting of the CEC.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The National Treasure shall be responsible, in conjunction with
                    the General Secretary for all monies, properties and budget of
                    NAFAU.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The National Treasure shall submit an audited statement of income
                    and expenditure and balance sheet for inclusion in the annual
                    report of the NEC and CEC to be presented at the NC.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The National Treasure shall scrutinize all the financial documents
                    at least once every month.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The National Treasure shall have an authorizing signature in
                    respect of all the financial documents.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The National Treasure shall immediately report to the NEC of any
                    irregularity in the financial affairs of the Union, which may come
                    to his/her notice.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <hr />

        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>Deputy National Treasurer</p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">
                    The Deputy National Treasurer shall execute the duty of the National
                    Treasure in his or her absence.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <hr />
        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>General Secretary (GS)</p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">
                    The general secretary shall be an employee of the union engaged on
                    such terms and conditions the NEC deems fit.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Except for the President, the GS shall be the official
                    spokesperson of the union with the consent of the Shop Steward
                    Committee (SSC) at a particular institution or establishment, the
                    GS or Deputy General Secretary or his/her appointee shall be the
                    official negotiator on behalf of NAFAU members with their
                    employers.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The GS is appointed and mandated to act on behalf of the union and
                    shall manage the affairs of the union on any matter as mandated
                    and directed by the NEC, the CEC, and the NC to speak on behalf,
                    defend and protect the union.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The GS shall attend all the NC, CEC and the NEC and shall be
                    responsible to report the overall activities of the union and
                    ensure proper minutes and or records of all structural meetings
                    are taken.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    In terms of clause 15.5.6 (c), the General Secretary or Deputy
                    General Secretary is mandated to institute, conduct, defend and
                    abandon any legal proceeding by and against NAFAU and or its
                    elected office bearers and officials.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    In addition, he/she shall be responsible for all union head office
                    correspondences and shall ensure that official receipts of all
                    monies received are issued together with the National Treasure,
                    submit financial reports to the NC, the CEC, and the NEC by law.
                    S/he shall have no voting right at any meeting of the constituent
                    bodies of the union.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The GS shall be responsible for all monies received by NAFAU,
                    together with the union’s auditor for the annual budget and
                    monthly financial statement to be presented to the NEC, CEC, and
                    the NC.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The GS shall be co-signatory to all official documents with the
                    approval of the NEC.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The GS shall be elected by a majority decision of delegated
                    attending NC and shall hold office until the election of the next
                    GS at the next NC. S/he shall be eligible for re-election.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The GS shall ensure that a register of members is maintained that
                    proper books auditing is done annually and all legal requirements
                    in respect of the financial matters are adhered to..
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The GS shall be responsible for the supervision of the union’s
                    employees and shall determine the duties of any staff member, not
                    specifically mentioned in the constitution, administrative policy
                    and any other position created in an effort to carry the duties
                    set out herein.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The GS shall prepare and draw up an annual report on the
                    activities of NAFAU to the NEC, the CEC and present it to the NC.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <hr />
        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>Deputy General Secretary (DGS)</p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">
                    The Deputy General Secretary shall assist the general secretary in
                    the execution of his/her duties and act as a General Secretary in
                    his absence.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The DGS shall be responsible for coordinating the work of the
                    union, especially the Union’s operation. The DGS shall be
                    responsible for the National Education Sub-Committee (NESCO). The
                    DGS shall promote dialogue between all parties involved in the
                    union education to improve the coordination of activities and
                    focus resources more effectively. Developing union education
                    networks for knowledge sharing, capacity building and influencing
                    social and economic policies.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The DGS shall carry the duties of the GS in his/her absence.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NOB;
