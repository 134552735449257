import {
  onSnapshot,
  doc,
  collection,
  deleteDoc,
  setDoc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import { db } from "../utils/firebaseConfig";
import INewsPost from "../interfaces/INewsPost";
import AppStore from "../stores/AppStore";

const $collection = "news";

class NewsApi {
  private colRef = collection(db, $collection);

  constructor(private store: AppStore) {}

  // Load all
  async loadAll() {
    const $query = query(collection(db, $collection), orderBy("date"));
    const querySnapshot = await getDocs($query);
    const posts = querySnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id } as INewsPost;
    });
    this.store.news.load(posts);
  }

  // Load by id
  async loadById(id: string) {
    const $doc = await getDoc(doc(this.colRef, id));

    if (!$doc.exists()) return;

    const posts = [{ id: $doc.id, ...$doc.data() } as INewsPost];
    this.store.news.load(posts);
  }

  // Update
  create(post: INewsPost) {
    const $post = { ...post };
    if (!$post.id) $post.id = doc(this.colRef).id;

    return setDoc(doc(this.colRef, $post.id), $post, { merge: true });
    // timestamp: serverTimestamp()
  }

  // Delete
  async delete(id: string) {
    await deleteDoc(doc(this.colRef, id));
    this.store.news.remove(id);
  }
  // Doc id
  get getDocId() {
    return doc(this.colRef).id;
  }
}

export default NewsApi;
