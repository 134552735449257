import { useCallback, useEffect, useState } from "react";
import NavItem, { NavButton } from "./NavItem";
import { useAppContext } from "../../shared/utils/Context";
import { useNavigate } from "react-router-dom";
import "./Drawer.scss";

const Drawer = () => {
  const { api, store } = useAppContext();
  const navigate = useNavigate();

  const [role, setRole] = useState<"Admin" | "NEO" | "MR">("NEO");
  const currUser = store.users.currentUser;

  const getUserDoc = useCallback(() => {
    if (store.users.loading) return;
    if (currUser) setRole(currUser.role);
  }, [store.users]);

  useEffect(() => {
    getUserDoc();
  }, [getUserDoc]);

  const onLogOut = async () => {
    await api.users.signOutUser().then(() => {
      navigate("/");
    });
  };

  return (
    <div className="drawer">
      <div className="header">
        <img src={process.env.PUBLIC_URL + "/logo264.png"} alt="" />
      </div>
      <div className="nav-items">
        <NavItem icon={"home"} label={"Dashboard"} routeTo={"/a"} />
        {role === "NEO" && (
          <>
            <NavItem icon={"world"} label={"News"} routeTo={"/a/news"} />
            <NavItem
              icon={"rss"}
              label={"Education"}
              routeTo={"/a/education"}
            />
            <NavItem
              icon={"location"}
              label={"Offices"}
              routeTo={"/a/members/offices"}
            />
          </>
        )}
        {role === "MR" && (
          <>
            <NavItem
              icon={"users"}
              label={"Membership"}
              routeTo={"/a/members/applications"}
            />
            <NavItem
              icon={"comments"}
              label={"Reported Issues"}
              routeTo={"/a/reported-issues"}
            />
          </>
        )}

        {role === "Admin" && (
          <>
            <NavItem icon={"users"} label={"Users"} routeTo={"/a/users"} />
            <NavItem icon={"world"} label={"News"} routeTo={"/a/news"} />
            <NavItem
              icon={"rss"}
              label={"Education"}
              routeTo={"/a/education"}
            />
            <NavItem
              icon={"location"}
              label={"Offices"}
              routeTo={"/a/members/offices"}
            />
            <NavItem
              icon={"users"}
              label={"Membership"}
              routeTo={"/a/members/applications"}
            />
            <NavItem
              icon={"comments"}
              label={"Reported Issues"}
              routeTo={"/a/reported-issues"}
            />
          </>
        )}
        <NavItem icon={"link"} label={"Back to site"} routeTo={"/"} />
        <NavButton icon={"sign-out"} label={"Logout"} onClick={onLogOut} />
      </div>
    </div>
  );
};

export default Drawer;
