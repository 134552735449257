import React, { ChangeEvent, Dispatch, Fragment, SetStateAction } from "react";
import "./PostParagraphs.scss";

interface IPostParagraph {
  index: number;
  paragraph: string;
  onRemoveParagraph: (index: number) => void;
  onUpdateParagraph: (index: number, content: string) => void;
}
const PostParagraph = (props: IPostParagraph) => {
  const { index, paragraph, onRemoveParagraph, onUpdateParagraph } = props;

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const content = e.target.value;
    onUpdateParagraph(index, content);
  };

  return (
    <div className="paragraph">
      <div className="uk-form-controls">
        <textarea
          className="post-paragragh uk-textarea uk-form-small"
          rows={3}
          placeholder="Paragraph"
          value={paragraph}
          onChange={onChange}
          required
        />
      </div>
      {index !== 0 && (
        <button
          className="btn post-remove-paragraph-btn"
          onClick={() => onRemoveParagraph(index)}
          type="button"
        >
          Remove <span data-uk-icon="icon: trash; ratio: .8"></span>
        </button>
      )}
    </div>
  );
};

interface IProps {
  paragraphs: string[];
  setParagraphs: Dispatch<SetStateAction<string[]>>;
}
const PostParagraphs = (props: IProps) => {
  const { paragraphs, setParagraphs } = props;

  const onAddParagraph = () => {
    setParagraphs((current) => [...current, ""]);
  };

  const onRemoveParagraph = (index: number) => {
    const $paragraphs = [...paragraphs];
    $paragraphs.splice(index, 1);

    setParagraphs($paragraphs);
  };

  const onUpdateParagraph = (index: number, content: string) => {
    const $paragraphs = [...paragraphs];

    if (index > $paragraphs.length) return;

    $paragraphs[index] = content;
    setParagraphs($paragraphs);
  };

  return (
    <div className="paragraphs">
      <p className="post-labels">
        <span data-uk-icon="icon: list; ratio: .8"></span> Content
      </p>

      {paragraphs?.map((paragraph, index) => (
        <Fragment key={index}>
          <PostParagraph
            paragraph={paragraph}
            index={index}
            onRemoveParagraph={onRemoveParagraph}
            onUpdateParagraph={onUpdateParagraph}
          />
        </Fragment>
      ))}

      <div className="uk-text-center">
        <button
          className="btn post-add-paragraph-btn"
          onClick={onAddParagraph}
          type="button"
        >
          <span data-uk-icon="icon: plus-circle; ratio: .8"></span> Add a
          Paragraph
        </button>
      </div>
    </div>
  );
};

export default PostParagraphs;
