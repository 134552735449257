import {
  onSnapshot,
  doc,
  collection,
  deleteDoc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../utils/firebaseConfig";
import { IReportedIssue } from "../interfaces/IReportedIssue";
import AppStore from "../stores/AppStore";

const $collection = "reportedIssues";

class ReportedIssuesApi {
  private colRef = collection(db, $collection);

  constructor(private store: AppStore) {}

  // Load all
  loadAll() {
    return onSnapshot(
      this.colRef,
      (snapshot) => {
        const issues: IReportedIssue[] = [];
        snapshot.forEach((doc) => {
          issues.push({ id: doc.id, ...doc.data() } as IReportedIssue);
        });

        this.store.reportedIssues.load(issues);
      },
      (error) => {
        // handle error
        // console.log("Error loading...");
      }
    );
  }

  // Load by id
  async loadById(id: string) {
    const $doc = await getDoc(doc(this.colRef, id));

    if (!$doc.exists()) return;

    const issues = [{ id: $doc.id, ...$doc.data() } as IReportedIssue];
    this.store.reportedIssues.load(issues);
  }

  // Update
  create(issue: IReportedIssue) {
    const $issue = { ...issue };
    if (!$issue.id) $issue.id = doc(this.colRef).id;

    return setDoc(doc(this.colRef, $issue.id), $issue, { merge: true });
    // timestamp: serverTimestamp()
  }

  // Delete
  async delete(id: string) {
    await deleteDoc(doc(this.colRef, id));
    this.store.reportedIssues.remove(id);
  }
}

export default ReportedIssuesApi;
