/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./NavBar.scss";
import OffCanvasNavBar from "./OffCanvasNavBar";
import showModalFromId from "../../shared/utils/ModalShow";
import DIALOG_NAMES from "../dialogs/Dialogs";

const NavBar = () => {
  return (
    <div
      className="navbar"
      data-uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky"
    >
      <div className="container uk-container">
        <nav className="uk-navbar-container" data-uk-navbar>
          <div className="uk-navbar-left">
            <button
              className="uk-icon-link uk-hidden@l"
              uk-icon="navbar-toggle-icon"
              type="button"
              uk-toggle="target: #offcanvas-navbar"
            ></button>
            <a href="/home">
              <img
                className="logo uk-navbar-item uk-logo"
                src={process.env.PUBLIC_URL + "/logo264.png"}
                alt=""
              />
            </a>
            <h4 className="brand-name uk-visible@l">
              Namibian Food & Allied Workers Union (NAFAU)
            </h4>
            <h4 className="brand-name uk-hidden@l">NAFAU</h4>
          </div>
          <div className="uk-navbar-right uk-visible@l">
            <ul className="uk-navbar-nav">
              <li className="nav-item uk-active">
                <NavLink to={"/"}>Home</NavLink>
              </li>
              <li className="nav-item">
                <a href="#">About Us</a>
                <div
                  className="dropdown uk-navbar-dropdown"
                  data-uk-toggle="on"
                >
                  <ul className="uk-nav uk-navbar-dropdown-nav">
                    <li>
                      <NavLink to={"about-us/history"}>History</NavLink>
                    </li>
                    <li>
                      <NavLink to={"about-us/gallery"}>Gallery</NavLink>
                    </li>
                    <li>
                      <NavLink to={"about-us/scope"}>Scope of NAFAU</NavLink>
                    </li>
                    <li>
                      <NavLink to={"about-us/aim-objective"}>
                        Aims &#38; objectives
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a href="#">Governance</a>
                <div className="dropdown uk-navbar-dropdown">
                  <ul className="uk-nav uk-navbar-dropdown-nav">
                    <li>
                      <NavLink to={"governance/structure"}>
                        Our structure
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"governance/NOB"}>Our leaders</NavLink>
                    </li>
                    <li>
                      <NavLink to={"governance/executive-committee"}>
                        Administration and Operation Committee
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a href="#">Membership</a>
                <div className="dropdown uk-navbar-dropdown">
                  <ul className="uk-nav uk-navbar-dropdown-nav">
                    <li>
                      <NavLink to={"members/apply"}>
                        Apply for Membership
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"members/benefits"}>Member Benefits</NavLink>
                    </li>
                    <li>
                      <NavLink to={"members/report-a-problem"}>
                        Report an Issue at work
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <NavLink to={"news"}>News</NavLink>
                <div
                  className="dropdown uk-navbar-dropdown"
                  data-uk-toggle="on"
                >
                  <ul className="uk-nav uk-navbar-dropdown-nav">
                    <li>
                      <a href="/news#disputes">Disputes</a>
                    </li>
                    <li>
                      <a href="/news#strikes">Strikes</a>
                    </li>
                    <li>
                      <a href="/news#recognition">Recognition Agreements</a>
                    </li>
                    <li>
                      <a href="/news#vacancies">Vacancies</a>
                    </li>
                    <li>
                      <a href="/news#education">Education</a>
                    </li>
                    <li>
                      <a href="/news#events">Events {"&"} Ceremonies</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <NavLink to={"education"}>Education</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={"members/offices"}>Contact Us</NavLink>
              </li>
            </ul>
          </div>
        </nav>
        <OffCanvasNavBar />
      </div>
    </div>
  );
};

export default NavBar;
