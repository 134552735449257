import React from "react";

interface IProps {
  title: string;
  content: string[];
}

const EducationItem = (props: IProps) => {
  const { title, content } = props;
  return (
    <div className="education-item uk-accordion-item uk-card uk-card-default uk-card-small uk-card-body">
      <div className="title uk-accordion-title">{title}</div>

      <div className="uk-accordion-content">
        {content.map((c, index) => (
          <p key={index}>{c}</p>
        ))}
      </div>
    </div>
  );
};

export default EducationItem;
