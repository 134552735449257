import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SectionHeading from "../../../shared/components/section-heading/SectionHeading";
import AOS from "./admin-org/AdminOrgStructure";
import BBAC from "./bbac/BBAC";
import BEC from "./bec/BEC";
import CEC from "./cec/CEC";
import Members from "./members/Members";
import NC from "./nc/NC";
import NEC from "./nec/NEC";
import NOB from "./nob/NOB";
import Overview from "./overview/Overview";
import RBC from "./rbc/RBC";
import REC from "./rec/REC";
import SSC from "./ssc/SSC";
import "./Structure.scss";
import StructureNav from "./StructureNav";

const Structure = () => {
  const [contentName, setcontentName] = useState("#overview");
  const { hash } = useLocation();

  const isActive = (nav: string) => (nav === contentName ? " active" : "");

  useEffect(() => {
    if (!hash) {
      setcontentName("#overview");
      return;
    }
    window.scrollTo(0, 0);
    setcontentName(hash);
  }, [hash]);

  return (
    <div className="structure uk-section">
      <div className="container uk-container">
        <SectionHeading
          heading="Our Structure"
          subheading="The committee consists of elected board leaders and acts as the steering committee for the full board."
        />

        <div className="uk-grid uk-child-width-1-3@s uk-child-width-1-4@m">
          <div>
            <StructureNav isActive={isActive} />
          </div>

          <div className="uk-width-expand">
            {contentName === "#overview" && <Overview />}
            {contentName === "#aos" && <AOS />}
            {contentName === "#nc" && <NC />}
            {contentName === "#cec" && <CEC />}
            {contentName === "#nec" && <NEC />}
            {contentName === "#nob" && <NOB />}
            {contentName === "#rbc" && <RBC />}
            {contentName === "#rec" && <REC />}
            {contentName === "#bbac" && <BBAC />}
            {contentName === "#bec" && <BEC />}
            {contentName === "#ssc" && <SSC />}
            {contentName === "#members" && <Members />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Structure;
