export const defaultEducationPost: IEducationPost = {
  id: "",
  title: "",
  content: [""],
};

export default interface IEducationPost {
  id: string;
  title: string;
  content: string[];
}
