import React from "react";
import NavBar from "./NavBar";
import "./Main.scss";
import AdminRouting from "../AdminRouting";

const Main = () => {
  return (
    <div className="main">
      <NavBar />
      <div className="main-content">
        <AdminRouting />
      </div>
    </div>
  );
};

export default Main;
