import React from "react";

const Overview = () => {
  return (
    <div className="overview section-structure">
      <h6 className="title">NAFAU Structure</h6>
      <img
        data-aos="zoom-in"
        data-aos-delay="300"
        className="overview-img"
        src={
          process.env.PUBLIC_URL + "/assets/governance/national-overview.png"
        }
        alt=""
      />
    </div>
  );
};

export default Overview;
