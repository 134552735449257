import React from "react";
import ApplicationsTable from "./ApplicationsTable";
import { useNavigate } from "react-router-dom";
import "./Applications.scss"

const Toolbar = () => {
  
  const navigate = useNavigate();
  const onNew = () => {
    navigate(`/members/apply`);
  };


  return (
    <div className="section-toolbar uk-margin">
      <div>
        <button className="kit-btn kit-btn-primary"
          onClick={onNew}>
          <span data-uk-icon="icon: plus-circle; ratio: .8">
          </span> New member
        </button>
      </div>
    </div>
  );
};

const MembershipApplications = () => {
  return (
    <div className="admin-membership-applications uk-section">
      <div className="container uk-container uk-container-large">
        <Toolbar />
        <ApplicationsTable />
      </div>
    </div>
  );
};

export default MembershipApplications;
