export const defaultNewsPost: INewsPost = {
  id: "",
  thumbnailUrl: "",
  heading: "",
  category: "General News",
  content: [""],
  date: new Date().toLocaleDateString(),
  dateInMillis: Date.now(),
  highlight: "",
};

export type INewsCategory = "Recognition agreements" | "General News" | "Vacancies" | "Disputes" | "Strikes" | "Events and ceremonies" | "Education";
export default interface INewsPost {
  id: string;
  thumbnailUrl: string;
  heading: string;
  category: INewsCategory;
  date: string;
  dateInMillis: number;
  highlight: string;
  content: string[];
}
