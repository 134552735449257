import { makeObservable, runInAction, toJS } from "mobx";
import INewsPost from "../interfaces/INewsPost";
import { NewsPost } from "../models/NewsPost";
import AppStore from "./AppStore";
import Store from "./Store";

export default class NewsStore extends Store<INewsPost, NewsPost> {
  items = new Map<string, NewsPost>();

  constructor(store: AppStore) {
    super(store);

    makeObservable(this, {
      items: true,
      selected: true,
    });

    this.store = store;
  }

  load(items: INewsPost[] = []) {
    runInAction(() => {
      items.forEach((item) => this.items.set(item.id, new NewsPost(item)));
    });
  }
}
