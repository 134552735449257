import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import NewsItemCard from "./NewsItemCard";
import "./news-highlight.scss";
import { observer } from "mobx-react-lite";
import { useAppContext } from "../../../shared/utils/Context";
import { useNavigate } from "react-router-dom";
import INewsPost from "../../../shared/interfaces/INewsPost";
import NewsPageSlider from "./NewsSlider";

interface IProps {
  isActive: (nav: string) => " active" | "";
}

export const CategoryNav = (props: IProps) => {
  const { isActive } = props;

  return (
    <ul
      className="news-nav uk-card uk-card-default"
      data-uk-sticky="bottom: #offset"
    >
      <li className={"news-nav-item" + isActive("#all")}>
        <a href="#all" className="news-nav-link">
          All
        </a>
      </li>
      <li className={"news-nav-item" + isActive("#general-news")}>
        <a href="#general-news" className="news-nav-link">
          General News
        </a>
      </li>
      <li className={"news-nav-item" + isActive("#disputes")}>
        <a href="#disputes" className="news-nav-link">
          Disputes
        </a>
      </li>
      <li className={"news-nav-item" + isActive("#strikes")}>
        <a href="#strikes" className="news-nav-link">
          Strikes
        </a>
      </li>
      <li className={"news-nav-item" + isActive("#recognition")}>
        <a href="#recognition" className="news-nav-link">
          Recognition Agreements
        </a>
      </li>
      <li className={"news-nav-item" + isActive("#vacancies")}>
        <a href="#vacancies" className="news-nav-link">
          Vacancies
        </a>
      </li>
      <li className={"news-nav-item" + isActive("#education")}>
        <a href="#education" className="news-nav-link">
          Education
        </a>
      </li>
      <li className={"news-nav-item" + isActive("#events")}>
        <a href="#events" className="news-nav-link">
          Events and Ceremonies
        </a>
      </li>
    </ul>
  );
};

const NewsHighlights = observer(() => {
  const [contentName, setContentName] = useState("#all");
  const { hash } = useLocation();
  const [loading, setLoading] = useState(false);

  const isActive = (nav: string) => (nav === contentName ? " active" : "");

  useEffect(() => {
    if (!hash) {
      setContentName("#all");
      return;
    }
    setContentName(hash);
  }, [hash]);

  const { api, store } = useAppContext();

  const navigate = useNavigate();

  const onView = (post: INewsPost) => {
    store.news.select(post);
    navigate(`./${post.id}`);
  };

  const loadFromDB = useCallback(async () => {
    setLoading(true);
    await api.news.loadAll();
    setLoading(false);
  }, [api.news]);

  useEffect(() => {
    loadFromDB();
  }, [loadFromDB]);

  const disputesNews = store.news.all.filter((dispute) => dispute.asJson.category === "Disputes");
  const generalNews = store.news.all.filter((general) => general.asJson.category === "General News");
  const strikeNews = store.news.all.filter((dispute) => dispute.asJson.category === "Strikes");
  const recognitionNews = store.news.all.filter((dispute) => dispute.asJson.category === "Recognition agreements");
  const vacanciesNews = store.news.all.filter((dispute) => dispute.asJson.category === "Vacancies");
  const eventsNews = store.news.all.filter((dispute) => dispute.asJson.category === "Events and ceremonies");
  const educationNews = store.news.all.filter((dispute) => dispute.asJson.category === "Education");

  return (
    <div className="news-section">
      <NewsPageSlider
        posts={store.news.all}
        onView={onView}
        loading={loading}
      />
      <div className="container uk-container uk-margin">
        <div className="uk-grid uk-child-width-1-3@s uk-child-width-1-4@m">
          <div className="nav-title">
            <h5>Categories</h5>
            <CategoryNav isActive={isActive} />
          </div>
          <div className="uk-width-expand">
            {contentName === "#all" && (
              <div
                className="uk-child-width-1-3@l uk-child-width-1-3@m uk-grid-small uk-grid-match"
                data-uk-grid
              >
                {store.news.size === 0 ? (
                  <p>
                    <span>No news</span>
                  </p>
                ) : (
                  <>
                    {store.news.all?.map((post: any) => (
                      <NewsItemCard
                        post={post}
                        contentHeight={5}
                        onView={onView}
                      />
                    ))}
                  </>
                )}
              </div>
            )}
            {contentName === "#general-news" && (
              <div className="uk-child-width-1-3@l uk-child-width-1-3@m uk-grid-small uk-grid-match" data-uk-grid >
                {generalNews.length === 0 ? (
                  <p>
                    <span>No General News</span>
                  </p>
                ) : (
                  <>
                    {generalNews.map((post) => (
                      <NewsItemCard
                        post={post}
                        contentHeight={5}
                        onView={onView}
                      />
                    ))}
                  </>
                )}
              </div>
            )}
            {contentName === "#disputes" && (
              <div className="uk-child-width-1-3@l uk-child-width-1-3@m uk-grid-small uk-grid-match" data-uk-grid >
                {disputesNews.length === 0 ? (
                  <p>
                    <span>No Disputes</span>
                  </p>
                ) : (
                  <>
                    {disputesNews.map((post) => (
                      <NewsItemCard
                        post={post}
                        contentHeight={5}
                        onView={onView}
                      />
                    ))}
                  </>
                )}
              </div>
            )}
            {contentName === "#strikes" && (
              <div className="uk-child-width-1-3@l uk-child-width-1-3@m uk-grid-small uk-grid-match"
                data-uk-grid
              >
                {strikeNews.length === 0 ? (
                  <p>
                    <span>No Strikes</span>
                  </p>
                ) : (
                  <>
                    {strikeNews.map((post) => (
                      <NewsItemCard
                        post={post}
                        contentHeight={5}
                        onView={onView}
                      />
                    ))}
                  </>
                )}
              </div>
            )}
            {contentName === "#recognition" && (
              <div
                className="uk-child-width-1-3@l uk-child-width-1-3@m uk-grid-small uk-grid-match"
                data-uk-grid
              >
                {recognitionNews.length === 0 ? (
                  <p>
                    <span>No Recognitions</span>
                  </p>
                ) : (
                  <>
                    {recognitionNews.map((post) => (
                      <NewsItemCard
                        post={post}
                        contentHeight={5}
                        onView={onView}
                      />
                    ))}
                  </>
                )}
              </div>
            )}
            {contentName === "#vacancies" && (
              <div
                className="uk-child-width-1-3@l uk-child-width-1-3@m uk-grid-small uk-grid-match"
                data-uk-grid
              >
                {vacanciesNews.length === 0 ? (
                  <p>
                    <span>No Vacancies</span>
                  </p>
                ) : (
                  <>
                    {vacanciesNews.map((post: any) => (
                      <NewsItemCard
                        post={post}
                        contentHeight={5}
                        onView={onView}
                      />
                    ))}
                  </>
                )}
              </div>
            )}
            {contentName === "#education" && (
              <div
                className="uk-child-width-1-3@l uk-child-width-1-3@m uk-grid-small uk-grid-match"
                data-uk-grid
              >
                {educationNews.length === 0 ? (
                  <p>
                    <span>No Education News</span>
                  </p>
                ) : (
                  <>
                    {educationNews.map((post: any) => (
                      <NewsItemCard
                        post={post}
                        contentHeight={5}
                        onView={onView}
                      />
                    ))}
                  </>
                )}
              </div>
            )}
            {contentName === "#events" && (
              <div
                className="uk-child-width-1-3@l uk-child-width-1-3@m uk-grid-small uk-grid-match"
                data-uk-grid
              >
                {eventsNews.length === 0 ? (
                  <p>
                    <span>No Events</span>
                  </p>
                ) : (
                  <>
                    {eventsNews.map((post: any) => (
                      <NewsItemCard
                        post={post}
                        contentHeight={5}
                        onView={onView}
                      />
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
export default NewsHighlights;
