import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IReportedIssue } from "../../../shared/interfaces/IReportedIssue";
import { useAppContext } from "../../../shared/utils/Context";
import IssueRow from "./IssueRow";

const IssuesTable = observer(() => {
  const { api, store } = useAppContext();
  const navigate = useNavigate();
  const [isEmpty, setIsEmpty] = useState(false);

  const onView = (issue: IReportedIssue) => {
    store.reportedIssues.select(issue);
    navigate(`./${issue.id}`);
  };

  const onDelete = async (id: string) => {
    if (!window.confirm("Do you want to delete?")) return;
    await api.reportedIssues.delete(id);
  };

  useEffect(() => {
    setIsEmpty(store.reportedIssues.size === 0);
    return () => { };
  }, [store.reportedIssues.size]);


  useEffect(() => {
    api.reportedIssues.loadAll();
    return () => { };
  }, []);

  return (
    <div className="table uk-card uk-card-default uk-card-body uk-card-small">
      {!isEmpty ? (
        <table className="uk-table uk-table-small uk-table-divider">
          <thead>
            <tr>
              <th>#</th>
              <th>Reported on</th>
              <th>Name</th>
              <th>Category</th>
              <th>Subject</th>
              <th className="uk-text-right">Actions</th>
            </tr>
          </thead>

          <tbody>
            {store.reportedIssues.all.map((issue, index) => (
              <tr key={issue.id}>
                <IssueRow
                  index={index}
                  issue={issue}
                  onView={onView}
                  onDelete={onDelete}
                />
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-issues">
          <p className="uk-text-center">
            No reported issues
          </p>
        </div>
      )}
    </div>
  );
});

export default IssuesTable;
