import { makeObservable, runInAction } from "mobx";
import { IMemberApplication } from "../interfaces/IMemberApplication";
import { MemberApplication } from "../models/MemberApplication";
import AppStore from "./AppStore";
import Store from "./Store";

export default class MemberApplicationStore extends Store<
    IMemberApplication,
    MemberApplication

> {
    items = new Map<string, MemberApplication>();

    constructor(store: AppStore) {
        super(store);

        makeObservable(this, {
            items: true,
            selected: true,
        });

        this.store = store;
    }

    load(items: IMemberApplication[] = []) {
        runInAction(() => {
            items.forEach((item) => this.items.set(item.id, new MemberApplication(item)));
        });
    }
}
