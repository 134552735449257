import { makeAutoObservable } from "mobx";
import { IAttachment, IReportedIssue } from "../interfaces/IReportedIssue";

export class ReportedIssue implements IReportedIssue {
  id: string;
  reportedOn: string;
  reportedOnMillis: number;
  reporter: string;
  cellphone: string;
  companyName: string;
  nearestBranch: string;
  category: string;
  subject: string;
  message: string;
  files: IAttachment[];
  idnumber: string;

  constructor(issue: IReportedIssue) {
    makeAutoObservable(this);
    this.id = issue.id;
    this.reportedOn = issue.reportedOn;
    this.reportedOnMillis = issue.reportedOnMillis;
    this.reporter = issue.reporter;
    this.category = issue.category;
    this.cellphone = issue.cellphone;
    this.companyName = issue.companyName;
    this.nearestBranch = issue.nearestBranch;
    this.subject = issue.subject;
    this.message = issue.message;
    this.files = issue.files;
    this.idnumber = issue.idnumber;
  }

  get asJson(): IReportedIssue {
    return {
      id: this.id,
      reportedOn: this.reportedOn,
      reportedOnMillis: this.reportedOnMillis,
      reporter: this.reporter,
      cellphone: this.cellphone,
      companyName: this.companyName,
      nearestBranch: this.nearestBranch,
      category: this.category,
      subject: this.subject,
      message: this.message,
      files: this.files,
      idnumber: this.idnumber,
    };
  }
}
