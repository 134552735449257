export const defaultReportedIssue: IReportedIssue = {
  id: "",
  reportedOn: new Date().toLocaleDateString(),
  reportedOnMillis: Date.now(),
  reporter: "",
  category: "",
  subject: "",
  message: "",
  files: [],
  cellphone: "",
  companyName: "",
  nearestBranch: "",
  idnumber: "",
};

export interface IAttachment {
  name: string;
  link: string;
}

export interface IReportedIssue {
  id: string;
  reportedOn: string;
  reportedOnMillis: number;
  reporter: string;
  cellphone: string;
  companyName: string;
  nearestBranch: string;
  category: string;
  subject: string;
  message: string;
  files: IAttachment[];
  idnumber: string;
}
