import React from "react";

const RBC = () => {
  return (
    <div className="rbc section-structure">
      <h6 className="title">Regional Bi-annual Conference (RBC)</h6>

      <div className="content">
        <h6 className="sub-title">Regional Conference</h6>
        <p className="description">
          The National Executive Committee may establish and demarcate the
          region of the union in areas where four or more branches have been
          established in accordance with the provision of this constitution.
        </p>
        <hr />
        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>
                    The Regional Executive Committee consists of the following
                  </p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">Regional Chairperson</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Deputy Regional Chairperson</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Regional Secretary</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Deputy Regional Secretary</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Regional Treasure</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Deputy Regional Treasure</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    One office-bearer from each Branch Executive Committee in the
                    region
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <p className="sub-title">
        The Regional Executive Committee shall convene a Regional Bi-annual
        Conference (RBC) in the established regions every second year.
      </p>
      <div className="uk-grid-match" data-uk-grid>
        <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
          <div className="uk-card uk-card-default uk-card-body card-title">
            <div className="title">
              <div>
                <p>
                  The composition of the RBC shall be as follows:
                </p>
              </div>
              <div className="line-div">
                <div className="line"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-width-expand@m">
          <div className="uk-card uk-card-default uk-card-body card-content">
            <ol className="list">
              <li className="list-item">
                <p className="list-item-text">
                  All members of the Regional Executive Committee (REC) each branch
                  shall be entitled to three delegates.
                </p>
              </li>
              <li className="list-item">
                <p className="list-item-text">
                  At the RBC, the NOB the National Coordinator, the Regional
                  Coordinator shall have no voting rights as well as all branch
                  organizers serving at different branches of NAFAU.
                </p>
              </li>
              <li className="list-item">
                <p className="list-item-text">
                  At the RBC, the progress of activities of all branches in a region
                  shall be discussed and evaluated after being tabled by the
                  Regional Coordinator.
                </p>
              </li>
              <li className="list-item">
                <p className="list-item-text">
                  The delegates shall elect by ballot.
                </p>
              </li>
              <li className="list-item">
                <p className="list-item-text">
                  The RBC shall consider and adopt reports on the activities of the
                  REC.
                </p>
              </li>
              <li className="list-item">
                <p className="list-item-text">
                  The RBC shall be responsible for meeting the aims and objectives
                  of the union with regard to the organization of workers in the
                  region.
                </p>
              </li>
              <li className="list-item">
                <p className="list-item-text">
                  The quorum of the RBC shall be a simple majority of the members in
                  good standing or a simple majority of branches in the region
                  thereof.
                </p>
              </li>
              <li className="list-item">
                <p className="list-item-text">
                  The regional chairperson shall preside at all the RBC and in his
                  or her absence, the Deputy Chairperson shall preside over such
                  meeting.
                </p>
              </li>
              <li className="list-item">
                <p className="list-item-text">
                  All people serving under the REC shall be eligible for re-election
                  and decisions at the regional conference shall be taken by a
                  simple majority vote.
                </p>
              </li>
              <li className="list-item">
                <p className="list-item-text">
                  The Regional Secretary shall take minutes during the proceedings
                  of the RBC.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RBC;
