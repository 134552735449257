import {
  onSnapshot,
  doc,
  collection,
  deleteDoc,
  setDoc,
  getDoc,
  query,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { db } from "../utils/firebaseConfig";
import IEducationPost from "../interfaces/IEducationPost";
import AppStore from "../stores/AppStore";

const $collection = "union101";

class EducationApi {
  private colRef = collection(db, $collection);

  constructor(private store: AppStore) {}

  // Load all
  loadAll() {
    return onSnapshot(
      this.colRef,
      (snapshot) => {
        const posts: IEducationPost[] = [];
        snapshot.forEach((doc) => {
          posts.push({ id: doc.id, ...doc.data() } as IEducationPost);
        });

        this.store.education.load(posts);
      },
      (error) => {
        // handle error
        // console.log("Error loading...");
      }
    );
  }

  // Load by id
  async loadById(id: string) {
    const $doc = await getDoc(doc(this.colRef, id));

    if (!$doc.exists()) return;

    const posts = [{ id: $doc.id, ...$doc.data() } as IEducationPost];
    this.store.education.load(posts);
  }

  // Update
  create(post: IEducationPost) {
    const $post = { ...post };
    if (!$post.id) $post.id = doc(this.colRef).id;

    return setDoc(doc(this.colRef, $post.id), $post, { merge: true });
    // timestamp: serverTimestamp()
  }

  // Delete
  async delete(id: string) {
    await deleteDoc(doc(this.colRef, id));
    this.store.education.remove(id);
  }
}

export default EducationApi;
