import {
  onSnapshot,
  doc,
  collection,
  deleteDoc,
  setDoc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../utils/firebaseConfig";
import { IMemberApplication } from "../interfaces/IMemberApplication";
import AppStore from "../stores/AppStore";

const $collection = "nafauMembers";

class MemberApplicationsAPi {
  private colRef = collection(db, $collection);

  constructor(private store: AppStore) {}
  // Load all
  async loadAll() {
    const $query = query(
      collection(db, $collection),
      orderBy("applicationDate")
    );
    const querySnapshot = await getDocs($query);
    const members = querySnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id } as IMemberApplication;
    });
    this.store.memberApplications.load(members);
  }

  // Load by id
  async loadById(id: string) {
    const $doc = await getDoc(doc(this.colRef, id));
    if (!$doc.exists()) return;
    const members = [{ id: $doc.id, ...$doc.data() } as IMemberApplication];
    this.store.memberApplications.load(members);
  }

  // Update
  // create(member: IMemberApplication) {
  //     const $member = { ...member };
  //     if (!$member.id) $member.id = doc(this.colRef).id;
  //     return setDoc(doc(this.colRef, $member.id), $member, { merge: true });
  // }
  create(member: IMemberApplication) {
    const $member = { ...member };
    // $member.id = doc(this.colRef).id;
    if (!$member.id) $member.id = doc(this.colRef).id;
    return setDoc(doc(this.colRef, $member.idNumber), $member, { merge: true });
  }

  // Delete
  async delete(id: string) {
    await deleteDoc(doc(this.colRef, id));
    this.store.memberApplications.remove(id);
  }
}

export default MemberApplicationsAPi;
