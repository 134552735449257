import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  signOut,
  deleteUser,
  onAuthStateChanged,
  User,
  sendPasswordResetEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from "firebase/auth";
import {
  onSnapshot,
  doc,
  collection,
  deleteDoc,
  setDoc,
  getDoc,
  query,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { db, auth, authWorker } from "../utils/firebaseConfig";
import { IUser } from "../interfaces/IUser";
import AppStore from "../stores/AppStore";

const $collection = "users";

class UserApi {
  private colRef = collection(db, $collection);
  constructor(private store: AppStore) {
    this.onAuthChanged();
  }

  onAuthChanged() {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in.
        await this.onSignedIn(user.uid);
      } else {
        // User is signed out
        await this.onSignedOut();
      }
    });
  }

  private async onSignedIn(uid: string) {
    this.store.users.loading = true;

    // Get user doc
    const userDocSnap = await getDoc(doc(this.colRef, uid));

    // Don't authorize if user doesn't exists.
    if (!userDocSnap.exists()) {
      // console.log('User dont exist');

      this.store.users.loading = false;
      return;
    }
    // console.log('User Found!');

    const user = { uid: userDocSnap.id, ...userDocSnap.data() } as IUser;
    this.store.users.loadCurrentUser(user);
    this.store.users.loading = false;
  }

  private async onSignedOut() {
    this.store.users.loading = true;
    this.store.users.removeCurrentUser();
    this.store.users.loading = false;
  }

  // User will be created with authWorker, thus not signed-in
  async createUser(user: IUser) {
    const { email, password } = user;
    const userCredential = await createUserWithEmailAndPassword(
      authWorker,
      email,
      password
    ).catch((error) => {
      return null;
    });

    if (userCredential) {
      user.uid = userCredential.user.uid;
      await setDoc(doc(db, $collection, user.uid), user);
      await signOut(authWorker);
    }
    return user;
  }

  // Load by id
  async loadById(id: string) {
    const $doc = await getDoc(doc(this.colRef, id));
    if (!$doc.exists()) return;
    const users = [{ uid: $doc.id, ...$doc.data() } as IUser];
    this.store.users.load(users);
  }
  // Update user info
  async updateUser(user: IUser) {
    await setDoc(doc(this.colRef, user.uid), user);
    return user;
  }

  // Delete
  async delete(id: string) {
    await deleteDoc(doc(this.colRef, id));
    this.store.users.remove(id);
  }
  //   User will be created and signed-in
  async onSignup(user: IUser) {
    const { email, password = `${"nafau@"}${user.branch}` } = user;
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    ).catch((error) => {
      // console.log("Error creating a user =>", error)
      return null;
    });

    if (userCredential) {
      user.uid = userCredential.user.uid;
      await setDoc(doc(this.colRef, user.uid), user);
    }
    return userCredential;
  }

  async signIn(email: string, password: string) {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        return signInWithEmailAndPassword(auth, email, password);
      })
      .catch((error) => {
        return null;
      });

    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    ).catch((error) => {
      return null;
    });

    if (userCredential) return userCredential.user;
    return userCredential;
  }

  async signOutUser() {
    signOut(auth);
  }

  async removeUser(user: User) {
    await deleteUser(user);
    await this.deleteUserFromDB(user.uid);
    return;
  }

  async passwordResetWithEmail(email: string) {
    await sendPasswordResetEmail(auth, email)
      .then(function () {
        alert("Password reset email sent.");
      })
      .catch(function (error) {
        // An error happened.
        alert("Could not send email.");
      });
  }

  async passwordResetWithOldPassword(
    email: string,
    oldPassword: string,
    newPassword: string
  ) {
    const credential = EmailAuthProvider.credential(email, oldPassword);
    const user = auth.currentUser;
    if (!user) return;
    await reauthenticateWithCredential(user, credential)
      .then(() => {
        if (newPassword.length >= 6)
          // User re-authenticated.
          updatePassword(user, newPassword)
            .then(function () {
              // Update successful.
              alert("Password reset successfully");
            })
            .catch(function (error) {
              // An error happened.
              alert("Could not reset password");
            });
        else alert("Password should be atleast 6 characters long");
      })
      .catch((error) => {
        // An error happened.
        alert("Incorrect password");
      });
  }

  async loadUser(uid: string) {
    const docRef = doc(this.colRef, uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const user = { ...docSnap.data(), uid: docSnap.id } as IUser;
      this.store.users.load([user]);
      return user;
    } else return undefined;
  }

  async deleteUserFromDB(uid: string) {
    const docRef = doc(this.colRef, uid);
    await deleteDoc(docRef);
  }

  async loadAll() {
    this.store.users.removeAll();
    const $query = query(collection(db, "users"), orderBy("fullName"));
    const querySnapshot = await getDocs($query);
    const users = querySnapshot.docs.map((doc) => {
      return { ...doc.data(), uid: doc.id } as IUser;
    });
    this.store.users.load(users);
  }
}

export default UserApi;
