import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoggedIn from "./logged-in/LoggedIn";
import LoggedOut from "./logged-out/LoggedOut";
import Home from "./logged-out/home/Home";
import AppApi from "./shared/apis/AppApi";
import AppStore from "./shared/stores/AppStore";
import UiStore from "./shared/stores/UiStore";
import {
  AppContext,
  DeviceWidthContext,
  useAppContext,
} from "./shared/utils/Context";
import ScrollToTop from "./shared/NavSrcollTop/NavScrollTop";
import AOS from "aos";
import "aos/dist/aos.css";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "./srcassets/scss/style.scss";
import "./App.scss";
import SwiperCore, { Navigation, Autoplay } from "swiper";

export const MainPage = () => {
  return (
    <Routes>
      <Route path="/*" element={<LoggedOut />}>
        <Route index element={<Home />} />
      </Route>
    </Routes>
  );
};

export const AdminPage = () => {
  return (
    <Routes>
      <Route path="/a/*" element={<LoggedIn />}>
        <Route path="*" element={<LoggedIn />} />
      </Route>
      <Route path="/*" element={<LoggedOut />}>
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  );
};

export const PrivateContent = observer(() => {
  const { store } = useAppContext();
  const currUser = store.users.currentUser;
  if (currUser) return <AdminPage />;
  else return <MainPage />;
});

function App() {
  const store = new AppStore();
  const api = new AppApi(store);
  const ui = new UiStore();

  SwiperCore.use([Navigation, Autoplay]);

  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 800,
      once: true,
      easing: "ease-in",
    });
    AOS.refresh();
  }, []);

  return (
    <div className="app">
      <DeviceWidthContext.Provider value={0}>
        <AppContext.Provider value={{ store, api, ui }}>
          <BrowserRouter>
            <ScrollToTop />
            <PrivateContent />
          </BrowserRouter>
        </AppContext.Provider>
      </DeviceWidthContext.Provider>
    </div>
  );
}

export default App;
