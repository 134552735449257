import React from "react";

const NC = () => {
  return (
    <div className="nc section-structure">
      <h6 className="title">National Congress</h6>
      <div className="content">
        <p className="description">
          The National Congress (NC) is the highest authority and policy making
          body of NAFAU. It sets the policies by means of voting, resolution,
          review and assesses the overall activities and development of NAFAU
          between congresses.
        </p>
        <hr />
        <h6 className="sub-title">Composition</h6>
        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>The National Congress shall comprise the following:</p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">
                    All members of the Central Executive Committee (CEC).
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Each region shall be entitled to one (1) delegate for each
                    hundred (one hundred) members, provided that all delegates
                    are members in good standing and depending on the financial
                    position of NAFAU.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    All delegates shall be NAFAU members in good standing and
                    compliant with applicable criteria which are determined by
                    NC, CEC, or NEC.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Notwithstanding the above, the NEC may determine the maximum
                    number of delegates to the NC and make recommendations to
                    the CEC.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <hr />
        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>Areas of authority</p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">
                    The NC shall evaluate the work of the CEC by considering,
                    adopting, amending, or rejecting its reports and
                    recommendations.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The NC shall have the power to confirm, amend, repudiate, or
                    revoke any decision made by any organ of NAFAU.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The NEC shall be responsible for appointing or dismissing
                    the National Office Bearers.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The NC shall approve the annual financial reports of NAFAU
                    as presented by the CEC.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The NC shall determine the overall future Strategic Plan/
                    program of the organization, CEC and NEC may determine the
                    plan/ program between congresses depend on the situation.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The NC shall adopt or change the agenda recommended by the
                    Central Executive Committee (CEC).
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    NC shall have the power to confirm, amend, repudiate, or
                    revoke any resolution taken or decision made on affiliation
                    in or outside Namibia.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The NC shall have the power to amend, repudiate or revoke
                    any organ of NAFAU.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Elect the forty-five members of the Central Executive
                    Committee (CEC), from among the members of the Central
                    Executive Committee, twenty members shall form a National
                    Executive Committee (NEC).
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <hr />

        <h6 className="sub-title">Quorum of Congress</h6>
        <p className="description">
          The quorum of the NC shall be at least two-thirds (2/3) of the
          regions, provided that each region is represented by at least
          one-third of its delegates consisting of all members in good standing.
          If the quorum is not formed within two (2) hours from the time
          specified in the written notice, such an NC shall stand adjourned for
          six (6) weeks and not more than six (6) months. At such an adjourned
          NC of which notice shall be given in the usual way, the delegate
          present shall constitute the quorum and conduct the business of the
          NC.
        </p>

        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>Meetings of the National Congress</p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">
                    The NC ordinarily meets at least every five (5) years on a
                    month to be decided by the CEC.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Notice for the Convention of the NC shall be conveyed by the
                    General Secretary in consultation with the President to the
                    Branch Executive (BEC) and the Regional Executive Committee
                    (REC) at least two months before the date of the proposed
                    convention.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The proposed congress resolution and the constitutional
                    amendments from the branches and the regions shall be sent
                    to the General Secretary not later than 30 days prior to the
                    NC copies of the agenda to be distributed to all the BEC and
                    REC by the General Secretary and shall reach the
                    above-referred structures not less than ten (10) days to the
                    NC. The congress resolution shall be adopted by most of the
                    congress delegates with voting rights.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    A special NC shall be called by the president upon a written
                    request of not less than two thirds (2/3) of the regions or
                    twelve branches with a total of (seven thousand five
                    hundred) 7500 members in good standing.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The NEC will propose the names of candidates for National
                    Office Bearer (NOB) to the branches and regions to accept or
                    propose other candidates which will be the position of
                    structures as a final list of candidates to the NC.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Each branch and region shall nominate one candidate for each
                    NOB position and shall be confirmed in writing to the
                    General Secretary at least four (4) weeks before the NC.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Upon structures, in terms of this constitution pronouns
                    themselves, those decisions should be respected by whoever
                    is in the structure/s or employment of the union.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    If the Congress is not able to take place or is postponed
                    due to a natural disaster/ vis major or for valid financial
                    reasons, the NEC shall make a recommendation to CEC for the
                    Congress to be postponed. During that period, the term of
                    the CEC, NEC and NOB Members in office at that stage shall
                    run until the next Congress. Thereafter, a Congress must be
                    convened.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <h6 className="sub-title">Elections of the Congress</h6>
        <p className="description">The National Congress shall elect:</p>
        <p className="description">
          The forty-five (45) members of the Central Executive Committee (CEC)
          nine (9) members per region from among the members of CEC and the NC
          shall elect twenty (20) members to serve on the NEC, comprised of four
          (4) members per region.
        </p>

        <h6 className="sub-title">National Congress on Office Bearers</h6>
        <p className="description">
          The members of the National Congress shall elect six (6) office
          bearers with voting rights and two (2) without voting rights to the
          following portfolio:
        </p>
        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>The Office Bearers</p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">President</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Deputy President</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">National Treasurer</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Deputy National Treasurer</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    General Secretary – No voting right
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Deputy General Secretary – No voting right
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NC;
