import React from "react";

const NEC = () => {
  return (
    <div className="nec section-structure">
      <h6 className="title">National Executive Committee (NEC)</h6>

      <div className="content">
        <p className="description">
          The NEC shall be the highest administrative authority and development
          organ in between the CEC meetings, and shall be responsible for
          implementing all decisions, resolutions, and directives of the CEC.
        </p>
        <p className="description">
          The NEC shall be accountable to the CEC on its work and activities as
          well as on that of the office of the General Secretary.
        </p>
        <p className="description">
          The NEC shall take decisions on a simple majority vote whereby ten
          (10) members of the NEC shall constitute the quorum.
        </p>
        <p className="description">
          The NEC shall meet four times annually or every two months and a
          member who fails to be present at three consecutive meetings without
          any valid reason shall immediately cease from being a member of the
          NEC. Any vacancy on the NEC shall be filled in accordance with the
          decision of the NEC.
        </p>
        <p className="description">
          The General Secretary with the agreement of the President shall
          convene extraordinary meetings of the NEC on a (twenty-four) 24-hour
          verbal notice, which should be given to each member of the NEC
          personally.
        </p>
        <p className="description">
          The meetings of the NEC shall be attended by the NEC members only.
        </p>

        <hr />

        <h6 className="sub-title">
          The Power of the National Executive Committee (NEC)
        </h6>
        <p className="description">
          NEC has mandated the General Secretary and/or Deputy General Secretary
          to institute, conduct, defend and abandon any legal proceeding by and
          against NAFAU and or its elected office bearers and officials.
        </p>
        <p className="description">
          To accept/reject recommendations to appoint employees on permanent
          employment.
        </p>
        <p className="description">
          NEC mandated NOB to borrow, raise and invest funds on behalf of NAFAU.
        </p>
        <p className="description">
          To accept/reject the proposed allocate float amounts and other funds
          to the branches, regions and national.
        </p>
        <p className="description">
          To consider reports from the General Secretary and give directives
          where necessary.
        </p>
        <p className="description">
          In urgent circumstances on behalf of the union, the General Secretary
          and/or Deputy General Secretary may institute or defend proceedings
          against the union, its members, and its staff, which can subsequently
          be ratified by the NEC.
        </p>
        <p className="description">
          NEC mandate NOB to open and close bank accounts on behalf of the union
          and to control the funds and finance of the union.
        </p>
        <p className="description">
          NEC mandate NOB to acquire by purchase, lease properties, hire and
          establish the businesses on behalf of the union.
        </p>
        <p className="description">
          To sell and exchange lease or mortgage or any of the union’s assets.
        </p>
        <p className="description">
          To appoint an acting President, acting General Secretary, acting
          Deputy General Secretary or National Treasure should the Deputy
          President and the Deputy National Treasurer respectively been unable
          to carry out their function.
        </p>
        <p className="description">
          To make amendments and re-appeal by-laws not consistent with this
          constitution to regulate the union's affairs, including but not
          limited to matters such as:
        </p>
        <hr />
        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p> NCE Procedures</p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">Election procedures</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Ballot procedures</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Inquiry procedures</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Meeting procedures</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Union discipline</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">Imposition of fines forfeitures</p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Special duties of the office bearers and officials
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The NEC is empowered to carry out all relevant administrative
                    activities in order to carry out its functions.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <hr />

        <h6 className="sub-title">Vacating of office by NEC members</h6>
        <p className="description">
          A member of the NEC shall vacate his/her seat in any of the following
          circumstances:
        </p>

        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>Vacating circumstances</p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">
                    On his/her resignation, suspension, or expulsion from being a
                    member of the union.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Upon absenting him/herself from three consecutive meetings of the
                    NEC meeting without notice to the General Secretary.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Upon ceasing to be a member in good standing. As a consequence of
                    a ballot to remove an office bearer from the office as provided
                    for in this constitution.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Every replacement shall be done upon the recommendation of the
                    management committee.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>


        <hr />

        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>Filling of Vacant NEC Position</p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">
                    A NEC vacancy can be caused by the death, resignation, dismissal
                    or or in the event of a member no longer falling under the scope
                    of NAFAU.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The Central Executive Committee shall fill the vacant position in
                    consultation with the General Secretary.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The Central Executive Committee of the region where such vacancy
                    has occurred must hold elections to elect amongst the Central
                    Executive Committee the member to fill the vacant position at the
                    NEC.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NEC;
