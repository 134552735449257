import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const config = {
  apiKey: "AIzaSyDsNEpRUKukCL-uIJiRsKgW04VEODP8yRE",
  authDomain: "namibia-food-and-allied.firebaseapp.com",
  projectId: "namibia-food-and-allied",
  storageBucket: "namibia-food-and-allied.appspot.com",
  messagingSenderId: "30506164931",
  appId: "1:30506164931:web:26e81214d5ec6a21bd3876",
  measurementId: "G-867PQW6PDF",
};

// Initialize Firebase
const app = initializeApp(config);
const authworkerApp = initializeApp(config, "authWorker"); // authWorker

export const db = getFirestore(app);
export const auth = getAuth(app);
export const authWorker = getAuth(authworkerApp); //authworkerApp

export const storage = getStorage(app);
export const analytics = getAnalytics(app);

export default app;
