import { useCallback, useEffect, useState, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../../shared/utils/Context";
import INewsPost, {
  defaultNewsPost,
} from "../../../shared/interfaces/INewsPost";
import { observer } from "mobx-react-lite";
import IntroSection from "../../../shared/Intro/IntroSection";
import "./readnews.scss";

interface IParagraphs {
  index: number;
  paragraph: string;
}
const Paragraphs = (props: IParagraphs) => {
  const { index, paragraph } = props;
  return (
    <div className="paragraph" key={index}>
      <div className="uk-form-controls">
        <p className="post-paragragh" style={{ whiteSpace: "break-spaces" }}>
          {paragraph}
        </p>
      </div>
    </div>
  );
};

interface IProps {
  paragraphs: string[];
}
const NewsParagraphs = (props: IProps) => {
  const { paragraphs } = props;

  return (
    <div className="paragraphs">
      {paragraphs?.map((paragraph, index) => (
        <Fragment key={index}>
          <Paragraphs paragraph={paragraph} index={index} />
        </Fragment>
      ))}
    </div>
  );
};

const ReadNews = observer(() => {
  const { api, store } = useAppContext();
  const { postId } = useParams();

  const navigate = useNavigate();
  const [post, setPost] = useState<INewsPost>({
    ...defaultNewsPost,
  });
  const [paragraphs, setParagraphs] = useState([""]);
  const [src, setSrc] = useState("");

  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onBack = () => {
    navigate("/news");
  };
  const loadPost = ($post: INewsPost) => {
    setPost($post);
    setSrc($post.thumbnailUrl);
    setParagraphs($post.content);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadFromDB = async (id: string) => {
    try {
      setLoading(true);
      await api.news.loadById(id);
      setLoading(false);

      const $post = store.news.items.get(id);
      if ($post) loadPost($post);
      else onBack();
    } catch (error) {
      onBack();
    }
  };

  const loadExistingPost = useCallback(() => {
    if (!store.news.selected) {
      if (postId) loadFromDB(postId);
      else onBack();
    } else {
      loadPost(store.news.selected);
    }
  }, [loadFromDB, onBack, postId, store.news.selected]);

  const loadNewPost = useCallback(() => { }, []);

  useEffect(() => {
    if (postId === "new") loadNewPost();
    else loadExistingPost();

    return () => { };
  }, [loadExistingPost, loadNewPost, postId]);

  //

  const scope = [
    {
      id: "1",
      title: `${post.heading}`,
      desc: ``,
      backgroundImage: `${post.thumbnailUrl}`,
    },
  ];

  return (
    <div className="read-news-post">
      <div className="uk-section news-back" style={{ backgroundImage: `url(${post.thumbnailUrl})` }} ></div>
      <div className="uk-section">
        <div className="uk-container uk-container-small">
          <h1 className="uk-heading-small"> {post.heading}</h1>
          <div className="uk-card uk-card-default uk-card-small uk-card-body uk-margin">
            <div className="uk-grid-small uk-child-width-1-1@s">
              <div className="uk-margin">
                <div className="controls">
                  <button
                    className="btn bck-btn"
                    type="button"
                    onClick={onBack}
                  >
                    Back
                  </button>
                </div>
                <div className="uk-space-between">
                  <div>
                    <p className="highlight">{post.highlight}</p>
                  </div>
                  <div className="date">
                    <p>Date :{post.date}</p>
                    <p>
                      Tag :<span>{post.category}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-card uk-card-default uk-card-small uk-card-body uk-margin">
            <NewsParagraphs paragraphs={paragraphs} />
            <div className="uk-text-right">
              <div className="controls">
                <button className="btn bck-btn" type="button" onClick={onBack}>
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ReadNews;
