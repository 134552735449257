import SectionHeading from "../../../shared/components/section-heading/SectionHeading";
import "./History.scss";
import IntroSection from "../../../shared/Intro/IntroSection";
import Timeline from "./Timeline";

const History = () => {
  const title = [
    {
      id: "1",
      title: "History of NAFAU",
      desc: `VIVA NAFAU!: The History and Achievements of the Namibian Food and Allied Workers Union.`,
      backgroundImage:
        process.env.PUBLIC_URL + "/assets/background/landing5.jpg",
    },
  ];

  return (
    <div className="history">
      <IntroSection data={title} />
      <div className="uk-section">
        <div className="history-container uk-container">
          <Timeline />
        </div>
      </div>
    </div>
  );
};

export default History;
