import React from "react";
import { useNavigate } from "react-router-dom";
import IEducationPost from "../../../shared/interfaces/IEducationPost";
import { useAppContext } from "../../../shared/utils/Context";
import EditDeleteButtons from "../../shared/components/edit-delete-buttons/EditDeleteButtons";

interface IProps {
  post: IEducationPost;
  onView: (post: IEducationPost) => void;
  onDelete: (id: string) => Promise<void>;
}

const Union101Item = (props: IProps) => {
  const { api, store } = useAppContext();

  const { post } = props;
  const { title, content } = post;

  const navigate = useNavigate();

  const onView = () => {
    store.education.select(post);
    navigate(`./${post.id}`);
  };

  const onDelete = () => {
    if (!window.confirm("Do you want to delete?")) return;
    props.onDelete(post.id);
  };

  return (
    <div className="union-101-item uk-card uk-card-default uk-card-small uk-card-body uk-margin">
      <h5 className="title">{title}</h5>
      {content.map((paragraph, index) => (
        <p className="description" key={index}>
          {paragraph}
        </p>
      ))}
      <EditDeleteButtons onView={onView} onDelete={onDelete} />
    </div>
  );
};

export default Union101Item;
