import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import Icons from "../background/Icons";
import "./Analytics.scss";

const TractionAnalytics = () => {
  return (
    <div className="traction-analytics uk-section">
      <div className="container uk-container uk-text-center">
        <div
          className="uk-grid uk-grid-match uk-child-width-1-4@s"
          data-uk-grid
          data-aos="fade-in"
          data-aos-delay="100"
        >
          <div>
            <div className="analytic-card uk-flex-center">
              <Icons.MemberIcon
                className="icon"
                fill="none"
                height={80}
                width={80}
              />
              <CountUp end={11304} redraw={true} suffix="+">
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span className="quantity" ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
              <p className="label">Active Members</p>
            </div>
          </div>

          <div>
            <div className="analytic-card uk-flex-center">
              <Icons.RecognitionIcon
                className="icon"
                fill="none"
                height={80}
                width={80}
              />
              <CountUp end={160} redraw={true} suffix="+">
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span className="quantity" ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
              <p className="label">Recognition Agreements</p>
            </div>
          </div>

          <div>
            <div className="analytic-card uk-flex-center">
              <Icons.BranchIcon
                className="icon"
                fill="none"
                height={80}
                width={80}
              />
              <CountUp end={23} redraw={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span className="quantity" ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
              <p className="label">Branches Countrywide</p>
            </div>
          </div>
          <div>
            <div className="analytic-card uk-flex-center">
              <Icons.CasesIcon
                className="icon"
                fill="none"
                height={80}
                width={80}
              />
              <CountUp end={75} redraw={true} suffix="%">
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span className="quantity" ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
              <p className="label">Case Winning Rate</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TractionAnalytics;
