import { observer } from "mobx-react-lite";
import moment from "moment";
import React, {
  FormEvent,
  useEffect,
  useState,
} from "react";
import {
  IMemberApplication,
  defaultMembers,
} from "../../../../shared/interfaces/IMemberApplication";
import { useAppContext } from "../../../../shared/utils/Context";
import Tabs from "./Tabs";

interface IPersonalProps {
  member: IMemberApplication;
  setMember: React.Dispatch<React.SetStateAction<IMemberApplication>>;
  loading: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const ApplicationForm = (props: IPersonalProps) => {
  const { member, setMember, loading, onSubmit } = props;

  const deductionAmount = (member.basicSalary * (1.3 / 100));

  function NumberFormat(number: number) {
    return number.toLocaleString(navigator.language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  // date of employement limiter
  const date = moment().format("YYYY-MM-DD");

  // let today = new Date();
  // const minBirthYear = currentYear - 16;

  const _year = (new Date().getFullYear() - 16);
  const date1 = moment().format(`${_year}-MM-DD`);

  return (
    <form className="uk-form-stacked" onSubmit={onSubmit}>
      <div
        className="personal-details uk-form-stacked uk-grid-small uk-child-width-1-1"
        data-uk-grid
      >
        <div className="uk-width-1-2@s">
          <label className="uk-form-label required" htmlFor="member-surname">
            Surname
          </label>
          <div className="uk-form-controls">
            <input
              className="uk-input"
              id="member-surname"
              type="text"
              placeholder="Surname"
              value={member.surname}
              onChange={(e) =>
                setMember({ ...member, surname: e.target.value })
              }
              required
            />
          </div>
        </div>

        <div className="uk-width-1-2@s">
          <label className="uk-form-label required" htmlFor="member-firstname">
            First Name
          </label>
          <div className="uk-form-controls">
            <input
              className="uk-input"
              id="member-firstname"
              type="text"
              placeholder="First Name."
              value={member.firstName}
              onChange={(e) =>
                setMember({ ...member, firstName: e.target.value })
              }
              required
            />
          </div>
        </div>
        <div className="uk-width-1-3@s">
          <label className="uk-form-label required" htmlFor="member-id-no">
            ID Number
          </label>
          <div className="uk-form-controls">
            <input
              className="uk-input"
              id="member-id-no"
              type="text"
              pattern="[0-9]{11}"
              placeholder="ID Number"
              value={member.idNumber}
              onChange={(e) =>
                setMember({ ...member, idNumber: e.target.value })
              }
              required
            />
            <br />
            <small>Format: 11 digits</small>
          </div>
        </div>

        <div className="uk-width-1-3@s">
          <label className="uk-form-label required" htmlFor="member-dob">
            Date Of Birth
          </label>
          <div className="uk-form-controls">
            <input
              className="uk-input"
              id="member-dob"
              type="date"
              max={date1}
              placeholder="Date Of Birth."
              value={member.dateOfBirth}
              onChange={(e) =>
                setMember({ ...member, dateOfBirth: e.target.value })
              }
              required
            />
          </div>
        </div>

        <div className="uk-width-1-3@s">
          <label className="uk-form-label required" htmlFor="member-gender">
            Gender
          </label>
          <div className="uk-form-controls">
            <select
              className="uk-select"
              id="member-gender"
              value={member.gender}
              onChange={(e) =>
                setMember({
                  ...member,
                  gender: e.target.value as "Male" | "Female",
                })
              }
              required
            >
              <option value="" disabled>
                Select...
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
        </div>
        <div className="uk-width-1-2@s">
          <label className="uk-form-label" htmlFor="member-home-tell">
            Telephone (Home, if applicable)
          </label>
          <div className="uk-form-controls">
            <input
              className="uk-input"
              id="member-home-tell"
              type="text"
              value={member.homeTelephone}
              onChange={(e) =>
                setMember({ ...member, homeTelephone: Number(e.target.value) })
              }
            />
          </div>
        </div>

        <div className="uk-width-1-2@s">
          <label className="uk-form-label" htmlFor="member-work-tell">
            Telephone (Work)
          </label>
          <div className="uk-form-controls">
            <input
              className="uk-input"
              id="member-work-tell"
              type="text"
              value={member.workTelephone}
              onChange={(e) =>
                setMember({ ...member, workTelephone: Number(e.target.value) })
              }
            />
          </div>
        </div>
        <div className="uk-width-1-2@s">
          <label className="uk-form-label required" htmlFor="member-cell">
            Cellphone (+264)
          </label>
          <div className="uk-form-controls">
            <input
              className="uk-input"
              id="member-cell"
              placeholder=""
              type="tel"
              pattern="[+]{1}[2-6]{3}[1-8]{2}[0-9]{3}[0-9]{4}"
              value={member.cellphoneNo}
              onChange={(e) =>
                setMember({ ...member, cellphoneNo: e.target.value })
              }
              required
            />
            <br />
            <small>Limited to Namibia, Format: +264812345678</small>
          </div>
        </div>

        <div className="uk-width-1-2@s">
          <label className="uk-form-label" htmlFor="member-email">
            Email Address
          </label>
          <div className="uk-form-controls">
            <input
              className="uk-input"
              id="member-email"
              type="email"
              placeholder="Email Address"
              value={member.email}
              onChange={(e) => setMember({ ...member, email: e.target.value })}
            />
          </div>
        </div>

        <div className="uk-width-1-2@s">
          <label className="uk-form-label" htmlFor="member-residential-addr">
            Residential Address / Hometown
          </label>
          <div className="uk-form-controls">
            <textarea
              className="uk-textarea"
              id="member-residential-addr"
              rows={3}
              placeholder="Residential Address"
              value={member.residentialAddress}
              onChange={(e) =>
                setMember({ ...member, residentialAddress: e.target.value })
              }
            />
          </div>
        </div>

        <div className="uk-width-1-2@s">
          <label className="uk-form-label" htmlFor="member-postal-addr">
            Postal Address
          </label>
          <div className="uk-form-controls">
            <textarea
              className="uk-textarea"
              id="member-postal-addr"
              rows={3}
              placeholder="Postal Address"
              value={member.postalAddress}
              onChange={(e) =>
                setMember({ ...member, postalAddress: e.target.value })
              }
            />
          </div>
        </div>
        <div
          className="employment-details uk-form-stacked uk-grid-small uk-child-width-1-1"
          data-uk-grid
        >
          <div className="uk-width-1-2@s">
            <label
              className="uk-form-label required"
              htmlFor="member-company-name"
            >
              Company Name
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                id="member-company-name"
                type="text"
                placeholder="Company Name"
                value={member.companyName}
                onChange={(e) =>
                  setMember({ ...member, companyName: e.target.value })
                }
                required
              />
            </div>
          </div>

          <div className="uk-width-1-2@s">
            <label className="uk-form-label" htmlFor="member-company-addr">
              Company Address
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                id="member-company-addr"
                type="text"
                placeholder="Company Address"
                value={member.companyAddress}
                onChange={(e) =>
                  setMember({ ...member, companyAddress: e.target.value })
                }
              />
            </div>
          </div>
          <div className="uk-width-1-2@s">
            <label
              className="uk-form-label required"
              htmlFor="member-duty-station"
            >
              Duty Station
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                id="member-duty-station"
                type="text"
                placeholder="Duty Station"
                value={member.dutyStation}
                onChange={(e) =>
                  setMember({ ...member, dutyStation: e.target.value })
                }
                required
              />
            </div>
          </div>

          <div className="uk-width-1-2@s">
            <label className="uk-form-label" htmlFor="member-do-emp">
              Date Of Employment
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                id="member-do-emp"
                max={date}
                type="date"
                value={member.dateOfEmployment}
                onChange={(e) =>
                  setMember({ ...member, dateOfEmployment: e.target.value })
                }
              />
            </div>
          </div>

          <div className="uk-width-1-2@s">
            <label
              className="uk-form-label required"
              htmlFor="member-nature-of-emp"
            >
              Nature Of Employment
            </label>
            <div className="uk-form-controls">
              <select
                className="uk-select"
                id="member-nature-of-emp"
                value={member.natureOfEmployment}
                onChange={(e) =>
                  setMember({
                    ...member,
                    natureOfEmployment: e.target.value as
                      | "Permanent"
                      | "Contract"
                      | "Tempolary",
                  })
                }
                required
              >
                <option value="" disabled>
                  Select...
                </option>
                <option value="Permanent">Permanent</option>
                <option value="Contract">Contract</option>
                <option value="Tempolary">Temporary</option>
              </select>
            </div>
          </div>

          <div className="uk-width-1-2@s">
            <label
              className="uk-form-label required"
              htmlFor="member-occupation"
            >
              Occupation
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                id="member-occupation"
                type="text"
                placeholder="Occupation"
                value={member.occupation}
                onChange={(e) =>
                  setMember({ ...member, occupation: e.target.value })
                }
                required
              />
            </div>
          </div>

          <div className="uk-width-1-2@s">
            <label className="uk-form-label" htmlFor="member-department">
              Department
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                id="member-department"
                type="text"
                placeholder="Department"
                value={member.department}
                onChange={(e) =>
                  setMember({ ...member, department: e.target.value })
                }
              />
            </div>
          </div>

          <div className="uk-width-1-2@s">
            <label className="uk-form-label required" htmlFor="member-region">
              Region
            </label>
            <div className="uk-form-controls">
              <select
                className="uk-select"
                id="member-region"
                value={member.region}
                onChange={(e) =>
                  setMember({ ...member, region: e.target.value })
                }
                required
              >
                <option value="" disabled>
                  Select...
                </option>
                <option value="Khomas">Khomas</option>
                <option value="Erongo">Erongo</option>
                <option value="Oshana">Oshana</option>
                <option value="Hardap">Hardap</option>
                <option value="Kunene">Kunene</option>
                <option value="Otjozondjupa">Otjozondjupa</option>
                <option value="Omusati">Omusati</option>
                <option value="Oshikoto">Oshikoto</option>
                <option value="Kavango West">Kavango West</option>
                <option value="Kavango East">Kavango East</option>
                <option value="!Kharas">!Kharas</option>
                <option value="Ohangwena">Ohangwena</option>
                <option value="Zambezi">Zambezi</option>
                <option value="Omaheke">Omaheke</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

          <div className="uk-width-1-2@s">
            <label
              className="uk-form-label required"
              htmlFor="member-basic-salary"
            >
              Basic Salary N$ {NumberFormat(member.basicSalary)}
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                id="member-basic-salary"
                type="text"
                value={member.basicSalary}
                onChange={(e) =>
                  setMember({ ...member, basicSalary: Number(e.target.value) })
                }
                required
              />
            </div>

            {member.basicSalary !== 0 && (
              <label className="uk-form-label">
                {deductionAmount.toFixed(2)}, 1.3% of basic salary to be
                deducted (approximate amount).
              </label>
            )}
          </div>
          <div className="uk-width-1-2@s">
            <label className="uk-form-label" htmlFor="member-emp-code">
              Salary No/Employment Code (if applicable)
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                id="member-emp-code"
                type="number"
                placeholder="Employment code."
                value={member.employmentCode}
                onChange={(e) =>
                  setMember({
                    ...member,
                    employmentCode: Number(e.target.value),
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="terms-conditions">
          <b>
            Stop order authorization for deduction of monthly trade union
            subscription and terms and conditions.
          </b>
          <p>
            I, the undersigned, not having authorized a stop-order for any other
            Trade Union/having withdrawn all other such authorizations, hereby
            agree to the monthly deductions of 1.3% (one point three percent)
            from my Basic Salary/Wage, which shall be paid over to the NAMIBIAN
            FOOD AND ALLIED WORKERS UNION (NAFAU) in respect of my membership to
            the union. My deduction shall be varied to meet any change in
            subscription fees notified to me from time to time by NAFAU.
          </p>
          <p>
            By submitting the form, you have also agreed to sign the form
            digitaly.
          </p>

          <div className="uk-margin">
            <input
              className="uk-checkbox uk-margin-small-right"
              type="checkbox"
              name="agree"
              id="terms_conditions"
              required
            />

            <label htmlFor="terms_conditions" className="required">
              By ticking the box, I have read, and agree with the stop order
              authorization for deduction of monthly trade union subscription
              and the terms {"&"} conditions.
            </label>
          </div>

          <div className="controls">
            <button className="form-button" type="submit">
              Submit
              {loading && <div data-uk-spinner="ratio: .6"></div>}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

const MembershipOnlineApplication = observer(() => {
  const [member, setMember] = useState<IMemberApplication>(defaultMembers);
  // const [activeStage, setActiveStage] = useState<"personal" | "emp">(
  //   "personal"
  // );
  const { api } = useAppContext();
  const [loading, setLoading] = useState(false);
  // const [randomCode, setRandomCode] = useState<string>("");

  // const onSubmitDetail = async (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   window.scrollTo(0, 0);
  //   setLoading(true);
  //   try {
  //     await api.messageApi.sendVerificationCode(
  //       `${member.cellphoneNo}`,
  //       `${randomCode}`
  //     );
  //   } catch (error) { }
  //   setLoading(false);
  //   setActiveStage("emp");
  // };

  // const onBackToDetail = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   e.stopPropagation();
  //   window.scrollTo(0, 0);
  //   setActiveStage("personal");
  // };

  // const onCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   e.stopPropagation();
  //   window.scrollTo(0, 0);
  //   setMember({ ...defaultMembers });
  //   setActiveStage("personal");
  // };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api.memberApplications.create(member);
      setLoading(false);
      alert("Thank you for your application!");
    } catch (error) {
      alert("An Error Occured!");
    }
    setLoading(false);
    setMember({ ...defaultMembers });
    // setActiveStage("personal");
  };

  // useEffect(() => {
  //   const random = Math.floor(Math.random() * 600000).toString();
  //   setRandomCode(random);
  //   return () => { };
  // }, []);

  return (
    <div className="online-application uk-margin">
      <div className="membership-form uk-card uk-card-default">
        <div className="card uk-card-body">
          {/* <Tabs activeStage={activeStage} /> */}
          <h5 className="title">Apply for NAFAU Membership Online</h5>
          <ApplicationForm
            member={member}
            setMember={setMember}
            loading={loading}
            onSubmit={onSubmit}
          />
          {/* {activeStage === "personal" && (
            <ApplicationForm
              member={member}
              setMember={setMember}
              loading={loading}
              onSubmit={onSubmitDetail}
            />
          )} */}
          {/* {activeStage === "emp" && (
            <ConfirmationPage
              member={member}
              setMember={setMember}
              loading={loading}
              onSubmit={onSubmit}
              onBack={onBackToDetail}
              randomCode={randomCode}
              onCancel={onCancel}
            />
          )} */}
        </div>
      </div>
    </div>
  );
});

export default MembershipOnlineApplication;

// interface IConfirmProps {
//   member: IMemberApplication;
//   setMember: React.Dispatch<React.SetStateAction<IMemberApplication>>;
//   loading: boolean;
//   onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
//   onBack: (e: React.MouseEvent<HTMLButtonElement>) => void;
//   onCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
//   randomCode: string;
// }

// export const ConfirmationPage = observer((props: IConfirmProps) => {
//   const { loading, member, setMember, onSubmit, onBack, onCancel, randomCode } =
//     props;

//   const [inputCode, setInputCode] = useState<string>("");
//   const [counter, setCounter] = useState(60);

//   useEffect(() => {
//     counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
//   }, [counter]);

//   return (
//     <form
//       className="confirmation-form uk-form-stacked"
//       autoComplete="off"
//       onSubmit={onSubmit}
//     >
//       <div className="uk-margin">
//         <label className="uk-form-label" htmlFor="code">
//           Enter the code which is send to the cellphone number you entered.
//           <br /> {counter}s
//         </label>
//         <div className="uk-form-controls">
//           <input
//             className="uk-input"
//             id="code"
//             type="text"
//             placeholder="Verification Code"
//             name="code"
//             value={inputCode}
//             onChange={(e) => setInputCode(e.target.value)}
//             required
//           />
//         </div>
//       </div>
//       <div className="uk-margin dialog-footer">
//         {inputCode !== randomCode && (
//           <button className="dialog-button disabled" type="button" disabled>
//             Verification required
//           </button>
//         )}
//         {inputCode === randomCode && (
//           <button className="dialog-button active" type="submit">
//             Submit
//             {loading && (
//               <span className="uk-margin-left" data-uk-spinner="ratio: 0.5" />
//             )}
//           </button>
//         )}
//         <button
//           className="dialog-button uk-margin-small-left normal"
//           type="button"
//           onClick={onCancel}
//         >
//           Cancel Application
//         </button>
//         {counter === 0 && (
//           <button
//             className="dialog-button uk-margin-small-left normal"
//             type="button"
//             onClick={onBack}
//           >
//             Did not recieve the code
//           </button>
//         )}
//       </div>
//     </form>
//   );
// });
