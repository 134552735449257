import { observer } from "mobx-react-lite";
import React, { FormEvent, useState } from "react";
import {
  IReportedIssue,
  defaultReportedIssue,
} from "../../../shared/interfaces/IReportedIssue";
import { useAppContext } from "../../../shared/utils/Context";
import "./ReportAProblem.scss";

const ReportAProblem = observer(() => {
  const { api, store } = useAppContext();
  const [loading, setLoading] = useState(false);

  const [issue, setIssue] = useState<IReportedIssue>({
    ...defaultReportedIssue,
  });
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const message =
    "An issue has been reported. Log into NAFAU Website Admin Portal to read more.";

  var setTo = "";
  var setCC = "";

  switch (issue.nearestBranch) {
    case "Windhoek":
      setTo = "windhoek@nafau.org.na";
      setCC = "central@nafau.com.na";
      break;
    case "Walvis Bay":
      setTo = "walvisbay@nafau.org.na";
      setCC = "western@nafau.org.na";
      break;
    case "Swakopmund":
      setTo = "walvisbay@nafau.org.na";
      setCC = "western@nafau.org.na";
      break;
    case "Luderitz":
      setTo = "luderitz@nafau.org.na";
      setCC = "southern@nafau.org.na";
      break;
    case "Keetmanshoop":
      setTo = "keetmanshoop@nafau.org.na";
      setCC = "southern@nafau.org.na";
      break;
    case "Oshakati":
      setTo = "oshakati@nafau.org.na";
      setCC = "northern@nafau.org.na";
      break;
    case "Tsumeb":
      setTo = "tsumeb@nafau.org.na";
      setCC = "northern@nafau.org.na";
      break;
    case "Katima Mulilo":
      setTo = "katima@nafau.org.na";
      setCC = "info@nafau.org.na";
      break;
    case "Rundu":
      setTo = "rundu@nafau.org.na";
      setCC = "info@nafau.org.na";
      break;
    case "Noordoewer":
      setTo = "info@nafau.org.na";
      setCC = "info@nafau.org.na";
      break;
    default:
      setTo = "info@nafau.org.na";
      setCC = "info@nafau.org.na";
      break;
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const $issue = { ...issue, reporter: `${fname} ${lname}` };
    setLoading(true);
    try {
      await api.mail.reportNotification(setTo, setCC, issue.subject, message);
      await api.reportedIssues.create($issue);
      setLoading(false);
      alert("Tank you for your report!");
    } catch (error) {
      console.log("Error! ");
    }
    console.log("To =>", setTo, "cc =>", setCC);
    setLoading(false);
    resetAll();
  };

  const resetAll = () => {
    setfname("");
    setlname("");
    setIssue({ ...defaultReportedIssue });
  };

  return (
    <div className="report-a-problem">
      <div className="bg"></div>

      <div className="uk-section">
        <div className="container uk-container">
          <div className="report-form uk-card uk-card-default uk-card-body">
            <h5 className="title">Report an issue at work.</h5>

            <form
              className="uk-form-stacked uk-grid-small uk-child-width-1-1"
              data-uk-grid
              onSubmit={onSubmit}
              autoComplete="off"
            >
              <div className="uk-width-1-2@s">
                <label
                  className="uk-form-label required"
                  htmlFor="report-firstname"
                >
                  First Name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="report-firstname"
                    type="text"
                    placeholder="First Name"
                    value={fname}
                    onChange={(e) => setfname(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="uk-width-1-2@s">
                <label
                  className="uk-form-label required"
                  htmlFor="report-lastname"
                >
                  Last Name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="report-lastname"
                    type="text"
                    placeholder="Last Name"
                    value={lname}
                    onChange={(e) => setlname(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="uk-width-1-2@s">
                <label
                  className="uk-form-label required"
                  htmlFor="report-idnumber"
                >
                  ID Number
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="report-idnumber"
                    type="text"
                    placeholder="ID Number"
                    value={issue.idnumber}
                    onChange={(e) =>
                      setIssue({ ...issue, idnumber: e.target.value })
                    }
                    required
                  />
                </div>
              </div>

              <div className="uk-width-1-2@s">
                <label
                  className="uk-form-label required"
                  htmlFor="report-cellphone"
                >
                  Cellphone
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="report-cellphone"
                    type="text"
                    placeholder="Cellphone"
                    value={issue.cellphone}
                    onChange={(e) =>
                      setIssue({ ...issue, cellphone: e.target.value })
                    }
                    required
                  />
                </div>
              </div>

              <div className="uk-width-1-2@s">
                <label
                  className="uk-form-label required"
                  htmlFor="report-company"
                >
                  Company Name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="report-company"
                    type="text"
                    placeholder="Company Name"
                    value={issue.companyName}
                    onChange={(e) =>
                      setIssue({ ...issue, companyName: e.target.value })
                    }
                    required
                  />
                </div>
              </div>

              <div className="uk-width-1-2@s">
                <label className="uk-form-label" htmlFor="report-branch">
                  Select Nearest NAFAU Branch
                </label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select"
                    id="report-branch"
                    value={issue.nearestBranch}
                    onChange={(e) =>
                      setIssue({ ...issue, nearestBranch: e.target.value })
                    }
                    required
                  >
                    <option>Select...</option>
                    <option value="Windhoek">Windhoek</option>
                    <option value="Walvis Bay">Walvis Bay</option>
                    <option value="Swakopmund">Swakopmund</option>
                    <option value="Luderitz">Luderitz</option>
                    <option value="Keetmanshoop">Keetmanshoop</option>
                    <option value="Oshakati">Oshakati</option>
                    <option value="Tsumeb">Tsumeb</option>
                    <option value="Rundu">Rundu</option>
                    <option value="Katima Mulilo">Katima Mulilo</option>
                    <option value="Noordoewer">Noordoewer</option>
                  </select>
                </div>
              </div>

              <div className="uk-width-1-2@s">
                <label
                  className="uk-form-label required"
                  htmlFor="report-category"
                >
                  Select The Category Of Incident
                </label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select"
                    id="report-category"
                    value={issue.category}
                    onChange={(e) =>
                      setIssue({ ...issue, category: e.target.value })
                    }
                    required
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    <option value="Harassment">Sexual Harassment</option>
                    <option value="Nepotism">Nepotism</option>
                    <option value="Discrimination">Discrimination</option>
                    <option value="Interpersonal conflict">
                      Interpersonal conflict
                    </option>
                    <option value="Unfair pay">Unfair pay</option>
                    <option value="Health, and Safety">
                      Health, and Safety
                    </option>
                    <option value="Unfair dismissal">Unfair dismissal</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
              </div>
              {issue.category === "Others" && (
                <div>
                  <label
                    className="uk-form-label required"
                    htmlFor="report-subject"
                  >
                    Subject
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="report-subject"
                      type="text"
                      placeholder="Subject"
                      value={issue.subject}
                      onChange={(e) =>
                        setIssue({ ...issue, subject: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
              )}
              <div>
                <label className="uk-form-label" htmlFor="report-message">
                  Message
                </label>
                <div className="uk-form-controls">
                  <textarea
                    className="uk-textarea"
                    id="report-message"
                    rows={5}
                    placeholder="What Do You Want To Report?"
                    value={issue.message}
                    onChange={(e) =>
                      setIssue({ ...issue, message: e.target.value })
                    }
                    required
                  />
                </div>
              </div>
              <div className="controls">
                <button className="btn primary" type="submit">
                  Send Report
                  {loading && <div data-uk-spinner="ratio: .6"></div>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ReportAProblem;
