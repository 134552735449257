import { Swiper, SwiperSlide } from "swiper/react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import INewsPost from "../../../shared/interfaces/INewsPost";
import { useAppContext } from "../../../shared/utils/Context";
import { observer } from "mobx-react-lite";
import { NewsPost } from "../../../shared/models/NewsPost";

interface InewsProps {
  post: INewsPost;
  onView: (news: INewsPost) => void;
}

const NewsOverlayCard = (props: InewsProps) => {
  const { post, onView } = props;

  const navigate = useNavigate();
  const onViews = () => {
    navigate("/news/");
    onView(post);
  };
  return (
    <div className="news-card overlay">
      <div className="container">
        <div className="uk-card uk-card-default uk-card-body uk-width-1-2@m">
          <h3 className="uk-card-title">{post.heading}</h3>
          <div className="news-button">
            <button className="read-more-button" onClick={onViews}>
              Read more
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

interface IProps {
  post: NewsPost;
  onView: (news: INewsPost) => void;
}

const NewsSliderContainer = (props: IProps) => {
  const { post } = props;

  const navigate = useNavigate();

  const onView = () => {
    navigate("/news/");
    props.onView(post.asJson);
  };

  return (
    <>
      {/* <div
        className="news-main"
        style={{ backgroundImage: `url(${thumbnailUrl})` }}
      >
     <div className="news-sub">
        <div className="uk-width-3-4@s uk-width-2-3@m uk-width-1-2@l uk-width-3-7@xl">
          <NewsOverlayCard post={post} onView={onView} />
        </div>
      </div>
      </div>
      <div className="uk-card uk-card-default uk-card-body uk-width-1-2@m">
        <h3 className="uk-card-title">{post.heading}</h3>
        <div className="news-button">
          <button className="read-more-button" onClick={onView}>
            Read more
          </button>
        </div>
      </div> */}
      <div
        className="uk-section news-main"
        style={{ backgroundImage: `url(${post.thumbnailUrl})` }}
      >
        <div className="uk-container">
          <h3
            className="uk-card-title"
            style={{ backgroundColor: `white`, padding: "10px" }}
          >
            {post.heading}
            <div className="news-button">
              <button className="read-more-button" onClick={onView}>
                Read more
              </button>
            </div>
          </h3>
        </div>
      </div>
    </>
  );
};

// NewsSliderContainer.propTypes = {
//   data: PropTypes.object,
// };

interface ISliderProps {
  posts: NewsPost[];
  onView: (news: INewsPost) => void;
  loading: boolean;
}

const NewsPageSlider = observer((props: ISliderProps) => {
  const { posts, loading } = props;
  const { store } = useAppContext();
  const navigate = useNavigate();
  const loadingGIF = `${process.env.PUBLIC_URL}/assets/gallery/Loading.gif`;

  const onView = (post: INewsPost) => {
    navigate(`./${post.id}`);
  };

  const swiperOption = {
    loop: true,
    speed: 1000,
    spaceBetween: 0,
    slidesPerView: 1,
    autoplay: {
      delay: 8000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".intro-slider .swiper-button-next",
      prevEl: ".intro-slider .swiper-button-prev",
    },
  };

  return (
    <div className="intro-slider-wrap">
      <Swiper effect="fade" className="intro-slider" {...swiperOption}>
        {loading && (
          <img
            alt="Loading"
            src={loadingGIF}
            style={{ display: loading ? "block" : "none" }}
          />
          // <div className="news-main">
          //   <div className="news-sub">
          //     <div className="uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l uk-width-1-1@xl">
          //       <div className="news-card overlay">
          //         <div className="container">
          //           <div className="uk-card uk-card-default uk-card-body uk-width-1-1@m">
          //             {/* <h3 className="uk-card-title">Loading...</h3> */}
          // <img
          //   alt="Loading"
          //   src={loadingGIF}
          //   style={{ display: loading ? "block" : "none" }}
          // />
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // </div>
        )}
        {!loading && (
          <>
            {store.news.size === 0 ? (
              <div className="news-main">
                <div className="news-sub">
                  <div className="uk-width-3-4@s uk-width-2-3@m uk-width-1-2@l uk-width-3-7@xl">
                    <div className="news-card overlay">
                      <div className="container">
                        <div className="uk-card uk-card-default uk-card-body uk-width-1-2@m">
                          <h3 className="uk-card-title">No news updates</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {posts.slice(0, 4).map((post) => {
                  return (
                    <SwiperSlide key={post.id}>
                      <NewsSliderContainer
                        key={post.id}
                        post={post}
                        onView={onView}
                      />
                    </SwiperSlide>
                  );
                })}
              </>
            )}
          </>
        )}
        <div className="home-slider-next main-slider-nav swiper-button-next">
          <i className="fal fa-angle-right"></i>
        </div>
        <div className="home-slider-prev main-slider-nav swiper-button-prev">
          <i className="fal fa-angle-left"></i>
        </div>
      </Swiper>
    </div>
  );
});

export default NewsPageSlider;
