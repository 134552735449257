import React from "react";
import TractionAnalytics from "./analytics/Analytics";
import Background from "./background/Background";
import HomePageSlider from "./header/HomePageSlider";

const Home = () => {
  return (
    <>
      <HomePageSlider />
      <Background />
      <TractionAnalytics />
    </>
  );
};

export default Home;
