import React from "react";

const CEC = () => {
  return (
    <div className="cec section-structure">
      <h6 className="title">Central Executive Committee (CEC)</h6>
      <div className="content">
        <p className="description">
          The CEC shall be the highest authority of NAFAU between the National
          Congresses (NC). It shall discuss, review, amend, adopt, or annul its
          resolutions, decisions, and recommendations of the NEC.
        </p>
        <p className="description">
          The CEC shall meet two times a year on the date recommended by the NEC
          with the agenda as recommended by the NEC.
        </p>
        <p className="description">
          It shall have the power to discuss, review, revoke, amend, annul, or
          adopt resolutions, implement decisions and or recommendations made by
          the NEC.
        </p>
        <p className="description">
          A 14 days’ notification of the CEC meeting with the date and
          recommended agenda shall be given in writing to the members of the CEC
          by the General Secretary on the instruction of the NEC.
        </p>
        <p className="description">
          The CEC may hold extraordinary meetings between bi-annual meetings.
          Such extraordinary meetings can only be held, and recommended agendas
          determine and supported by eleven (11) members of the NEC provided
          that notification of (fourteen) 14 days of such meeting with the
          recommended agenda is given by the General Secretary on the
          instruction of the NEC.
        </p>
        <p className="description">
          A member of the CEC who fails to be present at three consecutive
          meetings of the CEC without valid reason shall automatically cease to
          be a member of the CEC.
        </p>
        <p className="description">
          The CEC shall be accountable to the National Congress (NC). The quorum
          of the CEC shall be the simple majority with voting rights of its
          members.
        </p>
        <p className="description">
          The quorum of the CEC shall be constituted by twenty-three (23) of the
          members and it shall take decisions on a simple majority vote of the
          members present. If within one hour from the time as specified on the
          written notice of any meeting of the CEC a quorum is not present such
          meeting shall stand adjourned for (thirty) 30 days and such
          adjournment meeting of which notice shall be given in the usual way,
          the members present shall constitute a quorum and shall conduct the
          business of the meeting.
        </p>
        <p className="description">
          The decision and or ruling of the CEC shall be final and binding.
        </p>
        <hr />
        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>The Central Executive Committee shall be attended by:</p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">
                    The forty-five (45) members of the CEC.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Each Regional chairperson and/ or Regional Secretary with voting
                    right, the General Secretary, Deputy General Secretary, and the
                    Union employees responsible for organizing, educating, finance and
                    membership administration without having voting rights.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The CEC shall be responsible for rendering a written account of
                    its activities and work to the NC.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The CEC shall be responsible for rectifying, amending,
                    repudiating, or revoking decisions of the NEC as may from time to
                    time be referred to by the NEC.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The CEC shall be responsible for rendering a written account of
                    its activities and work to the NC.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The CEC shall institute, defined and/or abandon any legal
                    proceedings by and/or against NAFAU and/or its elected
                    office-bearers/officials.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <hr />
        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>Filling of Vacant CEC Position</p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">
                    A CEC vacancy can be caused by the death, resignation, dismissal
                    or in the event of a member no longer falling under the scope of
                    NAFAU.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Each Regional chairperson and/ or Regional Secretary with voting
                    right, the General Secretary, Deputy General Secretary, and the
                    Union employees responsible for organizing, educating, finance and
                    membership administration without having voting rights.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The CEC shall be responsible for rendering a written account of
                    its activities and work to the NC.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CEC;
