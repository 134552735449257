import React from "react";

const BBAC = () => {
  return (
    <div className="bbac section-structure">
      <h6 className="title">Branch Bi-Annual Committee (BBAC)</h6>

      <div className="content">
        <p className="description">
          The Branch Executive Committee (BEC) shall convene a Branch Bi-Annual
          Conference (BBAC) in their branch on a bi-annual basis. This can be
          done where the Regional Executive Committee has established or
          demarcated a branch of the union subject to the ratification by the
          REC. The branch chairperson shall preside and maintain order at the
          BBAC. At such conferences, members in good standing present shall be
          elected by ballot.
        </p>
      </div>
    </div>
  );
};

export default BBAC;
