import { makeObservable, runInAction } from "mobx";

import { IUser } from "../interfaces/IUser";
import { User } from "../models/User";
import AppStore from "./AppStore";
import Store from "./Store";

export default class UserStore extends Store<
    IUser,
    User
> {
    items = new Map<string, User>();
    currentUser: User | null = null;
    loading: boolean = true;
    constructor(store: AppStore) {
        super(store);

        makeObservable(this, {
            items: true,
            selected: true,
            currentUser: true,
        });

        this.store = store;

    }

    load(items: IUser[] = []) {
        runInAction(() => {
            items.forEach((item) => this.items.set(item.uid, new User(this.store, item)));
        });
    }
    loadCurrentUser(item: IUser) {
        runInAction(() => {
            this.currentUser = new User(this.store, item);
        });
    }

    removeCurrentUser() {
        runInAction(() => {
            this.currentUser = null;
        });
    }
}