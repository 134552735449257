import React from "react";

const AOC = () => {
  return (
    <div className="aoc section-structure">
      <h6 className="title">Administrative Organisation Structure</h6>

      <div className="content">
        <p className="description">
          NAFAU's organizational structure organizes and relates the
          administrative staff of the Union, so it can take advantage of its
          maximum potential. Below is an outline of the Union's administrative
          structure.
        </p>
      </div>

      <img
        data-aos="zoom-in"
        data-aos-delay="300"
        src={process.env.PUBLIC_URL + "/assets/governance/governance.png"}
        alt=""
      />
    </div>
  );
};

export default AOC;
