import { makeAutoObservable } from "mobx";
import IOffice from "../interfaces/IOffice";

export class OfficePost implements IOffice {
  id: string;
  branch: string;
  region: string;
  contact_person: string;
  location: string;
  tel: string;
  fax: string;
  phone: string;
  mail: string;

  constructor(post: IOffice) {
    makeAutoObservable(this);
    this.id = post.id;
    this.branch = post.branch;
    this.region = post.region;
    this.contact_person = post.contact_person;
    this.location = post.location;
    this.tel = post.tel;
    this.fax = post.fax;
    this.phone = post.phone;
    this.mail = post.mail;
  }

  get asJson(): IOffice {
    return {
      id: this.id,
      branch: this.branch,
      region: this.region,
      contact_person: this.contact_person,
      location: this.location,
      tel: this.tel,
      fax: this.fax,
      phone: this.phone,
      mail: this.mail,
    };
  }
}
