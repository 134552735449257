// type Roles = "Admin" | "News" | "Membership" | "Reports" | "Education";

type Roles = "Admin" | "NEO" | "MR";

// NEO - News,Education and Offices
// MR - Members applications and Reported issues

export const defaultUser: IUser = {
  uid: "",
  fullName: "",
  email: "",
  password: "",
  branch: "",
  role: "NEO",
};

export interface IUser {
  uid: string;
  fullName: string;
  email: string;
  password: string;
  branch: string;
  role: Roles;
}
