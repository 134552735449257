import { ReactComponent as ElectectedIcon } from "../../../icons/Speaker.svg";
import { ReactComponent as CollaborationIcon } from "../../../icons/Team.svg";
import { ReactComponent as QualityIcon } from "../../../icons/Badge.svg";
import { ReactComponent as DedicationIcon } from "../../../icons/Handshake.svg";
import { ReactComponent as PassionIcon } from "../../../icons/Passion.svg";
import { ReactComponent as AccountabilityIcon } from "../../../icons/Accountability.svg";
import { ReactComponent as DiversityIcon } from "../../../icons/Diversity.svg";
import { ReactComponent as IntegrityIcon } from "../../../icons/Shield.svg";
import { ReactComponent as CompetencyIcon } from "../../../icons/Plan.svg";
import { ReactComponent as RecognitionIcon } from "../../../icons/recognition.svg";
import { ReactComponent as MemberIcon } from "../../../icons/member.svg";
import { ReactComponent as BranchIcon } from "../../../icons/branch.svg";
import { ReactComponent as CasesIcon } from "../../../icons/cases.svg";

const Icons = {
  ElectectedIcon,
  CollaborationIcon,
  QualityIcon,
  DedicationIcon,
  PassionIcon,
  AccountabilityIcon,
  DiversityIcon,
  IntegrityIcon,
  CompetencyIcon,
  RecognitionIcon,
  MemberIcon,
  BranchIcon,
  CasesIcon,
};
export default Icons;
