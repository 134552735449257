import React, { useRef, useState } from "react";

const createStyle = (height: number) => {
  const style: React.CSSProperties = {
    maxHeight: `${height}px`,
  };
  return style;
};

interface IProps {
  title: string;
  content: string[];
  children?: React.ReactNode;
}
const PolicyAccordion = (props: IProps) => {
  const { title, content, children } = props;

  const contentRef = useRef<HTMLDivElement>(null);

  const [isExpanded, setisExpanded] = useState(false);
  const [expandCss, setexpandedCss] = useState<React.CSSProperties>({});

  const toggleExpand = () => {
    const height = !isExpanded ? contentRef.current!.offsetHeight + 100 : 50;
    setexpandedCss(createStyle(height));

    setisExpanded(!isExpanded);
  };

  return (
    <div
      className={`kit-accordion-item ${isExpanded ? "expanded" : ""}`}
      onClick={toggleExpand}
      style={expandCss}
    >
      <h6 className="policy-section-title">
        {title} <span className="icon" uk-icon="plus"></span>
      </h6>

      <div className="content" ref={contentRef}>
        {content.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
        {children}
      </div>
    </div>
  );
};

export default PolicyAccordion;
