import { observer } from "mobx-react-lite";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../shared/utils/Context";
import { defaultUser, IUser } from "../../shared/interfaces/IUser";
import "./users.scss";
import { UserForm } from "./CreateNewUser";
interface IToolbar {
  onBack: () => void;
}
const Toolbar = (props: IToolbar) => {
  return (
    <div className="section-toolbar uk-margin">
      <h5 className="title" onClick={() => props.onBack()}>
        <span data-uk-icon="icon: chevron-left; ratio: .8"></span> Back
      </h5>
    </div>
  );
};

const ViewUser = observer(() => {
  const { api, store } = useAppContext();
  const { userId } = useParams();

  const navigate = useNavigate();
  const [user, setUser] = useState<IUser>({
    ...defaultUser,
  });
  const [loading, setLoading] = useState(false);

  const onNavigateBack = () => {
    store.users.clearSelected();
    navigate("/a/users/");
  };

  const loadFromDB = async (id: string) => {
    setLoading(true);
    await api.users.loadById(id);
    setLoading(false);

    const $user = store.users.items.get(id);

    if ($user) setUser($user);
    else onNavigateBack();
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const $user = await api.users.updateUser(user);
      if ($user) await store.users.load([$user]);
      alert("User Updated");
    } catch (error) {
      alert("Failed");
    }
    setLoading(false);
    store.users.clearSelected();
    navigate("/a/users");
  };

  const sendPasswordResetEmail = () => {
    if (user.email) api.users.passwordResetWithEmail(user.email);
  };

  useEffect(() => {
    if (!store.users.selected) {
      if (userId) loadFromDB(userId);
      else onNavigateBack();
    } else setUser(store.users.selected);
  }, [store.users.selected]);

  //   if (loading) return <Loading fullWidth />;

  useEffect(() => {
    if (store.users.selected) setUser(store.users.selected);
    else setUser({ ...defaultUser });
    return () => {};
  }, [store.users.selected]);

  return (
    <div className="create uk-section">
      <div className="uk-container">
        <div className="uk-card uk-card-default uk-width-1-2@m">
          <Toolbar onBack={onNavigateBack} />
          <div className="uk-card-body">
            <form onSubmit={onSubmit}>
              <UserForm
                user={user}
                setUser={setUser}
                passwordField={!store.users.selected}
              />
              <div className="uk-flex create-user-form-footer">
                <div>
                  <button className="kit-btn kit-btn-primary" type="submit">
                    Submit
                    {loading && (
                      <div
                        className="uk-margin-small-right"
                        data-uk-spinner="ratio: 0.5"
                      ></div>
                    )}
                  </button>
                </div>
                <div>
                  <button
                    className="kit-btn kit-btn-primary uk-margin-left"
                    type="button"
                    onClick={sendPasswordResetEmail}
                  >
                    Send password resent email
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ViewUser;
