import React from "react";
import "./aimobjectives.scss";


const AimsObjectives = () => {

  const aims = `${process.env.PUBLIC_URL}/assets/documents/aims and objectives.pdf`;


  return (
    <div className="aim-objective ">
      <div className="bg">
      </div>
      <div className="uk-section">
        <div className="container uk-container">
          <div className="aim-body uk-card uk-card-default uk-card-body">
            <h6 className="title">The aims and objectives of the Union shall be</h6>
            <ol className="sub-list">
              <li className="list-item">
                To organize and unite all workers employed in various job categories within the food and allied industry. To further group them according to the nature of work they are engaged in and assist them in forming a worker’s committee of their own under the auspices of NAFAU.
              </li>
              <br />
              <li>
                To strive for the betterment of the economic and social welfare for all members, as well as justice in general by:
                <br />
                (a) Regular relations, negotiations, and settlement of disputes between the members and their employers
                <br />
                (b) Striving for the improvement of working and living conditions of the members of NAFAU.
                <br />
                (c) Striving for fair and adequate wages, salaries, and severance pay for members of NAFAU.
                <br />
                (d) Promoting education, labour safety, sport, and recreation for members of NAFAU
                <br />
              </li>
              <li>
                To coordinate and synchronize the activities of the workers' committee affiliated to NAFAU by inter-establishing branches in the country.
              </li><br />
              <li>To cooperate with and assist affiliate unions and industrial organizations in the general interest of members of the working-class movement in the social-economic situation in Namibia.</li>
              <li>Provide and promote projects, training courses, and seminars for members and scholarships for self-improvement and self-preparation, respectively.
              </li><br />
              <li>To provide advice and obtain legal assistance were considered necessary to members and officials of the union in matters affecting the employment environment and social security in general.
              </li><br />
              <li>To encourage and promote the spirit of oneness, solidarity, and unity among all workers based on the motto of NAFAU and the philosophy of the invisibility of the workers' movement.
              </li><br />
              <li>To oppose any discrimination in employment and to fight for the right of all the workers as well as worker’s free choice of employment provided that acceptable wages are being paid.
              </li><br />
              <li>Promote, oppose, or support any action in the interest of workers in general and the trade union.
              </li><br />
              <li>To advance the democratic rights of all workers.
              </li><br />
              <li>To uphold and fight for the internationally accepted practice of an (8 hour) working day and other international labor standards.
              </li><br />
              <li>To make a presentation to the government on labor and trade union legislation and other matters affecting workers in general.
              </li><br />
              <li>
                To promote international friendship, co-operation, and solidarity with all workers around the world based on mutual respect and no interference in each other’s affairs.
              </li><br />
              <li>
                To do such other things as appear to be in the interest of the Union and its members and which are not inconsistent with the aims and objectives, or any matter specifically provided for in the Constitution.
              </li>
            </ol>
            <a className="download" href={aims} download>
              Download PDF<span uk-icon="icon: download; ratio: .8"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AimsObjectives;
