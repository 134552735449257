import axios from "axios";
import { stringify } from "querystring";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";

export default class MailApi {
  API_URI: string;

  constructor(private api: AppApi, private store: AppStore, URI: string) {
    this.API_URI = URI;
  }

  async emailNotification(to: string, subject: string, message: string) {
    const from = "no-reply@nafau.org.na";
    const cc = "";
    await this.sendMail(from, to, cc, subject, message);
    // console.log("Mail To be send =>", to, from, subject, message);
  }

  async reportNotification(
    to: string,
    cc: string,
    subject: string,
    message: string
  ) {
    const from = "no-reply@nafau.org.na";
    await this.sendMail(from, to, cc, subject, message);
    // console.log("Mail To be send =>", to, from, subject, message);
  }

  // Send
  async sendMail(
    from: string,
    to: string,
    cc: string,
    subject: string,
    message: string
  ) {
    const body = {
      from: from,
      to: to,
      cc: cc,
      subject,
      message,
    };

    const uri = `${this.API_URI}${stringify(body)}`;

    axios
      .get(uri)
      .then((response) => {
        //   Snackbar

        console.log("Successful request => ", response.data);
      })
      .catch((error) => {
        //   Snackbar
        console.log(error);
      });
  }
}
