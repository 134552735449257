import React from "react";

const REC = () => {
  return (
    <div className="rec section-structure">
      <div className="content">
        <div className="uk-grid-match" data-uk-grid>
          <div className="uk-width-1-3@l uk-width-1-1@s uk-width-1-3@m uk-text-center">
            <div className="uk-card uk-card-default uk-card-body card-title">
              <div className="title">
                <div>
                  <p>
                  Regional Executive Committee (REC)
                  </p>
                </div>
                <div className="line-div">
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <div className="uk-card uk-card-default uk-card-body card-content">
              <ol className="list">
                <li className="list-item">
                  <p className="list-item-text">
                    The management of the RBC affairs shall be vested in the Regional
                    Executive Committee (REC).
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    Meetings of the REC shall be attended by all members of the REC,
                    all Chairpersons or Secretaries of all branches in the given
                    region and all NAFAU officials in each region without voting
                    rights. The REC shall meet at least once every three (3) months,
                    members of the REC shall hold membership not longer than two (2)
                    years and shall be eligible for re-election.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The REC shall coordinate the recruitment of members in a
                    particular region and promote their interest in general. The REC
                    shall approve the monthly financial statements as proposed and
                    recommended by the Regional Coordinator. The REC shall identify
                    and evaluate problems affecting members in the region and make
                    necessary recommendations to the NEC or to the CEC for actions.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    In the absence of the Regional Chairperson, the Deputy Regional
                    Chairperson shall attend the NEC meeting to discuss and report on
                    regional activities and progress thereof.
                  </p>
                </li>
                <li className="list-item">
                  <p className="list-item-text">
                    The REC shall be the highest NAFAU structure at the regional
                    level.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default REC;
