import React from "react";
import { useNavigate } from "react-router-dom";
import INewsPost from "../../../shared/interfaces/INewsPost";

interface IProps {
  post: INewsPost;
  contentHeight?: number;
  onView: (news: INewsPost) => void;
}

const NewsItemCard = (props: IProps) => {
  const { post } = props;
  const { thumbnailUrl, date, category, heading, highlight } = post;

  const navigate = useNavigate();

  const onView = () => {
    navigate("/news/");
    props.onView(post);
  };

  return (
    <div>
      <div
        onClick={onView}
        className="card-post uk-card uk-card-default"
        data-aos="fade-in"
        data-aos-delay="100"
      >
        <div className="uk-card-media-top thumbnail">
          <img src={thumbnailUrl} alt="" />
          <p className="date">{date}</p>
        </div>
        <div className="uk-card-body">
          <div className="uk-card-badge uk-label">{category}</div>
          <h4 className="uk-card-title title" onClick={onView}>
            {heading}
          </h4>
          <p>{highlight}..</p>

          <span className="read-more" onClick={onView}>
            Read more
          </span>
        </div>
      </div>
    </div>
  );
};

export default NewsItemCard;
