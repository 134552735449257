import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAppContext } from "../shared/utils/Context";
import Home from "./home/Home";
import News from "./news/news/News";
import ViewNewsPost from "./news/view-post/ViewNewsPost";
import Education from "./union-101/union-101/Education";
import MembershipApplications from "./members/applications/MembershipApplications";
import ReportedIssues from "./reported-issues/issues/ReportedIssues";
import ViewEducationPost from "./union-101/view-post/ViewEducationPost";
import ViewReportedIssue from "./reported-issues/view-reported-issue/ViewReportedIssue";
import ViewApplication from "./members/applications/ViewApplication";
import NafauUsers from "./users/NafauUsers";
import CreateNewUser from "./users/CreateNewUser";
import ViewUser from "./users/ViewUser";
import Office from "./offices/office/Office";
import ViewOfficePost from "./offices/view-office/ViewOfficePost";

const AdminRouting = observer(() => {
  const { store } = useAppContext();

  const [role, setRole] = useState<"Admin" | "NEO" | "MR">("NEO");
  const currUser = store.users.currentUser;

  const getUserDoc = useCallback(() => {
    if (store.users.loading) return;
    if (currUser) setRole(currUser.role);
  }, [store.users]);

  useEffect(() => {
    getUserDoc();
  }, [getUserDoc]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />

      {role === "NEO" && (
        <>
          <Route path="education" element={<Education />} />
          <Route path="education/:postId" element={<ViewEducationPost />} />
          <Route path="members/offices" element={<Office />} />
          <Route
            path="members/offices/:officeId"
            element={<ViewOfficePost />}
          />
          <Route path="news" element={<News />} />
          <Route path="news/:postId" element={<ViewNewsPost />} />
        </>
      )}

      {role === "MR" && (
        <>
          <Route path="reported-issues" element={<ReportedIssues />} />
          <Route
            path="reported-issues/:issueId"
            element={<ViewReportedIssue />}
          />
          <Route
            path="members/applications/:memberId"
            element={<ViewApplication />}
          />
          <Route
            path="members/applications"
            element={<MembershipApplications />}
          />
        </>
      )}

      {role === "Admin" && (
        <>
          <Route path="users" element={<NafauUsers />} />
          <Route path="users/new" element={<CreateNewUser />} />
          <Route path="users/:userId" element={<ViewUser />} />
          <Route
            path="reported-issues/:issueId"
            element={<ViewReportedIssue />}
          />
          <Route path="reported-issues" element={<ReportedIssues />} />
          <Route
            path="members/applications"
            element={<MembershipApplications />}
          />
          <Route
            path="members/applications/:memberId"
            element={<ViewApplication />}
          />
          <Route path="education" element={<Education />} />
          <Route path="education/:postId" element={<ViewEducationPost />} />
          <Route path="members/offices" element={<Office />} />
          <Route
            path="members/offices/:officeId"
            element={<ViewOfficePost />}
          />
          <Route path="news" element={<News />} />
          <Route path="news/:postId" element={<ViewNewsPost />} />
        </>
      )}

      <Route path="*" element={<Navigate to="/a" />} />
    </Routes>
  );
});

export default AdminRouting;
