import React, { Fragment, useEffect } from "react";
import NewsPostItem from "./NewsPostItem";
import "./News.scss";
import { observer } from "mobx-react-lite";
import { useAppContext } from "../../../shared/utils/Context";
import { useNavigate } from "react-router-dom";
import INewsPost from "../../../shared/interfaces/INewsPost";

interface IProps {
  onCreate: () => void;
}
const Toolbar = (props: IProps) => {
  return (
    <div className="toolbar uk-margin">
      <button
        className="kit-btn kit-btn-primary"
        onClick={() => props.onCreate()}
      >
        <span data-uk-icon="icon: plus-circle; ratio: .8"></span> Create a Post
      </button>
    </div>
  );
};

const News = observer(() => {
  const { api, store } = useAppContext();

  const navigate = useNavigate();

  const onCreate = () => {
    navigate(`./new`);
  };

  const onView = (post: INewsPost) => {
    store.news.select(post);
    navigate(`./${post.id}`);
  };

  const onDelete = async (id: string) => {
    await api.news.delete(id);
  };

  useEffect(() => {
    api.news.loadAll();

    return () => {};
  }, []);

  return (
    <div className="admin-news uk-section">
      <div className="container uk-container uk-container-large">
        <Toolbar onCreate={onCreate} />
        <div
          className="uk-child-width-1-4@l uk-child-width-1-3@m uk-grid-small uk-grid-match"
          data-uk-grid
        >
          {store.news.all.map((post) => (
            <NewsPostItem
              key={post.id}
              post={post}
              contentHeight={5}
              onView={onView}
              onDelete={onDelete}
            />
          ))}
        </div>
      </div>
    </div>
  );
});

export default News;
