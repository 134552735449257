import React from "react";
import SliderImages from "./sliderImages.json";
import { Swiper, SwiperSlide } from "swiper/react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./Header.scss";

interface ISliderImg {
  image: string;
  paragraph: string;
  onImageView: () => void;
  onOverlayView: () => void;
}

const Intro = (props: ISliderImg) => {
  const { image, paragraph, onImageView, onOverlayView } = props;
  return (
    <div
      className="intro-section"
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL + image})` }}
    >
      <div className="intro-container">
        <div className="uk-width-3-4@s uk-width-2-3@m uk-width-1-2@l uk-width-3-7@xl">
          <div className="main-overlay" data-aos="fade-in">
            <h4 className="header-title">NAFAU</h4>
            <p className="injury">An Injury to one is an injury to all.</p>
            <p className="catchy-text">{paragraph}</p>
            <div className="header-button">
              <div>
                <Link
                  className="mbr-button"
                  to={process.env.PUBLIC_URL + "/members/apply"}
                >
                  Become a Member
                </Link>
              </div>
              <div>
                <Link
                  className="ctc-button uk-margin-small-left"
                  to={process.env.PUBLIC_URL + "/members/report-a-problem"}
                >
                  Report an Issue
                </Link>
              </div>
            </div>
          </div>
          <div className="overlay-button">
            <button onClick={onImageView}>
              <span data-uk-icon="ban"></span>
            </button>
            <button onClick={onOverlayView}>
              <span data-uk-icon="play"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Intro.propTypes = {
  data: PropTypes.object,
};

const HomePageSlider = () => {
  const swiperOption = {
    loop: true,
    speed: 1000,
    spaceBetween: 0,
    slidesPerView: 1,
    // autoplay: {
    //   delay: 6000,
    //   disableOnInteraction: false,
    // },
    navigation: {
      nextEl: ".intro-slider .swiper-button-next",
      prevEl: ".intro-slider .swiper-button-prev",
    },
  };

  const element = document.getElementsByClassName("main-overlay")!;
  const onImageView = () => {
    for (var i = 0; i < element.length; i++) {
      var div = element[i];
      div.setAttribute("style", "opacity:0;");
    }
  };
  const onOverlayView = () => {
    for (var i = 0; i < element.length; i++) {
      var div = element[i];
      div.setAttribute("style", "opacity:1;");
    }
  };

  return (
    <div className="intro-slider-wrap">
      <Swiper effect="fade" className="intro-slider" {...swiperOption}>
        {SliderImages &&
          SliderImages.map((data) => {
            return (
              <SwiperSlide key={data.id}>
                <Intro
                  key={data.id}
                  image={data.backgroundImage}
                  paragraph={data.paragraph}
                  onOverlayView={onOverlayView}
                  onImageView={onImageView}
                />
              </SwiperSlide>
            );
          })}
        <div className="home-slider-next main-slider-nav swiper-button-next">
          <i className="fal fa-angle-right"></i>
        </div>
        <div className="home-slider-prev main-slider-nav swiper-button-prev">
          <i className="fal fa-angle-left"></i>
        </div>
      </Swiper>
    </div>
  );
};

export default HomePageSlider;
