export const defaultOffice: IOffice = {
  id: "",
  branch: "",
  region: "",
  contact_person: "",
  location: "",
  tel: "",
  fax: "",
  phone: "",
  mail: "",
};

export default interface IOffice {
  id: string;
  branch: string;
  region: string;
  contact_person: string;
  location: string;
  tel: string;
  fax: string;
  phone: string;
  mail: string;
}
