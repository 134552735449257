import React from "react";
import { MemberApplication } from "../../../shared/models/MemberApplication";
import { IMemberApplication } from "../../../shared/interfaces/IMemberApplication";

interface IProps {
  index: number;
  member: MemberApplication;
  onView: (member: IMemberApplication) => void;
  onDelete: (id: string) => void;
}

const ApplicationsRow = (props: IProps) => {
  const { index, member } = props;

  const onView = () => {
    props.onView(member.asJson);
  };

  const onDelete = () => {
    props.onDelete(member.id);
  };

  return (
    <>
      <td>{index + 1}</td>
      <td>{member.surname + "  " + member.firstName}</td>
      <td>{member.idNumber}</td>
      <td>{member.dateOfEmployment}</td>
      <td>{member.companyName}</td>
      <td className="status">
        <span className={`${member.status}`}>{member.status}</span>
      </td>
      <td className="controls uk-text-right">
        <button
          className="kit-btn-text kit-btn-primary uk-margin-small-right"
          onClick={onView}
        >
          <span data-uk-icon="file-edit"></span> View
        </button>
        <button className="kit-btn-text kit-btn-danger" onClick={onDelete}>
          <span data-uk-icon="trash"></span> Delete
        </button>
      </td>
    </>
  );
};

export default ApplicationsRow;

interface ITabProps {
  activeTab: "pending" | "approved" | "rejected";
  onPending: () => void;
  onApproved: () => void;
  onRejected: () => void;
}
export const AdminApplicationTabs = (props: ITabProps) => {
  const { activeTab, onPending, onApproved, onRejected } = props;
  const pendingCss = activeTab === "pending" ? "active" : "complete";
  const approvedCss =
    activeTab === "approved"
      ? "active"
      : activeTab === "rejected"
      ? "complete"
      : "";
  const rejectedCss = activeTab === "rejected" ? "active" : "";

  return (
    <div className="tabs">
      <div className={`tab-item ${pendingCss}`} onClick={onPending}>
        Pending
      </div>
      <div className={`tab-item ${approvedCss}`} onClick={onApproved}>
        Approved
      </div>
      <div className={`tab-item ${rejectedCss}`} onClick={onRejected}>
        Rejected
      </div>
    </div>
  );
};
