type employmentType = "Permanent" | "Contract" | "Tempolary";
type Gender = "Male" | "Female";

export const defaultMembers: IMemberApplication = {
  id: "",
  surname: "",
  firstName: "",
  cellphoneNo: "",
  workTelephone: 0,
  homeTelephone: 0,
  email: "",
  idNumber: "",
  dateOfBirth: "",
  gender: "Male",
  residentialAddress: "",
  postalAddress: "",
  companyName: "",
  companyAddress: "",
  dateOfEmployment: "",
  natureOfEmployment: "Permanent",
  occupation: "",
  department: "",
  dutyStation: "",
  region: "",
  basicSalary: 0,
  employmentCode: 0,
  termsConditions: true,
  applicationDate: new Date().toLocaleDateString(),
  officialName: "",
  officialSignature: "",
  officialTell: 0,
  officialCell: 0,
  officialRegion: "",
  officialBranch: "",
  dataCaptureName: "",
  dataCaptureDate: "",
  dataCapturePlace: "",
  dataCaptureSignature: "",
  status: "Pending",
};

export interface IMemberApplication {
  id: string;
  surname: string;
  firstName: string;
  cellphoneNo: string;
  workTelephone: number;
  homeTelephone: number;
  email: string;
  residentialAddress: string;
  postalAddress: string;
  idNumber: string;
  dateOfBirth: string;
  gender: Gender;
  companyName: string;
  companyAddress: string;
  dateOfEmployment: string;
  natureOfEmployment: employmentType;
  occupation: string;
  department: string;
  dutyStation: string;
  region: string;
  basicSalary: number;
  employmentCode: number;
  termsConditions: true;
  applicationDate: string;
  officialName: string;
  officialSignature: string;
  officialTell: number;
  officialCell: number;
  officialRegion: string;
  officialBranch: string;
  dataCaptureName: string;
  dataCaptureDate: string;
  dataCapturePlace: string;
  dataCaptureSignature: string;
  status: string; //pending , approved, rejected
}
