import React, { useEffect } from "react";
import "./Home.scss";
import { useAppContext } from "../../shared/utils/Context";
import { observer } from "mobx-react-lite";

const Home = observer(() => {
  const { api, store } = useAppContext();

  useEffect(() => {
    api.news.loadAll();
    api.users.loadAll();
    api.reportedIssues.loadAll();
    api.memberApplications.loadAll();
    return () => {};
  }, [api.news, api.users, api.reportedIssues, api.memberApplications]);

  return (
    <div className="uk-section admin-dashboard">
      <div className="uk-container container">
        <div
          className="uk-child-width-1-3@l uk-child-width-1-3@m uk-grid-small uk-grid-match"
          data-uk-grid
        >
          <div>
            <div className="card-post uk-card uk-card-default">
              <div className="uk-card-media-top"></div>
              <div className="uk-card-body">
                <h4 className="uk-card-title">Total News</h4>
                <p>{store.news.size}</p>
              </div>
            </div>
          </div>
          <div>
            <div className="card-post uk-card uk-card-default">
              <div className="uk-card-media-top"></div>
              <div className="uk-card-body">
                <h4 className="uk-card-title">All Users</h4>
                <p>{store.users.size}</p>
              </div>
            </div>
          </div>
          <div>
            <div className="card-post uk-card uk-card-default">
              <div className="uk-card-body">
                <h4 className="uk-card-title">Membership Applications</h4>
                <p>{store.memberApplications.size}</p>
              </div>
            </div>
          </div>
          <div>
            <div className="card-post uk-card uk-card-default">
              <div className="uk-card-body">
                <h4 className="uk-card-title">Reported Issues</h4>
                <p>{store.reportedIssues.size}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Home;
